import { useState, useEffect, useRef } from "react";
import { Box, Fade, IconButton } from "@mui/material";
import jsStyles from "../../styles/jsStyles";
import { themeColors, themeMeasurements } from "../../utils/constant";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

function CaseStudyVideo({ videoFilename }) {
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    if (playing) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [playing]);

  const IconBtn = !playing ? PlayArrowIcon : PauseIcon;

  return (
    <Box
      className="case-studies-video-wrapper"
      width={{ xs: 1, md: "60%" }}
      sx={{
        borderRadius: themeMeasurements.borderRadiusBase,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* overlay */}
      <Fade in={!playing}>
        <Box
          sx={{
            bgcolor: themeColors.indigo500 + "88",
            ...jsStyles.inset0,
            content: '""',
            borderRadius: themeMeasurements.borderRadiusBase,
          }}
        />
      </Fade>
      {/* play-button */}
      <IconButton
        aria-label="play"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          opacity: playing ? 0 : 1,
          ":hover": {
            opacity: 1,
          },
          ...(!playing ? jsStyles.orangeGradient : {}),
          transition: ".1s opacity",
          zIndex:20,
        }}
        size="large"
        onClick={togglePlaying}
      >
        <IconBtn
          sx={{
            fontSize: { xs: "15vw", sm: "6rem" },
            color: "white",
          }}
        />
      </IconButton>
      <video style={{ width: "100%", height: "100%" }} ref={videoRef}>
        <source
          src={require("../../images/" + videoFilename)}
          type="video/mp4"
        />
        Your browser does not support mp4.
      </video>
    </Box>
  );
}

export default CaseStudyVideo;
