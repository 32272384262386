import React from "react";
import ProjectsDataGrid from "../../components/admin/tables/ProjectsDataGrid";

function ProjectsPage() {
  return (
    <div>
      <ProjectsDataGrid />
    </div>
  );
}

export default ProjectsPage;
