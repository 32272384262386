import { Box, Stack, Typography } from "@mui/material";
import React from "react";

function TechJuniorBenefitArticle({ title, description, iconSrc }) {
  return (
    <Stack alignItems="start">
      <Box sx={{ mb: { xs: 1, sm: 3, md: 4 }, width: 1 }}>
        <img src={iconSrc} alt={title} style={{ width: "100%" }} />
      </Box>
      <Box maxWidth={{ xs: "auto" }}>
        <Typography variant="h4" mb={1}>
          {title}
        </Typography>
        <Typography textAlign="left" pr={2}>
          {description}
        </Typography>
      </Box>
    </Stack>
  );
}

export default TechJuniorBenefitArticle;
