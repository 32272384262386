export const FAQ_DATA = {
  techProfessionals: [
    {
      question: "What is a skill-based volunteering platform?",
      answer:
        "A skill-based volunteering platform connects tech professionals with NPOs to develop custom solutions through volunteer skills and expertise.",
    },
    {
      question: "How do I join the platform as a tech professional?",
      answer:
        "To join the platform as a tech professional, you will need to register and create a profile about your skills and expertise.",
    },
    {
      question:
        "What kind of projects can I expect to work on as a tech professional on the platform?",
      answer:
        "As a tech professional on the platform, you can expect to work on various projects, such as website development, data analysis, and custom software development, based on your skills and NPO needs.",
    },
    {
      question:
        "Is there any cost to me as a tech professional to join the platform?",
      answer:
        "No, there is no cost to join the platform as a tech professional.",
    },
    {
      question: "How do I communicate with the NPOs I will be working with?",
      answer:
        "The platform will provide a communication tool for tech professionals and NPOs to communicate and collaborate on projects.",
    },
    {
      question: "How long does it take for a project to be completed?",
      answer:
        "The timeline for a project will depend on the specific project and the availability of tech professionals.",
    },
    {
      question:
        "What happens if I need to leave a project before its completion?",
      answer:
        "The platform will have a protocol in place to handle such scenarios and will work with the NPO and the remaining team members to ensure that the project is completed to the best of our abilities.",
    },
    {
      question:
        "What kind of support will I receive from the platform for my professional development?",
      answer:
        "The platform offers resources for tech professionals to enhance their skills and knowledge through mentoring, networking and professional development opportunities.",
    },
  ],
  npos: [
    {
      question: "What is a skill-based volunteering platform?",
      answer:
        "A skill-based volunteering platform connects non-profit organizations (NPOs) with technical professionals who can offer their skills and expertise to help develop custom solutions for the NPOs.",
    },
    {
      question: "How does my NPO join the platform?",
      answer:
        "To join the platform, your NPO will need to register and create a profile, providing details about your organization. Once your profile is created, you can post projects in the community.",
    },
    {
      question:
        "What project scope can my NPO expect from tech professionals on the platform?",
      answer:
        "Tech professionals on the platform can provide a range of project scopes, such as web development, data analysis, custom software development, and more. The specific project scope depends on your organization's specific needs.",
    },
    {
      question:
        "Will my NPO need to pay for the services provided on the platform?",
      answer:
        "Yes, the platform charges for its volunteering skills matching services and for the facilitation of projects.",
    },
    {
      question:
        "How do I communicate with the tech professionals working on my project?",
      answer:
        "The platform will provide a communication tool for NPOs and tech professionals to communicate and collaborate on projects.",
    },
    {
      question: "How long does it take for a project to be completed?",
      answer:
        "The timeline for a project will depend on the specific project and the availability of tech professionals.",
    },
    {
      question:
        "What happens if a tech professional leaves the project before its completion?",
      answer:
        "The platform will have a protocol in place to handle such scenarios and will work with the NPO and the remaining team members to ensure that the project is completed to the best of our abilities.",
    },
  ],
};
