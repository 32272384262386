import useSWR from "swr";
import { swrFetcher } from "../../../utils/fetch";

const useProject = (id) => {
    const { data ,mutate} = useSWR(`/api/project${id ? `/user/${id}`: ``}`, swrFetcher);

    return {
        data,
        mutate
    }
}

export default useProject;