import { Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AppFAB = ({ label, onClick }) => {
  return (
    <Box sx={{ position: "fixed", bottom: 0, right: 240, m: 4 }}>
      <Fab color="primary" variant="extended" onClick={onClick}>
        <AddIcon sx={{ mr: 1 }} />
        {label}
      </Fab>
    </Box>
  );
};

export default AppFAB;
