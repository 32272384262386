import React from 'react';
import { Box, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import TeamMember from '../../../components/TeamMember';

const TaskItem = ({ task, index, handleToggleTask, handleEditTask, statusColors }) => (
  <Stack direction="row" alignItems="center" >
    <Box sx={{ width: 50, padding: '8px' }}>
      <Checkbox
        checked={task.checked}
        onChange={() => handleToggleTask(task._id)}
        icon={<RadioButtonUncheckedIcon sx={{ color: task.checked ? '#42549f' : '#e8e8e8' }} />}
        checkedIcon={<CheckCircleOutlineIcon sx={{ color: task.checked ? '#42549f' : '#e8e8e8' }} />}
      />
    </Box>
    <Stack 
    onClick={() => handleEditTask(task)} 
      direction="row"
      alignItems="center"
      sx={{
        cursor:"pointer",
        width: `calc(100% - 50px)`,
        borderLeft: '1px solid #e8e8e8',
        borderRight: '1px solid #e8e8e8',
        borderBottom: '1px solid #e8e8e8',
        ...(index === 0 && { borderTop: '1px solid #e8e8e8' }),
      }}
    >
      <Box sx={{ width: 200, padding: '8px' }}>
        <Typography align="left">{ task.title.length > 8 ? task.title.slice(0, 13) + "..." : task.title}</Typography>
      </Box>
      <Box sx={{ width: 250, padding: '8px', display: 'flex', alignItems: 'center' }}>
        {task.assignment.length > 0 && (
          <TeamMember
            user={(() => {
              const { uid, ...user } = task.assignment[0];
              return user;
            })()}
            subtitle={task.assignment[0].role}
          />
        )}
      </Box>
      <Box sx={{ width: 100, padding: '8px' }}>
        <Typography variant="body2" align="left">{task.type}</Typography>
      </Box>
      <Box sx={{ width: 180, padding: '8px' }}>
        <span
          style={{
            backgroundColor: statusColors[task.status].background,
            color: statusColors[task.status].color,
            padding: '5px 10px',
            borderRadius: '15px',
            display: 'inline-block',
            textAlign: 'center',
            width: '120px',
          }}
        >
          {task.status}
        </span>
      </Box>
      <Box sx={{ width: 200, padding: '8px' }}>
        {new Date(task.date).toLocaleDateString('en-GB')}
      </Box>
      <Box sx={{ width: 50, padding: '8px', textAlign: 'right' }}>
        <IconButton aria-label="edit" onClick={() => handleEditTask(task)}>
          <EditIcon />
        </IconButton>
      </Box>
    </Stack>
  </Stack>
);

export default TaskItem;
