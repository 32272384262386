import React from "react";
import { Box, Typography } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Link } from "react-router-dom";
import { PROFESSION_ROLES } from "../data/lists";
import { themeColors } from "../utils/constant";
import { getFullName } from "../utils/tools";
import { getTextByValue } from "../utils/tools";
import AppAvatar from "./AppAvatar";
import jsStyles from "../styles/jsStyles";

const TeamMember = ({
  user,
  subtitle: subtitleProp = "",
  role = "",
  showEmptyPosition = false,
  sx = {},
  isTask
}) => {
  const professionRole = user?.professionRole || role;
  const subtitle =
    subtitleProp || getTextByValue(PROFESSION_ROLES, professionRole);
  const isClickable = user?.uid;
  return (
    <Box
      display="flex"
      alignItems="center"
      className="clean-link"
      bgcolor={themeColors.indigo100}
      borderRadius={50}
      p={1}
      pr={6}
      gap={2}
      component={user?.uid ? Link : ""}
      sx={{
        transition: ".15s box-shadow",
        ":hover": isClickable ? jsStyles.shadowSm : {},
        width: "fit-content",
        ...sx,
      }}
      to={isClickable ? `/profile/${user?.uid}` : null}
    >
      {showEmptyPosition && !user ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "dashed 1px #aaa",
            borderRadius: 100,
            width: { xs: 35, sm: 45 },
            height: { xs: 35, sm: 45 },
          }}
        >
          <QuestionMarkIcon />
        </Box>
      ) : (
        <AppAvatar isTask={true} user={user} />
      )}
      <div>
        <Typography color={themeColors.orange}>
          {subtitle}
        </Typography>
        <Typography variant="h4">{getFullName(user)}</Typography>
      </div>
    </Box>
  );
};

export default TeamMember;
