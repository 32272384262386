import { Dialog, Stack, Typography } from "@mui/material";
import React from "react";
import GradientButton from "../../../components/GradientButton";
import superTalentedImage from "../../../images/developer-image.png";

function TooMuchProjectsDialog({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { px: 4, pt: 4, pb: 3 } }}
    >
      <Stack alignItems="center" mb={2} textAlign='center'>
        <img
          src={superTalentedImage}
          alt="super-talented"
          style={{ height: "13rem" }}
        />
        <Typography variant="h4" mb={1.5}>
          We know you’re super talented
        </Typography>
        <Typography>
          But you are already member in other project.
        </Typography>
        <GradientButton
          label="OK"
          onClick={onClose}
          sx={{ width: "auto", mt: 4 }}
        />
      </Stack>
    </Dialog>
  );
}

export default TooMuchProjectsDialog;
