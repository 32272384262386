import { Box } from "@mui/material";
import React from "react";
import TeamMember from "./TeamMember";

function Team({ team, showEmptyPositions, ...props }) {
  const teamItems = showEmptyPositions
    ? team
    : team.filter(({ chosenUser }) => chosenUser);
  if (showEmptyPositions)
    teamItems.sort((a, b) =>
      a.chosenUser && !b.chosenUser ? -1 : !a.chosenUser && b.chosenUser ? 1 : 0
    );
  return (
    <Box display="flex" flexWrap="wrap" gap={1} {...props}>
      {teamItems.map(({ chosenUser, requirements, _id }) => (
        <TeamMember
          showEmptyPosition
          key={_id}
          user={chosenUser}
          role={requirements?.role}
        />
      ))}
    </Box>
  );
}

export default Team;
