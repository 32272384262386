import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";
import DateCalendarServerRequest from "./components/Calendar"
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Container,
  Divider,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import { Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import AppTabs from "../../components/AppTabs";
import ProjectTags from "../../components/ProjectTags";
import StartEditButton from "../../components/StartEditButton";
import Team from "../../components/Team";
import TeamMember from "../../components/TeamMember";
// import Chat from "../../components/chat/ProjectChat";
import Chat from "../../components/Chat";

import TimelineStatus from "./components/TimelineStatus";
import { useUser } from "../../context/user";
import {
  APP_TYPES,
  MEETING_DETAILS_ITEMS,
  PROJECT_STATUSES,
} from "../../data/lists";
import { PROJECT_TEMPLATES } from "../../data/project-templates";
import jsStyles from "../../styles/jsStyles";
import { themeColors } from "../../utils/constant";
import { appFetch, swrFetcher } from "../../utils/fetch";
import { getTextByValue } from "../../utils/tools";
import AddTeamPositionDialog from "./components/AddTeamPositionDialog";
import EditableItem from "./components/EditableItem";
import vogoImage from "../../images/logo192.png";
import ProjectTasks from "./components/ProjectTasks"

function ProjectStatusPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { user } = useUser();
  const { data: project, mutate } = useSWR(
    `/api/project/${id}/status`,
    swrFetcher
  );
  const [teamMembers, setTeamMembers] = useState([]);
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await appFetch(`/api/projectTask/${project._id}/team`, {
          method: "GET",
        });
        if (response.ok) {
          const data = await response.json();
          setTeamMembers(data.data.team.map(team => ({ role: team.requirements.role, ...team.chosenUser })));
        } else {
          console.error('Failed to fetch team members');
        }
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };
    fetchTeamMembers();
  }, [project]);
  const [tab, setTab] = useState("TEAM WORKSPACE");

  const [editModeType, setEditModeType] = useState(""); // 'meeting-details' || 'links' || 'status
  const [links, setLinks] = useState([]);
  const [infoLinks, setInfoLinks] = useState([]);
  const [theValue, setTheValue] = useState("");
  const [theNeed, setTheNeed] = useState("");
  const [description, setDescription] = useState("");
  const [meetingDetails, setMeetingDetails] = useState({});
  const [customSection, setCustomSection] = useState({
    title: "",
    content: "",
  });
  const [selectedCustomSection, setSelectedCustomSection] = useState(null);
  const [teamPositionDialogOpen, setTeamPositionDialogOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState("Gathering Team");
  const [loading, setLoading] = useState(false);
  const [allTasks, setAllTasks] = useState([]);

  const onCloseTeamPositionDialog = () => setTeamPositionDialogOpen(false);
  const openTeamPositionDialog = () => setTeamPositionDialogOpen(true);

  const LINK_TYPES = [
    { regex: /docs.google.com/i, label: "Instructions" },
    { regex: /figma.com/i, label: "Design" },
    { regex: /github.com/i, label: "Github" },
    { regex: /slack.com/i, label: "Slack" },
    { regex: /asana.com/i, label: "Asana" },
  ];
  if (project) {
    project.teamLinks.sort((link1, link2) => {
      const i1 = LINK_TYPES.findIndex((type) => type.regex.test(link1));
      const i2 = LINK_TYPES.findIndex((type) => type.regex.test(link2));
      if (i1 < 0) return 1;
      if (i2 < 0) return -1;
      if (i1 > i2) {
        return 1;
      }
      if (i1 < i2) {
        return -1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (project) {
      const {
        teamLinks,
        meetingLocation,
        meetingTiming,
        contactPerson,
        infoLinks,
        theNeed,
        theValue,
        description,
      } = project;

      setLinks(teamLinks);
      setMeetingDetails({ meetingLocation, meetingTiming, contactPerson });
      setLinks(infoLinks);
      setTheValue(theValue);
      setTheNeed(theNeed);
      setDescription(description);
    }
  }, [project]);

  if (project === null) return <Navigate to="/login" replace />;
  if (project === undefined) return null;

  const stepIndex = PROJECT_STATUSES.findIndex(
    ({ value }) => value === project.status
  );

  const userIsAllowedToEdit =
    user.role === 0 ||
    user.professionRole === "productManager" ||
    project.npo?._id === user._id;

  const saveTeamPosition = createSaveChangesHandler({
    url: `/api/project/product-manager/${project._id}/add-team-position`,
    method: "PUT",
  });
  const saveChanges = createSaveChangesHandler({
    url: "/api/project/product-manager/" + project._id,
    method: "PUT",
  });
  function createSaveChangesHandler(options) {
    return async (data) => {
      try {
        setLoading(true);
        const createRes = await appFetch(options.url, {
          method: options.method,
          body: JSON.stringify(data),
        });
        if (!createRes.ok) throw new Error();
        await createRes.json();
        enqueueSnackbar("Changes saved");
        mutate();
        setEditModeType("");
      } catch (error) {
        console.error(error);
        enqueueSnackbar("An error has occurred", { variant: "error" });
        throw error;
      } finally {
        setLoading(false);
      }
    };
  }
  const saveMeetingChanges = (ev) => {
    ev.preventDefault();
    saveChanges(meetingDetails);
  };
  const saveStatusChange = (value) => {
    saveChanges({ status: value });
  };

  const LocalEditButton = ({ editType, ...props }) =>
    userIsAllowedToEdit ? (
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: ".75rem",
        }}
      >
        <StartEditButton
          isEditMode={editModeType === editType}
          onClickEdit={() => setEditModeType(editType)}
          onCancelEdit={() => setEditModeType("")}
          loading={loading}
          {...props}
        />
      </Box>
    ) : null;

  const createOnSaveHandler = (key) => (value) => {
    setEditModeType("");
    saveChanges({ [key]: value });
  };
  const addInfoSection = () => {
    setEditModeType("custom-section");
  };
 
  const saveInfoSection = async (ev) => {
    // ev.preventDefault();
    // TODO: add validation
    const newSections = selectedCustomSection
      ? project.customInfoSections.map((i) =>
          i._id === selectedCustomSection._id ? selectedCustomSection : i
        )
      : (project.customInfoSections || []).concat(customSection);
    await saveChanges({
      customInfoSections: newSections,
    });
    setCustomSection({
      title: "",
      content: "",
    });
    setSelectedCustomSection(null);
  };

  const customImage = project?.image?.url;
  const defaultImage = PROJECT_TEMPLATES.find(
    (i) => i.value === project?.appType
  )?.bigImageSrc;

  let component = <></>
switch(tab){
  case "TEAM WORKSPACE":
    component = <>
    <Divider sx={{ my: 1 }} />
    <EditableItem
      label="Description"
      value={description}
      setValue={setDescription}
      onSaveValue={createOnSaveHandler("description")}
      isEditMode={editModeType === "description"}
      editButtonComponent={<LocalEditButton editType="description" />}
      multiline
    />
    <Divider />
    <EditableItem
      label="The need"
      value={theNeed}
      setValue={setTheNeed}
      onSaveValue={createOnSaveHandler("theNeed")}
      isEditMode={editModeType === "theNeed"}
      editButtonComponent={<LocalEditButton editType="theNeed" />}
      multiline
    />
    <Divider />
    <EditableItem
      label="The value"
      value={theValue}
      setValue={setTheValue}
      onSaveValue={createOnSaveHandler("theValue")}
      isEditMode={editModeType === "theValue"}
      editButtonComponent={<LocalEditButton editType="theValue" />}
      multiline
    />
    <Divider />
    <EditableItem
      label="More information"
      value={infoLinks}
      setValue={setInfoLinks}
      multiple
      onSaveValue={createOnSaveHandler("infoLinks")}
      isEditMode={editModeType === "infoLinks"}
      editButtonComponent={<LocalEditButton editType="infoLinks" />}
      isLinksList
      showLinkType
    />
    <Divider />
    {/* Meeting details Card */}
    <form onSubmit={saveMeetingChanges}>
      <Box
        display="flex"
        position="relative"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="start"
        gap={{ xs: 1, sm: 0 }}
        py={2}
      >
        <Typography fontWeight={700} width={180} flexShrink={0}>
          Meeting details
        </Typography>

        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
          {Object.entries(meetingDetails).map(([key, text], index) => {
            const label = MEETING_DETAILS_ITEMS.find(
              (i) => i.key === key
            )?.label;

            const onChangeMeetingItem = ({ target: { value } }) => {
              setMeetingDetails({
                ...meetingDetails,
                [key]: value,
              });
            };

            return (
              <li
                key={key}
                style={{
                  marginBottom: ".6em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography component="span" mr={2}>
                  {label}:
                </Typography>
                {editModeType !== "meeting-details" ? (
                  text
                ) : (
                  <TextField
                    autoFocus={index === 0}
                    onChange={onChangeMeetingItem}
                    value={text}
                    className="editable-text-field"
                  />
                )}
              </li>
            );
          })}
        </ul>
        <Box ml="auto">
          <LocalEditButton editType="meeting-details" />
        </Box>
      </Box>
    </form>
    <Divider sx={{ my: 1 }} />
    {/* Project links Card */}
    <EditableItem
      label="Project links"
      value={links}
      setValue={setLinks}
      multiple
      onSaveValue={createOnSaveHandler("teamLinks")}
      isEditMode={editModeType === "links"}
      editButtonComponent={
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: ".75rem",
          }}
        >
          <LocalEditButton editType="links" />
        </Box>
      }
      isLinksList
      showLinkType
    />
    <Divider sx={{ my: 1 }} />
    {project?.customInfoSections.map((section) => {
      const editTypeId = "custom-section-" + section._id;
      const isEditMode = editModeType === editTypeId;
      return (
        <EditableItem
          key={section._id}
          label={isEditMode ? selectedCustomSection.title : section.title}
          fullyEditable
          multiline
          setLabel={(value) =>
            setSelectedCustomSection((prev) => ({
              ...prev,
              title: value,
            }))
          }
          value={
            isEditMode ? selectedCustomSection.content : section.content
          }
          setValue={(value) =>
            setSelectedCustomSection((prev) => ({
              ...prev,
              content: value,
            }))
          }
          onSaveValue={saveInfoSection}
          isEditMode={isEditMode}
          editButtonComponent={
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: ".75rem",
              }}
            >
              <LocalEditButton
                editType={editTypeId}
                onClickEdit={() => {
                  setEditModeType(editTypeId);
                  setSelectedCustomSection(section);
                }}
                onCancelEdit={() => {
                  setEditModeType("");
                  setSelectedCustomSection(null);
                }}
                deletable
                onClickDelete={async () => {
                  const newSections = project.customInfoSections.filter(
                    (i) => i._id !== selectedCustomSection._id
                  );
                  await saveChanges({
                    customInfoSections: newSections,
                  });
                  setEditModeType("");
                  setSelectedCustomSection(null);
                }}
                disabled={
                  isEditMode &&
                  (selectedCustomSection.title.trim().length < 2 ||
                    selectedCustomSection.content.trim().length < 2)
                }
              />
            </Box>
          }
        />
      );
    })}
    {editModeType === "custom-section" && (
      <form onSubmit={saveInfoSection}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="start"
          gap={{ xs: 1, sm: 0 }}
          py={2}
        >
          <TextField
            value={customSection.title}
            onChange={(ev) =>
              setCustomSection((prev) => ({
                ...prev,
                title: ev.target.value,
              }))
            }
            tabIndex={0}
            sx={{
              fontWeight: 700,
              width: 180,
              flexShrink: 0,
              pr: 1,
            }}
            className="editable-text-field"
            placeholder="Title"
          />
          <TextField
            value={customSection.content}
            onChange={(ev) =>
              setCustomSection((prev) => ({
                ...prev,
                content: ev.target.value,
              }))
            }
            tabIndex={1}
            className="editable-text-field"
            placeholder="Description"
            sx={{ whiteSpace: "pre-line" }}
            fullWidth
            multiline
          />
        </Box>
      </form>
    )}
    <Box width={1} display={"flex"} justifyContent="center">
      <Box width={1} display={"flex"} justifyContent="center">
        {!userIsAllowedToEdit ? null : editModeType !==
          "custom-section" ? (
          <IconButton
            size="small"
            color="primary"
            sx={{ border: "1px solid" }}
            onClick={addInfoSection}
          >
            <AddIcon />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            color="secondary"
            sx={{ border: "1px solid" }}
            onClick={saveInfoSection}
            disabled={
              customSection.title.trim().length < 2 ||
              customSection.content.trim().length < 2
            }
          >
            <SaveIcon />
          </IconButton>
        )}
      </Box>
      {/* <GradientButton
        size="small"
        inverted
        label={"Add section"}
        onClick={openTeamPositionDialog}
        startIcon={<AddIcon style={{ zIndex: 10 }} />}
        useSingleColor
      /> */}
    </Box>
    <Typography fontWeight={700} width={180} mb={3} mt={4} flexShrink={0}>
      NPO Project manager
    </Typography>
    <TeamMember user={project.npo} subtitle="Project manager" />
    <Typography fontWeight={700} width={180} mt={4} mb={3} flexShrink={0}>
      The team:
    </Typography>
    <Box display="flex" alignItems="start" flexWrap="wrap" gap={1}>
      <Team team={project.team} showEmptyPositions />
      {userIsAllowedToEdit && (
        <Box width={1} display={"flex"} justifyContent="center">
          <IconButton
            size="small"
            color="primary"
            sx={{ border: "1px solid" }}
            onClick={openTeamPositionDialog}
          >
            <AddIcon />
          </IconButton>
        </Box>
      )}
    </Box>
    <AddTeamPositionDialog
      open={teamPositionDialogOpen}
      onClose={onCloseTeamPositionDialog}
      onSave={saveTeamPosition}
    />
  </>
    break;
  case "PROJECT DETAILS":
    component =  <>
    {/* <Chat project={project} /> */}
    <TimelineStatus activeStatus={activeStatus} onStatusClick={setActiveStatus} allTasks={allTasks}/>
<Box height={90}></Box>

<ProjectTasks teamMembers={teamMembers} setAllTasks={setAllTasks} allTasks={allTasks} project={project} activeStatus={activeStatus}/>
<DateCalendarServerRequest teamMembers={teamMembers} setAllTasks={setAllTasks} allTasks={allTasks} project={project}/>
  </>
    break;
  case "CHAT":

    component =   <Chat roomId={project.chatRoomId} />

    break;
}
  return (
    <Container maxWidth="lg" sx={{ mb: 2 }}>
      <Stack
        mt={4}
        mb={4}
        sx={{
          ...jsStyles.cardBorderRadius,
          overflow: "hidden",
          bgcolor: themeColors.indigo100,
          ".image": {
            height: 300,
            objectFit: "cover",
            ...(!customImage
              ? {
                  objectFit: "contain",
                  bgcolor: "white",
                  py: 1,
                  m: 0.8,
                  mb: 0,
                  borderTopLeftRadius: ".5rem",
                  borderTopRightRadius: ".5rem",
                }
              : {}),
          },
        }}
      >
        {project ? (
          <img
            className="image"
            src={customImage || defaultImage}
            alt="background images"
          />
        ) : (
          <Skeleton variant="rectangular" className="image" />
        )}
        <Box
          p={2}
          display="flex"
          gap={3}
          sx={{
            ".logo": {
              ...jsStyles.cardBorderRadius,
              width: 110,
              height: 110,
              overflow: "hidden",
              objectFit: "contain",
              bgcolor: "white",
            },
          }}
        >
          {project ? (
            <img
              className="logo"
              src={project.logo?.url || project.npo?.npoLogo?.url || vogoImage}
              alt={`${project.name}-logo`}
            />
          ) : (
            <Skeleton variant="rectangular" className="logo" />
          )}
          <Stack>
            <Typography variant="h4">
              {project?.name || <Skeleton width={80} />}
            </Typography>
            <Typography variant="h1">
              {getTextByValue(APP_TYPES, project?.appType) || (
                <Skeleton width={150} />
              )}
            </Typography>
            {project ? (
              <ProjectTags tags={project?.tags} mt="auto" />
            ) : (
              <Skeleton width={120} />
            )}
          </Stack>
        </Box>
      </Stack>
      <AppTabs
        tabs={["TEAM WORKSPACE","PROJECT DETAILS","CHAT" /*"GROUP CHAT"*/ ]}
        setTab={setTab}
        activeTab={tab}
        mb={4}
      />
     {component}
    </Container>
  );
}

export default ProjectStatusPage;
