import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import jsStyles from "../styles/jsStyles";
import { themeColors } from "../utils/constant";

function GradientButton({
  loading,
  onClick,
  label,
  inverted = false,
  disabled = false,
  invertedBgcolor = "white",
  useSingleColor = false,
  singleColor = themeColors.indigo700,
  labelProps = {},
  sx = {},
  ...props
}) {
  return (
    <LoadingButton
      disabled={disabled}
      loading={loading}
      className={props.disabled ? "grayscale" : ""}
      sx={{
        px: { xs: 2.2, sm: 3 },
        borderRadius: 100,
        minWidth: { xs: "9rem", sm: "11rem" },
        height: "2.7rem",
        textTransform: "none",
        position: "relative",
        zIndex: 2,
        overflow: "hidden",
        opacity: props.disabled ? 0.5 : 1,
        ".MuiLoadingButton-loadingIndicator": {
          zIndex: 10,
          color: inverted ? themeColors.orange : "white",
        },
        ":hover": {
          ".inner-bg": {
            opacity: 0.75,
          },
        },
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      <Box
        className="inner-bg"
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          ...(useSingleColor
            ? { bgcolor: singleColor }
            : jsStyles.orangeGradient),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {inverted && (
          <Box
            sx={{
              width: 1,
              height: 1,
              padding: "2px", // this is the border width
              borderRadius: 100,
              backgroundColor: invertedBgcolor,
              backgroundClip: "content-box",
            }}
          />
        )}
      </Box>
      <Typography
        fontWeight="600"
        visibility={loading ? "hidden" : ""}
        className="label"
        sx={{
          zIndex: 10,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          ...(useSingleColor ? {} : jsStyles.orangeGradientText),
          color: !useSingleColor
            ? inverted
              ? "transparent"
              : "white"
            : inverted
            ? singleColor
            : "white",
        }}
        {...labelProps}
      >
        {label}
      </Typography>
    </LoadingButton>
  );
}

export default GradientButton;
