import { Checkbox, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import AppInput from "../../../../components/AppInput";
import GradientButton from "../../../../components/GradientButton";
import PlacesAutocomplete from "../../../../components/PlacesAutocomplete";
import { useUser } from "../../../../context/user";
import { appFetch } from "../../../../utils/fetch";
import { validateByRules } from "../../../../utils/validators";

function Step3() {
  const { user, setUser } = useUser();
  const [npoName, setNpoName] = useState(user?.npoName || "");
  const [npoWebsite, setNpoWebsite] = useState(user?.npoWebsite || "");
  const [city, setCity] = useState(user?.city || null);
  const [npoDescription, setNpoDescription] = useState(
    user?.npoDescription || ""
  );

  const [agreementChecked, setAgreementChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);

  const validateForm = () => {
    const hasErrors =
      validateByRules("name", npoName) ||
      validateByRules("url", npoWebsite) ||
      !city?.place_id ||
      validateByRules("description", npoDescription);
    return hasErrors;
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const invalid = validateForm();
    if (invalid) {
      setShowInputErrors(true);
      return;
    }
    setServerError("");

    try {
      setLoading(true);
      const res = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify({
          npoName,
          npoWebsite,
          npoDescription,
          city: { name: city?.description, placeId: city?.place_id },
        }),
      });
      const data = await res.json();

      if (!res.ok) throw new Error();

      setUser(data.user);
    } catch (err) {
      setServerError("Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack component={"form"} width={{ xs: 1, sm: 450 }} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppInput
            label="NPO name"
            name="npoName"
            value={npoName}
            setValue={setNpoName}
            validationRules={"required name"}
            showError={showInputErrors}
            placeholder="Association name"
          />
        </Grid>
        <Grid item xs={12}>
          <AppInput
            label="NPO website"
            name="npoWebsite"
            value={npoWebsite}
            setValue={setNpoWebsite}
            validationRules={"required url"}
            showError={showInputErrors}
            placeholder="https://"
          />
        </Grid>
        <Grid item xs={12}>
          <PlacesAutocomplete place={city} setPlace={setCity} showError={showInputErrors} />
        </Grid>
        <Grid item xs={12}>
          <AppInput
            label="Description of the activities and goals"
            name="npoDescription"
            value={npoDescription}
            setValue={setNpoDescription}
            validationRules={"required description"}
            showError={showInputErrors}
            multiline
            rows={4}
            placeholder="Please describe ..."
          />
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} alignItems="center">
            <Checkbox
              checked={agreementChecked}
              required
              onChange={() => setAgreementChecked((prev) => !prev)}
            />
            <Typography variant="body2" align="left">
              I acknowledge that I have read and consent to the{" "}
              <a
                target="_blank"
                href={"/trems-of-service.pdf"}
                rel="noreferrer"
              >
                terms of service
              </a>{" "}
              and{" "}
              <a target="_blank" href={"/privacy-policy.pdf"} rel="noreferrer">
                privacy policy
              </a>
              .
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <GradientButton
        sx={{ mt: 8, alignSelf: "center" }}
        label="Next"
        type="submit"
        loading={loading}
        disabled={!agreementChecked}
      />
      {serverError ? (
        <Typography color="error" textAlign="center" height="1.5em">
          {serverError}
        </Typography>
      ) : null}
    </Stack>
  );
}

export default Step3;
