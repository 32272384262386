import { Collapse, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { themeColors, themeMeasurements } from "../utils/constant";

function FQASummrayItem({ isOpen = false, title, description, ...props }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      borderRadius={themeMeasurements.borderRadiusBase}
      border="1px solid"
      bgcolor={isOpen ? "white" : themeColors.indigo100}
      pl={{ xs: 3, md: 6 }}
      pr={2.5}
      minWidth={{ xs: "auto", md: 750 }}
      sx={{
        cursor: "pointer",
        borderColor: isOpen ? themeColors.orangeGradientEnd : "transparent",
        "&:hover .FQASummrayItem-title": {
          transform: "translateX(2px)",
          //   borderColor: 'red',
        },
      }}
      {...props}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          className="FQASummrayItem-title"
          variant={isMobile ? "" : "h4"}
          color={isOpen ? themeColors.orangeGradientStart : undefined}
          py={2.2}
          sx={{ transition: "transform .4s" }}
        >
          {title}
        </Typography>
        <AddIcon sx={{ opacity: 0.6 }} />
      </Box>
      <Collapse in={isOpen}>
        <Box ml={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 7 }}>
          <Typography>{description}</Typography>
        </Box>
      </Collapse>
    </Box>
  );
}

export default FQASummrayItem;
