import React, { Suspense } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import SignupPage from "./pages/signup/SignupPage";
import LoginPage from "./pages/login-page/LoginPage";
import ForgotPasswordPage from "./pages/forgot-password-page/ForgotPasswordPage";

import NonUserRoute from "./components/auth/NonUserRoute";
import { withUserAuth } from "./components/auth/UserRoute";

import Layout from "./components/Layout";
import AdminLayout from "./components/admin/AdminLayout";

import AdminUsersPage from "./pages/admin/UsersPage";
import AdminProjectsPage from "./pages/admin/ProjectsPage";
import AdminNposPage_OLD from "./pages/admin/NposPage_OLD";
import AdminNposPage from "./pages/admin/NposPage";
import MyProjectsTab from "./pages/projects/components/MyProjectsTab";
import ProjectInfo from "./pages/projects/components/ProjectInfo";
import UserProfile from "./pages/user-profile/components/UserProfile";
import HomeProjectsTab from "./pages/projects/components/HomeProjectsTab";
import ProjectStatusPage from "./pages/project-status/ProjectStatusPage";
import WaitingForConfirmPage from "./pages/WaitingForConfirmPage";
import WelcomePage from "./pages/welcome-page/WelcomePage";
import AdminRoute from "./components/auth/AdminRoute";
import JoinUsPage from "./pages/JoinUsPage";
import NPORegistrationPage from "./pages/NPORegistrationPage";
import Page404 from "./pages/Page404";
import TechProSignupPage from "./pages/signup/tech-pro/TechProSignupPage";
import NpoSignupPage from "./pages/signup/npo/NpoSignupPage";
import PublishProjectPage from "./pages/publish-project/PublishProjectPage";
import LayoutForUsers from "./components/LayoutForUsers";

const router = createBrowserRouter([
  // PUBLIC LAYOUT
  {
    path: "/",
    element: <Layout />,
    children: [
      // PUBLIC ROUTES
      { index: true, element: <WelcomePage /> },
      {
        path: "*",
        element: <Page404 />,
      },
      {
        path: "join-us",
        element: <JoinUsPage />,
      },
      {
        path: "npo-registration",
        element: <NPORegistrationPage />,
      },
      //  NON-USER ROUTES
      {
        path: "login",
        element: (
          <NonUserRoute>
            <LoginPage />
          </NonUserRoute>
        ),
      },
      {
        path: "signup",
        element: (
          <NonUserRoute>
            <SignupPage />
          </NonUserRoute>
        ),
      },
      {
        path: "signup/tech-professional",
        element:
          // for non-user or unconfigured user
          withUserAuth(<TechProSignupPage />, {
            authUserStatus: [null, "unconfigured"],
          }),
      },
      {
        path: "signup/npo",
        element:
          // for non-user or unconfigured user
          withUserAuth(<NpoSignupPage />, {
            authUserStatus: [null, "unconfigured"],
          }),
      },
      {
        path: "forgot-password",
        element: (
          <NonUserRoute>
            <ForgotPasswordPage />
          </NonUserRoute>
        ),
      },
      {
        path: "waiting-for-confirm",
        element: <WaitingForConfirmPage />,
      },
    ],
  },
  // APP LAYOUT
  {
    // element: <LayoutForUsers />,
    children: [
      {
        path: "/projects",
        element: withUserAuth(<HomeProjectsTab />),
      },
      {
        path: "/my-activity",
        element: withUserAuth(<MyProjectsTab />),
      },
      {
        path: "/my-profile",
        element: withUserAuth(<UserProfile isMyProfile />),
      },
      {
        path: "/project/:id",
        element: withUserAuth(<ProjectInfo />),
      },
      {
        path: "/project/:id/status",
        element: withUserAuth(<ProjectStatusPage />),
      },
      {
        path: "/profile/:uid",
        element: withUserAuth(<UserProfile />),
      },
      {
        path: "/create-project",
        element: withUserAuth(<PublishProjectPage />),
      },
    ],
  },
  // ADMIN ROUTES
  {
    path: "/admin",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AdminRoute>
          <AdminLayout />
        </AdminRoute>
      </Suspense>
    ),
    children: [
      { index: true, element: <Navigate to="projects" replace={true} /> },
      { path: "npos-old", element: <AdminNposPage_OLD /> },
      { path: "npos", element: <AdminNposPage /> },
      { path: "users", element: <AdminUsersPage /> },
      { path: "projects", element: <AdminProjectsPage /> },
    ],
  },
]);

export default router;
