import React, { useState } from "react";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  createFilterOptions,
  TextField,
} from "@mui/material";
import useSWR from "swr";
import { appFetch, swrFetcher, swrFetcherNotAuth } from "../../utils/fetch";
import jsStyles from "../../styles/jsStyles";

const filter = createFilterOptions();

function MultipleCreatableInput({
  items,
  setItems,
  apiRoute,
  label,
  placeholder,
  error,
  helperText,
  disabledCreation,
  needAuth = true,
  sx = {},
}) {
  const [creatingNewItem, setCreatingNewItem] = useState(false);
  const {
    data: fetchedItems,
    error: fetchedError,
    mutate,
  } = useSWR(apiRoute, needAuth ? swrFetcher : swrFetcherNotAuth, {
    revalidateIfStale: false,
  });

  const isLoading = (!fetchedError && !fetchedItems) || creatingNewItem;
  async function createNewItem(name) {
    try {
      setCreatingNewItem(true);
      const res = await appFetch(apiRoute, {
        method: "POST",
        body: JSON.stringify({ name }),
      });
      if (!res.ok) throw new Error();
      mutate();
      const { data } = await res.json();
      return data;
    } catch (err) {
      console.error(err);
    } finally {
      setCreatingNewItem(false);
    }
  }

  return (
    <Autocomplete
      disabled={isLoading}
      multiple
      fullWidth
      value={items}
      onChange={async (event, newArray) => {
        const isAddOperation = items.length < newArray.length;
        const newValue = newArray.at(-1);
        if (!isAddOperation) {
          return setItems(newArray);
        }
        if (typeof newValue === "string") {
          setItems((prevItems) => prevItems.concat(newValue.inputValue));
        } else if (newValue && newValue.inputValue) {
          const newItem = await createNewItem(newValue.inputValue);
          setItems((prevItems) => prevItems.concat(newItem));
        } else if (newValue) {
          setItems((prevItems) => {
            return prevItems.concat(newValue);
          });
        } else {
          setItems(newArray);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params).filter(({ _id }) =>
          items.every((i) => i._id !== _id)
        );

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (!disabledCreation && inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={fetchedItems || []}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      isOptionEqualToValue={(option, value) => {
        return option.name === value.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      // freeSolo
      renderTags={(value, getItemProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.name}
            {...getItemProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          color="primary"
          sx={{ ".MuiOutlinedInput-root": jsStyles.textField, ...sx }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default MultipleCreatableInput;
