import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import React from "react";
import jsStyles from "../styles/jsStyles";

function AppSelect({
  value,
  setValue,
  label,
  children,
  error,
  helperText = "",
  sx = {},
  ...props
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl
      fullWidth
      sx={{ ".MuiOutlinedInput-root": jsStyles.select, ...sx }}
    >
      <InputLabel id="demo-simple-select-label" error={error}>
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Age"
        onChange={handleChange}
        {...props}
      >
        {children}
      </Select>
      {Boolean(helperText) && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default AppSelect;









// import React from "react";
// import { FormControl, FormHelperText, TextField, Autocomplete } from "@mui/material";
// import jsStyles from "../styles/jsStyles";

// function AppSelect({
//   value = [],  // Default to an empty array
//   setValue,
//   label,
//   children,
//   error,
//   helperText = "",
//   sx = {},
//   ...props
// }) {
//   // Convert children to options array, defaulting to an empty array if null
//   const options = React.Children.map(children, (child) => {
//     return child
//       ? {
//           label: child.props.children, // Assuming the display text is the child content
//           value: child.props.value,
//         }
//       : null;
//   }) || [];

//   const handleChange = (event, newValue) => {
//     setValue(newValue || []);  // Default to an empty array if newValue is null
//   };

//   return (
//     <FormControl
//       sx={{ ".MuiAutocomplete-root": jsStyles.autocomplete, ...sx }}
//       fullWidth 
//     >
//       <Autocomplete

//         multiple
//         value={value}
//         onChange={handleChange}
//         options={options}
//         getOptionLabel={(option) => option.label || ""}
//         isOptionEqualToValue={(option, value) => option.value === value.value}
//         renderInput={(params) => (
//           <TextField
    
//             {...params}
//             label={label}
//             error={error}
//             helperText={helperText}
//           />
//         )}
//         {...props}
//       />
//       {Boolean(helperText) && (
//         <FormHelperText error={error}>{helperText}</FormHelperText>
//       )}
//     </FormControl>
//   );
// }

// export default AppSelect;
