import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import AppAvatar from "./AppAvatar";
import { useUser } from "../context/user";

import { grey } from "@mui/material/colors";
import { fileToBase64 } from "../utils/files";
import { appFetch } from "../utils/fetch";
import AppEditIcon from "./AppEditIcon";
import { useSnackbar } from "notistack";
import { useId } from "react";
import FileResizer from "react-image-file-resizer";

function EditableProfilePicture({ size = 80, isNpoLogo = false, sx = {} }) {
  const { user, setUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const id = useId();

  async function onChange(ev) {
    const file = ev.target.files[0];

    // RESIZE IMAGE
    FileResizer.imageFileResizer(
      file,
      600,
      600,
      "PNG",
      100,
      0,
      async (resizedFile) => {
        // validate file size
        const fileIsTooBig = resizedFile.size / 1024 ** 2 > 2;
        if (fileIsTooBig) {
          enqueueSnackbar("File size is greater then 2 mb", {
            variant: "error",
          });
          return;
        }
        // UPLOAD IMAGE
        try {
          setLoading(true);
          // set image in ui
          const dataUrl = await fileToBase64(resizedFile);
          setProfileImage(dataUrl);

          // upload
          const payload = {
            image: {
              data: dataUrl,
              type: resizedFile.type,
            },
          };
          const uploadUrl =
            "/api/file/" + (!isNpoLogo ? "profile-image" : "npo-logo");
          const fieldKey = !isNpoLogo ? "profileImage" : "npoLogo";
          const uploadRes = await appFetch(uploadUrl, {
            method: "POST",
            body: JSON.stringify(payload),
          });
          if (!uploadRes.ok) throw new Error();
          const { image } = await uploadRes.json();

          // UPDATE USER
          const updateUserRes = await appFetch("/api/user/", {
            method: "PUT",
            body: JSON.stringify({
              [fieldKey]: image,
            }),
          });
          if (!updateUserRes.ok) throw new Error();
          setUser({ ...user, [fieldKey]: image });
        } catch (err) {
          enqueueSnackbar("An Error Has occurred.", { variant: "error" });
          setProfileImage(null);
        } finally {
          setLoading(false);
        }
      },
      "file"
    );
  }

  return (
    <Box position="relative" sx={sx}>
      <Box display="flex" position="relative">
        <AppAvatar
          user={user}
          uri={profileImage}
          isNpoLogo={isNpoLogo}
          size={size}
          variant={isNpoLogo ? "rect" : undefined}
          sx={{ opacity: loading ? 0.5 : 1 }}
        />
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
            }}
          >
            <CircularProgress sx={{ color: grey[500] }} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          bottom: -18,
          right: 5,
          position: "absolute",
          display: "flex",
          opacity: loading ? 0.5 : 1,
        }}
      >
        <input
          type="file"
          name="profile-image"
          id={id}
          style={{ display: "none" }}
          onChange={onChange}
          disabled={loading}
          accept="image/*"
        />
        <AppEditIcon
          component="label"
          outlined={false}
          htmlFor={id}
          style={{ cursor: !loading ? "pointer" : "", display: "flex" }}
          size="1.8em"
        />
      </Box>
    </Box>
  );
}

export default EditableProfilePicture;
