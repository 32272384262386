import GroupIcon from "@mui/icons-material/Group";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Diversity2Icon from "@mui/icons-material/Diversity2";

const adminRoutes = [
  { title: "Users", Icon: GroupIcon, href: "/admin/users" },
  { title: "Npos", Icon: Diversity2Icon, href: "/admin/npos" },
  { title: "Projects", Icon: AppRegistrationIcon, href: "/admin/projects" },
  { title: "Npos OLD", Icon: Diversity2Icon, href: "/admin/npos-old" },
];

export default adminRoutes;
