import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { themeColors } from "../../../utils/constant";
import { formatHref } from "../../../utils/tools";
import { validateByRules } from "../../../utils/validators";

const EditableLinkItem = React.forwardRef(
  ({ user, isEditMode, link, linkType }, ref) => {
    const fullUrl =
      linkType !== "email"
        ? formatHref(user[linkType])
        : `mailto:${user[linkType]}`;
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Box
        component={"a"}
        target="_blank"
        key={linkType}
        href={isEditMode ? undefined : fullUrl}
        rel="noreferrer"
        className="link"
        sx={{
          opacity: !isEditMode && !user[linkType] ? 0.4 : 1,
          pointerEvents: !user[linkType] ? "none" : "",
          cursor: isEditMode ? "text !important" : "none",
          backgroundColor: {
            xs: themeColors.indigo500 + "22",
            sm: "transparent",
          },
          borderRadius: {
            xs: 20,
            sm: 0,
          },
          p: {
            xs: ".8rem !important",
            sm: 0,
          },
        }}
      >
        <img
          src={link.icon}
          alt="icon"
          style={{
            width: "1em",
            height: "1em",
            objectFit: "contain",
          }}
        />
        {!isEditMode || linkType === "email" ? (
          <Typography display={{ xs: "none", sm: "inline" }}>
            {link.label}
          </Typography>
        ) : (
          <Typography
            p={0.5}
            minWidth={80}
            suppressContentEditableWarning={true}
            contentEditable={isEditMode}
            ref={ref}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
              }
            }}
            onInput={(ev) => {
              const errorMessage = validateByRules(
                linkType === "linkedinURL" ? "linkedinUrl" : "url",
                ev.target.textContent
              );
              if (errorMessage) ref.current.classList.add("error");
              else ref.current.classList.remove("error");
            }}
            sx={
              isEditMode
                ? {
                    outline: `1px ${themeColors.indigo300} solid`,
                    outlineColor: themeColors.orangeGradientEnd,
                    borderRadius: 1,
                    "&.error": {
                      outline: `1px red solid`,
                    },
                  }
                : {}
            }
          >
            {user[linkType]}
          </Typography>
        )}
      </Box>
    );
  }
);

export default EditableLinkItem;
