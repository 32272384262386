import React, { createContext, useContext, useState } from "react";

const ChatContext = createContext({
  weavyClient: null,
  setWeavyClient: () => {},
});

export const ChatProvider = ({ children }) => {
  const [weavyClient, setWeavyClient] = useState(null);

  return (
    <ChatContext.Provider
      value={{
        weavyClient,
        setWeavyClient,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export function useChat() {
  return useContext(ChatContext);
}
