import { Box, Typography } from "@mui/material";
import React from "react";
import { themeColors } from "../utils/constant";

const ProjectTags = ({ tags, sx = {}, ...props }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={"1.1em"}
      gap={0.5}
      sx={sx}
      {...props}
    >
      {tags.map((tag, index) => (
        <React.Fragment key={tag._id}>
          {index !== 0 && <Typography color={themeColors.orange}>|</Typography>}
          <Typography
            key={tag._id}
            variant="text2"
            color={themeColors.indigo700 + "77"}
          >
            {tag.name}
          </Typography>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ProjectTags;
