import { useNavigate } from "react-router-dom";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { APP_TYPES, PROJECT_STATUSES } from "../data/lists";
import AppAvatar from "./AppAvatar";
import { useUser } from "../context/user";
import { getTextByValue } from "../utils/tools";
import jsStyles from "../styles/jsStyles";
import { Stack } from "@mui/system";
import { themeColors } from "../utils/constant";
import React from "react";
import ProjectTags from "./ProjectTags";

import { ReactComponent as EyeIcon } from "../images/icons/eye.svg";
import useSWR from "swr";
import { swrFetcher } from "../utils/fetch";
import vogoImage from "../images/logo192.png";

const ProjectCard = ({
  _id,
  name,
  tags,
  logo,
  npo,
  appType,
  team,
  status,
  hideTeam = false,
  marketplaceMode = false,
}) => {
  let navigate = useNavigate();
  const { user } = useUser();

  const { data: projectViews } = useSWR(
    `/api/project/${_id}/view`,
    swrFetcher,
    { revalidateIfStale: false }
  );

  const isTeamMember =
    team.some(({ chosenUser }) => chosenUser?._id === user._id) ||
    user._id === npo?._id;
  const handleClick = () => {
    const url = !isTeamMember ? `/project/${_id}` : `/project/${_id}/status`;
    navigate(url);
  };

  const padding = 1.8;

  const teamIsEmpty = team?.every((i) => !i.chosenUser);

  const CardDivider = () => (
    <Divider sx={{ bgcolor: themeColors.indigo500 + "77" }} />
  );

  const logoSrc = logo?.url || npo?.npoLogo?.url || vogoImage;

  return (
    <Stack
      width={300}
      sx={{
        ...jsStyles.cardBorder,
        transition: ".15s box-shadow",
        cursor: "pointer",
        ":hover": jsStyles.shadowSm,
      }}
      onClick={handleClick}
    >
      <Box p={padding} pb={marketplaceMode ? 1 : ""}>
        <Box
          display="flex"
          gap={2}
          mb={padding}
          sx={{
            ".company-logo": {
              height: "4rem",
              width: "4rem",
              objectFit: "contain",
              flexShrink: 0,
            },
          }}
        >
          <img src={logoSrc} className="company-logo" alt="npo-logo" />
          <Box>
            <Typography>{name}</Typography>
            <Typography variant="h3">
              {getTextByValue(APP_TYPES, appType)}
            </Typography>
          </Box>
        </Box>
        <ProjectTags tags={tags} />
      </Box>
      {!marketplaceMode && <CardDivider />}
      <Box display={"flex"} gap={1} p={padding} py={marketplaceMode ? 0 : ""}>
        <Typography fontWeight={700}>Project phase:</Typography>
        <Typography>{getTextByValue(PROJECT_STATUSES, status)}</Typography>
      </Box>
      {!hideTeam && (
        <>
          {!marketplaceMode && <CardDivider />}
          <Box
            style={{ position: "relative" }}
            p={padding}
            py={marketplaceMode ? 3.2 : ""}
          >
            {!teamIsEmpty ? (
              <Team team={team} />
            ) : (
              <Typography>New project</Typography>
            )}
          </Box>
        </>
      )}
      {marketplaceMode && (
        <>
          <CardDivider />
          <Box
            p={padding}
            py={2.2}
            display="flex"
            alignItems={"center"}
            justifyContent={"start"}
            gap={1.7}
          >
            {projectViews === undefined ? (
              <Skeleton width={70} />
            ) : (
              <Box display="flex" alignItems={"center"} gap={0.5}>
                <EyeIcon style={{ width: "1.4em" }} />
                <Typography fontSize={".9rem"} color={"#354998CC"}>
                  {projectViews} Views
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

const Team = ({ team }) => {
  const fullyTeamPositions = team.filter(({ chosenUser }) => chosenUser);
  const isFullTeam = team.length === fullyTeamPositions.length;
  const avatarSize = 31;
  return (
    <Box height={"1.6em"}>
      <Box position="relative">
        {fullyTeamPositions.map(({ chosenUser }, index, arr) => {
          return (
            <AppAvatar
              sx={{
                top: "-.1em",
                left: `${index * (avatarSize * 0.7)}px`,
                zIndex: arr.length + 1 - index,
                position: "absolute",
              }}
              size={avatarSize}
              key={chosenUser._id}
              user={chosenUser}
            />
          );
        })}
      </Box>
      {isFullTeam && (
        <Typography
          ml={`${
            fullyTeamPositions.length * avatarSize * 0.7 + avatarSize * 0.3
          }px`}
          pl={1}
        >
          {/* Full Team */}
        </Typography>
      )}
    </Box>
  );
};

export default ProjectCard;
