// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig =
  process.env.NODE_ENV === "production"
    ? {
        apiKey: "AIzaSyDBYX78Sfu039Y9Cul_o6noUQ7UdHIVvsI",
        authDomain: "vogo-cbfa8.firebaseapp.com",
        projectId: "vogo-cbfa8",
        messagingSenderId: "802715146404",
        appId: "1:802715146404:web:e903e99e1e5f969f07ee31",
      }
    : {
      apiKey: "AIzaSyDBYX78Sfu039Y9Cul_o6noUQ7UdHIVvsI",
      authDomain: "vogo-cbfa8.firebaseapp.com",
      projectId: "vogo-cbfa8",
      messagingSenderId: "802715146404",
      appId: "1:802715146404:web:e903e99e1e5f969f07ee31",
        // apiKey: "AIzaSyADywt7i3Ns6J6UZ_TRTr2HmYkbiP3lu7U",
        // authDomain: "vogo-5d371.firebaseapp.com",
        // projectId: "vogo-5d371",
        // storageBucket: "vogo-5d371.appspot.com",
        // messagingSenderId: "426863138908",
        // appId: "1:426863138908:web:7d269275d2fcba9dc96e99",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const fireAuth = getAuth(app);
