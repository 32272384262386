import React from "react";

import useSWR from "swr";

import { swrFetcher } from "../../utils/fetch";
import UsersDataGrid from "../../components/admin/tables/UsersDataGrid";

const NposPage = () => {
  const { data: users, error, mutate } = useSWR("/api/user?role=2", swrFetcher);
  return (
    <div>
      <UsersDataGrid {...{ users, error, mutate }} />
    </div>
  );
};

export default NposPage;
