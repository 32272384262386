import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import jsStyles from "../styles/jsStyles";
// import useOnclickOutside from "react-cool-onclickoutside";

const PlacesAutocomplete = ({ setPlace, place, showError }) => {
  const {
    ready,
    value,
    suggestions: { data, loading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["(cities)"],
      componentRestrictions: { country: ["il", "usa"] },
    },
    debounce: 300,
  });

  const handlerChangePlace = (event, value) => setPlace(value);

  const handleInput = (e) => setValue(e.target.value);

  return (
    <Box>
      <Autocomplete
        fullWidth
        onChange={handlerChangePlace}
        disabled={!ready}
        label="City"
        value={place}
        options={data}
        renderOption={(props, option) => (
          <li {...props}>{option.description}</li>
        )}
        isOptionEqualToValue={(option, value) =>
          option?.place_id === value?.place_id
        }
        getOptionDisabled={() => loading}
        getOptionLabel={(option) => option?.description || ""}
        filterOptions={(options, params) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            label="City"
            placeholder="Search..."
            //   placeholder={placeholder}
            error={showError}
            helperText={showError ? "Required" : ""}
            onChange={handleInput}
            value={value}
            color="primary"
            sx={{ ".MuiOutlinedInput-root": jsStyles.textField }}
          />
        )}
      />
    </Box>
  );
};

export default PlacesAutocomplete;
