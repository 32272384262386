import React, { useState } from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useSWR from "swr";

import GenericSnackbar from "../../GenericSnackbar";
import WarningDialog from "../dialogs/WarningDialog";
import { appFetch, swrFetcher } from "../../../utils/fetch";
import jsStyles from "../../../styles/jsStyles";

const NposDataGrid = () => {
  const [selectedNpo, setSelectedNpo] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openShowDialog, setOpenShowDialog] = useState(false);

  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const { data: npos, error, mutate } = useSWR("/api/npo", swrFetcher);

  const columns = [
    {
      field: "name",
      headerName: "Npo Name",
      flex: 1,
    },
    {
      field: "contactFullName",
      headerName: "Contact Name",
      flex: 1,
    },
    {
      field: "contactEmail",
      headerName: "Contact Email",
      flex: 1,
    },
    {
      field: "contactPhone",
      headerName: "Contact Phone",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Creation Date",
      valueGetter: ({ value }) => new Date(value).toLocaleDateString("he"),
      flex: 1,
      sortComparator: (a, b) => {
        const parseDate = (str) => {
          const [date, month, year] = str.split(".");
          return new Date(year, month - 1, date);
        };
        return parseDate(a) - parseDate(b);
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 50 * 3,
      cellClassName: "actions",
      getActions: ({ row }) => [
        <Tooltip title="Show" key={`show-btn-${row.id}`}>
          <IconButton
            aria-label="show"
            onClick={() => {
              setOpenShowDialog(true);
              setSelectedNpo(row);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>,
        <Tooltip title="Email" key={`email-btn-${row.id}`}>
          <IconButton
            aria-label="email"
            href={`mailto:${row.contactEmail}`}
            target="_blank"
            disabled={!row.contactEmail}
          >
            <EmailIcon />
          </IconButton>
        </Tooltip>,
        <Tooltip title="Delete" key={`delete-btn-${row.id}`}>
          <IconButton
            aria-label="delete"
            onClick={() => onClickDeleteItem(row)}
            key={`delete-btn-${row._id}`}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>,
      ],
    },
  ];

  function onClickDeleteItem(item) {
    setSelectedNpo(item);
    setOpenDeleteDialog(true);
  }

  async function deleteItem(id) {
    try {
      const res = await appFetch("/api/npo/" + id, {
        method: "DELETE",
      });
      if (!res.ok) {
        throw new Error();
      }
      mutate((prevItems) => prevItems.filter((c) => c._id !== id));
      setSuccessSnackbarMessage("NPO Deleted.");
    } catch (err) {
      console.log(err.message || "An error has occurred");
      setErrorSnackbarMessage("An error has occurred");
    }
  }

  const onCloseDeleteDialog = () => setOpenDeleteDialog(false);
  const onCloseSuccessSnackbar = () => setSuccessSnackbarMessage("");
  const onCloseErrorSnackbar = () => setErrorSnackbarMessage("");

  const DisplayTextField = ({ fieldKey, ...props }) => (
    <TextField
      sx={jsStyles.textField}
      fullWidth
      value={selectedNpo?.[fieldKey]}
      {...props}
      InputProps={{
        readOnly: true,
      }}
    />
  );

  return (
    <Box sx={{ height: "80vh" }}>
      <DataGrid
        rows={npos || []}
        columns={columns}
        getRowId={({ _id }) => _id}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sort
        loading={!npos && !error}
      />
      <Dialog
        open={Boolean(openShowDialog && selectedNpo)}
        onClose={() => {
          setOpenShowDialog(false);
          setSelectedNpo(null);
        }}
      >
        <DialogTitle id="alert-dialog-title">{selectedNpo?.name}</DialogTitle>
        <DialogContent>
          {/* {JSON.stringify(Object.entries(selectedNpo || {}))} */}
          {selectedNpo ? (
            <Stack
              spacing={8}
              sx={{ ...jsStyles.cardBorder, py: 10, px: 10, width: 1 }}
            >
              <Stack>
                <Typography variant="h3" mb={3}>
                  Contact details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="Full name"
                      fieldKey="contactFullName"
                      placeholder="First name and surnamne"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DisplayTextField
                      label="Role"
                      fieldKey="contactRoleInCompany"
                      placeholder="Your role in company"
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <DisplayTextField
                      label="Email"
                      fieldKey="contactEmail"
                      placeholder="Your email address"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DisplayTextField
                      label="Phone"
                      fieldKey="contactPhone"
                      placeholder="(XXX) XX XXXXX XXX"
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack>
                <Typography variant="h3" mb={3}>
                  NPO details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="NPO name"
                      fieldKey="name"
                      placeholder="Association name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="NPO website"
                      fieldKey="website"
                      placeholder="https://"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="What are the main association activities ?"
                      fieldKey="activities"
                      placeholder="Please describe ..."
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack>
                <Typography variant="h3" mb={3}>
                  Solution details
                </Typography>
                <Grid container spacing={2} mb={12}>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="What technological solution do you need ?"
                      fieldKey="solutionRequired"
                      placeholder="Try to describe in your own words the solution ..."
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="What is the problem that this solution helps to solve ?"
                      fieldKey="problemSolutionSolve"
                      placeholder="Please elaborate ..."
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="Please describe how a success looks like in your eyes ?"
                      fieldKey="successfulSolutionVision"
                      placeholder="Please describe ..."
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          ) : null}
        </DialogContent>
      </Dialog>
      <WarningDialog
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        title={`Delete '${selectedNpo?.name}' NPO?`}
        onConfirm={() => deleteItem(selectedNpo._id)}
      />
      <GenericSnackbar
        open={Boolean(successSnackbarMessage)}
        onClose={onCloseSuccessSnackbar}
        message={successSnackbarMessage}
        type="success"
      />
      <GenericSnackbar
        open={Boolean(errorSnackbarMessage || error)}
        onClose={onCloseErrorSnackbar}
        message={errorSnackbarMessage}
        type="error"
      />
    </Box>
  );
};

export default NposDataGrid;
