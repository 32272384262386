import React, { useState } from "react";

import {
  Badge,
  Box,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import useSWR from "swr";

import GenericSnackbar from "../../GenericSnackbar";
import WarningDialog from "../dialogs/WarningDialog";
import { appFetch, swrFetcher } from "../../../utils/fetch";
import AppFAB from "../AppFAB";
import AddProjectDialog from "../dialogs/add-project-dialog/AddProjectDialog";
import {
  PROFESSION_ROLES,
  PROJECT_CONFIRM_STATUSES,
} from "../../../data/lists";
import TeamDialog from "../dialogs/team-dialog/TeamDialog";
import { Link } from "react-router-dom";

const ProjectsDataGrid = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [teamDialogOpen, setTeamDialogOpen] = useState(false);

  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const {
    data: projects,
    error,
    mutate,
  } = useSWR("/api/project/full", swrFetcher);

  const checkThereIsJoinRequestOnEmptyPosition = (project) =>
    [...new Set(project.joinRequests)].some((user) =>
      project.team.some(
        (i) => !i.chosenUser && i.requirements.role === user.professionRole
      )
    );

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: ({ value, row: { _id } }) => (
        <Link to={`/project/${_id}/status`}>{value}</Link>
      ),
      flex: 1,
    },
    {
      field: "confirmStatus",
      headerName: "confirm status",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueGetter: ({ value }) =>
        PROJECT_CONFIRM_STATUSES.find((i) => i.value === value)?.text,
      valueSetter: ({ value, row }) => {
        const val = PROJECT_CONFIRM_STATUSES.find(
          (i) => i.text === value
        )?.value;
        console.log(val);
        return { ...row, status: val };
      },
      valueOptions: PROJECT_CONFIRM_STATUSES.map(({ text }) => text),
    },
    {
      field: "organizationName",
      headerName: "Organization",
      flex: 1,
    },
    {
      field: "team",
      headerName: "Open Roles",
      valueGetter: ({ value: team }) =>
        team
          .filter((i) => !i.chosenUser)
          .map(
            ({ requirements }) =>
              PROFESSION_ROLES.find(({ value }) => value === requirements.role)
                ?.text
          )
          .join(", "),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Creation Date",
      valueGetter: ({ value }) => new Date(value).toLocaleDateString("he"),
      flex: 1,
      sortComparator: (a, b) => {
        const parseDate = (str) => {
          const [date, month, year] = str.split(".");
          return new Date(year, month - 1, date);
        };
        return parseDate(a) - parseDate(b);
      },
    },
    {
      field: "joinRequests",
      width: 80,
      cellClassName: "actions",
      renderHeader: () => (
        <Typography fontSize={"inherit"} fontWeight={500} color="green">
          Team
          {/* {`${
            projects?.filter(checkThereIsJoinRequestOnEmptyPosition).length ||
            "?"
          }
          join requests`} */}
        </Typography>
      ),
      sortable: true,
      sortComparator: (a, b) => {
        const aTeamRequset = a.length;
        const bTeamRequset = b.length;
        return aTeamRequset && !bTeamRequset
          ? -1
          : bTeamRequset && !aTeamRequset
          ? 1
          : 0;
      },
      renderCell: ({ row }) => {
        const isFullTeam = row.team.every((i) => i.chosenUser);
        const thereIsJoinRequestOnEmptyPosition =
          checkThereIsJoinRequestOnEmptyPosition(row);
        return (
          <Badge
            key={`team-btn-${row.id}`}
            overlap="circular"
            // badgeContent={row.joinRequests.length || undefined}
            variant={thereIsJoinRequestOnEmptyPosition ? "dot" : undefined}
            color="primary"
          >
            <IconButton
              onClick={() => onClickManageTeam(row)}
              aria-label="team"
              color={isFullTeam ? "success" : "warning"}
            >
              <GroupIcon />
            </IconButton>
          </Badge>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 50 * 3,
      cellClassName: "actions",
      sortable: true,
      sortComparator: (a, b) => {
        const aTeamRequset = checkThereIsJoinRequestOnEmptyPosition(a);
        const bTeamRequset = checkThereIsJoinRequestOnEmptyPosition(b);
        return aTeamRequset && !bTeamRequset
          ? -1
          : bTeamRequset && !aTeamRequset
          ? 1
          : 0;
      },
      getActions: ({ row }) => {
        return [
          <Tooltip title="Edit" key={`edit-btn-${row.id}`}>
            <IconButton onClick={() => onClickEditItem(row)} aria-label="edit">
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete" key={`delete-btn-${row.id}`}>
            <IconButton
              aria-label="delete"
              onClick={() => onClickDeleteItem(row)}
              key={`delete-btn-${row._id}`}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
        ];
      },
    },
  ];

  async function deleteProject(item) {
    try {
      const id = item._id;
      const res = await appFetch("/api/project/" + id, {
        method: "DELETE",
      });
      if (!res.ok) {
        throw new Error();
      }
      const { imagesDeletionError } = await res.json();
      setSuccessSnackbarMessage(
        `Project Deleted.${
          imagesDeletionError ? " (But can't delete project images)" : ""
        }`
      );
      mutate((prevProjects) => prevProjects.filter((c) => c._id !== id));
    } catch (err) {
      console.log(err.message || "An error has occurred");
      setErrorSnackbarMessage("An error has occurred");
    }
  }

  function onClickDeleteItem(item) {
    setSelectedProject(item);
    setOpenDeleteDialog(true);
  }

  function onClickAddItem() {
    setSelectedProject(null);
    setOpenProjectDialog(true);
  }

  function onClickEditItem(item) {
    setSelectedProject(item);
    setOpenProjectDialog(true);
  }

  function onClickManageTeam(item) {
    setSelectedProject(item);
    setTeamDialogOpen(true);
  }
  function onCloseTeamDialog(item) {
    setSelectedProject(null);
    setTeamDialogOpen(false);
  }

  const onCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedProject(null);
  };
  const onCloseProjectDialog = () => {
    setOpenProjectDialog(false);
    setSelectedProject(null);
  };

  const onCloseSuccessSnackbar = () => setSuccessSnackbarMessage("");
  const onCloseErrorSnackbar = () => setErrorSnackbarMessage("");

  const selectedProjectFromProjects = selectedProject
    ? projects.find(({ _id }) => _id === selectedProject._id)
    : null;

  async function onCellEditCommit({ id, field, value: cellValue }) {
    try {
      let value = cellValue;
      if (field === "confirmStatus") {
        value = PROJECT_CONFIRM_STATUSES.find(
          (i) => i.text === cellValue
        )?.value;
      }
      const res = await appFetch(`/api/project/${id}`, {
        method: "PUT",
        body: JSON.stringify({ [field]: value }),
      });
      if (!res.ok) throw new Error();
      mutate();
      setSuccessSnackbarMessage("Changes saved");
    } catch (err) {
      console.error(err);
      setErrorSnackbarMessage("An error has occurred");
    }
  }
  return (
    <Box sx={{ height: "86vh" }}>
      <DataGrid
        rows={projects || []}
        columns={columns}
        getRowId={({ _id }) => _id}
        onCellEditCommit={onCellEditCommit}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        loading={!projects && !error}
      />
      <AddProjectDialog
        open={openProjectDialog}
        onClose={onCloseProjectDialog}
        editedItem={selectedProject}
        mutate={mutate}
        setSuccessSnackbarMessage={setSuccessSnackbarMessage}
      />
      <TeamDialog
        open={teamDialogOpen}
        onClose={onCloseTeamDialog}
        team={selectedProjectFromProjects?.team}
        joinRequests={selectedProjectFromProjects?.joinRequests}
        projectId={selectedProjectFromProjects?._id}
      />
      <WarningDialog
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        title={`Delete ${selectedProject?.name} project?`}
        onConfirm={() => deleteProject(selectedProject)}
      />
      <GenericSnackbar
        open={Boolean(successSnackbarMessage)}
        onClose={onCloseSuccessSnackbar}
        message={successSnackbarMessage}
        type="success"
      />
      <GenericSnackbar
        open={Boolean(errorSnackbarMessage || error)}
        onClose={onCloseErrorSnackbar}
        message={errorSnackbarMessage}
        type="error"
      />
      <AppFAB label="Add project" onClick={onClickAddItem} />
    </Box>
  );
};

export default ProjectsDataGrid;
