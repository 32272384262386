import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse, Container, MenuItem, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { Box, Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, useNavigate } from "react-router-dom";
import useSWR from "swr";
import AppInput from "../../components/AppInput";
import AppSelect from "../../components/AppSelect";
import GradientButton from "../../components/GradientButton";
import { useProjectsStatus } from "../../context/projectsStatus";
import CONFIG from "../../data/config.json";
import { PROJECT_TEMPLATES } from "../../data/project-templates";
import jsStyles from "../../styles/jsStyles";
import { themeColors } from "../../utils/constant";
import { appFetch, swrFetcher } from "../../utils/fetch";
import { validateByRules } from "../../utils/validators";
import SuccessPublishDialog from "./SuccessPublishDialog";

function PublishProjectPage() {
  const { projectsStatus, updateProjectsStatus } = useProjectsStatus();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [template, setTemplate] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [theNeed, setTheNeed] = useState("");
  const [theValue, setTheValue] = useState("");
  const [tags, setTags] = useState([]);
  const [skills, setSkills] = useState([]);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showInputErrors, setShowInputErrors] = useState(false);
  const { data: fetchedSkills } = useSWR(
    "/api/mutable-list/programm-language",
    swrFetcher,
    {
      revalidateIfStale: false,
    }
  );
  const { data: fetchedTags } = useSWR(
    "/api/mutable-list/project-tag",
    swrFetcher,
    {
      revalidateIfStale: false,
    }
  );

  useEffect(() => {
    if (fetchedSkills && template) {
      const matchedSkills = fetchedSkills.filter((i) =>
        template.skillsRegex.test(i.name)
      );
      if (!matchedSkills?.length) return;
      setSkills(matchedSkills.map((i) => i._id));
    }
  }, [fetchedSkills, template]);

  const onSubmit = async (ev) => {
    ev.preventDefault();
    setShowInputErrors(true);
    const isValid =
      template &&
      tags?.length &&
      !validateByRules("required title", name) &&
      !validateByRules("required description", description) &&
      !validateByRules("required description", theValue) &&
      !validateByRules("required description", theNeed);
    if (!isValid) return;

    try {
      setLoading(true);

      const res = await appFetch("/api/project/npo", {
        method: "POST",
        body: JSON.stringify({
          appType: template.value,
          tags,
          skills,
          theNeed,
          theValue,
          name,
          description,
          team: template.team,
        }),
      });
      if (!res.ok) throw new Error();
      const { data: project } = await res.json();
      setSuccess(true);
      updateProjectsStatus({
        projectsInProcess: projectsStatus.projectsInProcess.concat(project),
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ position: "relative" }}>
      <Helmet>
        <title>vogo impact | create new project</title>
      </Helmet>
      <Stack
        component={"form"}
        onSubmit={onSubmit}
        sx={{
          textAlign: "center",
          mb: 8,
        }}
      >
        <Typography variant="h2" mb={4} mt={8}>
          Create new project
        </Typography>
        <Step
          number={1}
          title="Please choose the kind of project you are interested in working on"
        >
          <Box
            display={"flex"}
            flexWrap="wrap"
            justifyContent={"center"}
            mt={5}
            mb={5}
            gap={1}
          >
            {PROJECT_TEMPLATES.map((item) => {
              const isSelected = template?.title === item.title;
              const errorMode = showInputErrors && !template;
              return (
                <Stack
                  alignItems={"center"}
                  pb={3}
                  px={2}
                  sx={{
                    ...jsStyles.cardBorder,
                    overflow: "hidden",
                    maxWidth: 275,
                    borderColor: errorMode
                      ? red[100]
                      : isSelected
                      ? themeColors.indigo500
                      : themeColors.indigo100,
                    boxShadow: isSelected
                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
                      : "",
                    transitionDuration: ".15s",
                    transitionProperty: "box-shadow",
                    cursor: "pointer",
                    ":hover": {
                      borderColor: themeColors.indigo500,
                    },
                  }}
                  onClick={() => {
                    if (!template)
                      document
                        .querySelector("#project-template")
                        .scrollIntoView({ behavior: "smooth" });
                    setTemplate(item);
                  }}
                  key={item.title}
                >
                  {React.createElement(item.icon, { style: { width: 120 } })}
                  <Typography variant="h4">{item.title}</Typography>
                  <Typography variant="caption">{item.subtitle}</Typography>
                </Stack>
              );
            })}
          </Box>
        </Step>
        <Step
          number={2}
          title="Project Template"
          disabled={!template}
          id="project-template"
        >
          <Stack mt={5} mb={5} gap={2}>
            <AppInput
              label="Project name"
              name="name"
              value={name}
              setValue={setName}
              validationRules={"required title"}
              showError={showInputErrors}
              placeholder="..."
            />
            <AppInput
              label="Tell us in general about your project"
              name="description"
              value={description}
              setValue={setDescription}
              validationRules={"required description"}
              showError={showInputErrors}
              multiline
              rows={4}
              placeholder="Please describe ..."
            />
            <AppSelect
              label="Choose tags that best fits to your project"
              placeholder="Education, medical..."
              name="tags"
              value={tags}
              setValue={setTags}
              required
              multiple
              error={showInputErrors && !tags?.length}
              helperText={showInputErrors && !tags?.length ? "Required" : ""}
            >
              {fetchedTags
                ? fetchedTags.map((item) => (
                    <MenuItem
                      value={item._id}
                      key={item._id}
                      disabled={
                        tags?.length >= 3 && !tags.some((id) => id === item._id)
                      }
                    >
                      {item.name}
                    </MenuItem>
                  ))
                : null}
            </AppSelect>
            <AppInput
              label="What type of technological solution do you need?"
              name="theNeed"
              value={theNeed}
              setValue={setTheNeed}
              validationRules={"required description"}
              showError={showInputErrors}
              multiline
              rows={4}
              placeholder="Please describe ..."
            />
            <AppInput
              label="What is the expected value after the solution is ready?"
              name="theValue"
              value={theValue}
              setValue={setTheValue}
              validationRules={"required description"}
              showError={showInputErrors}
              multiline
              rows={4}
              placeholder="Please describe ..."
            />
            <AppSelect
              label="Required skills"
              placeholder="Product manager, designer ..."
              name="skills"
              value={skills}
              setValue={setSkills}
              required
              multiple
              error={showInputErrors && !skills?.length}
              helperText={
                showInputErrors
                  ? !skills?.length && "Required"
                  : "Custom software development requires certain default skills, but additional skills can be added as needed."
              }
            >
              {fetchedSkills
                ? fetchedSkills.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))
                : null}
            </AppSelect>
            <GradientButton
              loading={loading}
              type="submit"
              label={"POST PROJECT"}
              sx={{ mt: 4, alignSelf: "center" }}
            />
          </Stack>
        </Step>
      </Stack>
      <SuccessPublishDialog
        open={success}
        onClose={() => navigate("/projects")}
      />
    </Container>
  );
}

function Step({ number, title, disabled = false, id, children }) {
  const isExpanded = true;

  return (
    <>
      <Box
        id={id}
        display="flex"
        alignItems="center"
        gap={1}
        my={1}
        py={1}
        sx={{
          opacity: isExpanded ? 1 : 0.7,
          cursor: !isExpanded && !disabled ? "pointer" : "",
          ":hover": { opacity: !disabled ? 1 : "" },
        }}
      >
        {/* <NumberBullet
          number={number}
          size={{ xs: "1.7rem", sm: "1.8rem" }}
          labelProps={{ variant: "body" }}
        /> */}
        <Typography textAlign="left" variant="h3" fontWeight={500}>
          {title}
        </Typography>
        <ChevronRightIcon
          sx={{ color: themeColors.indigo500, transform: "rotateZ(90deg)" }}
        />
      </Box>
      <Collapse in={isExpanded}>{children}</Collapse>
    </>
  );
}

export default PublishProjectPage;
