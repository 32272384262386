import { useState } from "react";
import { Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import GradientTypography from "../components/GradientTypography";
import jsStyles from "../styles/jsStyles";
import { ReactComponent as ChevronDownIcon } from "../images/icons/chevron-down.svg";
import GradientButton from "../components/GradientButton";
import { Controller, useForm } from "react-hook-form";

function NPORegistrationPage() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contactFullName: "",
      contactRoleInCompany: "",
      contactEmail: "",
      contactPhone: "",
      name: "",
      website: "",
      activities: "",
      // actionsToAchieveGoal: "",
      solutionRequired: "",
      problemSolutionSolve: "",
      // didCheckSolutionsInMarket: "",
      // prevSolutionsDescription: "",
      successfulSolutionVision: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      setError("");
      const res = await fetch("/api/npo", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });

      if (!res.ok) throw new Error();

      setSuccess(true);
    } catch (error) {
      // console.error(error);
      setError("Unexpected error occurred. Please contact");
    } finally {
      setLoading(false);
    }
  };

  const SubTitle = ({ subTitle }) => (
    <Box display="flex" alignItems="center" gap={1.0} mb={3}>
      <Typography variant="h3">{subTitle}</Typography>
      <Box mt={0.5}>
        <ChevronDownIcon />
      </Box>
    </Box>
  );

  const HookFormTextField = ({ label, placeholder, fieldKey, ...props }) => {
    const inputName = label
      ? label.toLowerCase().replace(/ /g, "-")
      : props.name;

    const rules = {
      maxLength: props.multiline ? 500 : 40,
      required: true,
    };

    const error = errors[fieldKey];
    const errorMessagesByType = {
      required: "Required field",
      maxLength: "Too long",
    };
    const errorMessage = error && errorMessagesByType[error.type];

    return (
      <Controller
        name={fieldKey}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Stack>
            <TextField
              sx={jsStyles.textField}
              fullWidth
              label={label}
              placeholder={placeholder}
              name={inputName}
              inputProps={rules}
              {...field}
              {...props}
            />
            {error ? (
              <Typography color="error">{errorMessage}</Typography>
            ) : null}
          </Stack>
        )}
      />
    );
  };

  return (
    <Container maxWidth="lg">
      <Stack alignItems="center" mt={14}>
        {!success ? (
          <>
            <GradientTypography mb={9}>Registration form</GradientTypography>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Stack
                spacing={8}
                sx={{
                  ...jsStyles.cardBorder,
                  py: { xs: 5, sm: 10 },
                  px: { xs: 2, sm: 10 },
                  width: 1,
                  mb:14
                }}
              >
                <Stack>
                  <SubTitle subTitle="Contact details" />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="Full name"
                        fieldKey="contactFullName"
                        placeholder="First name and surnamne"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <HookFormTextField
                        label="Role"
                        fieldKey="contactRoleInCompany"
                        placeholder="Your role in company"
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      <HookFormTextField
                        label="Email"
                        fieldKey="contactEmail"
                        placeholder="Your email address"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <HookFormTextField
                        label="Phone"
                        fieldKey="contactPhone"
                        placeholder="(XXX) XX XXXXX XXX"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack>
                  <SubTitle subTitle="NPO details" />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="NPO name"
                        fieldKey="name"
                        placeholder="Association name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="NPO website"
                        fieldKey="website"
                        placeholder="https://"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="What are the main association activities ?"
                        fieldKey="activities"
                        placeholder="Please describe ..."
                        multiline
                        rows={4}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <HookFormTextField
                        label="What the association currently does to achieve the goal ?"
                        fieldKey="actionsToAchieveGoal"
                        placeholder="Please describe ..."
                        multiline
                        rows={4}
                      />
                    </Grid> */}
                  </Grid>
                </Stack>
                <Stack>
                  <SubTitle subTitle="Solution details" />
                  <Grid container spacing={2} mb={12}>
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="What technological solution do you need ?"
                        fieldKey="solutionRequired"
                        placeholder="Try to describe in your own words the solution ..."
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="What is the problem that this solution helps to solve ?"
                        fieldKey="problemSolutionSolve"
                        placeholder="Please elaborate ..."
                        multiline
                        rows={4}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <HookFormTextField
                        label="Did you check other existing solutions in the market ?"
                        fieldKey="didCheckSolutionsInMarket"
                        placeholder="Yes / No"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="What solutions and why its not answering your need ?"
                        fieldKey="prevSolutionsDescription"
                        placeholder="Please describe ..."
                        multiline
                        rows={4}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <HookFormTextField
                        label="Please describe how a success looks like in your eyes ?"
                        fieldKey="successfulSolutionVision"
                        placeholder="Please describe ..."
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                  <GradientButton
                    label="REGISTER NOW"
                    sx={{ alignSelf: "center", width: 300 }}
                    type="submit"
                    loading={loading}
                  />
                  {error ? (
                    <Typography color="error">{error}</Typography>
                  ) : null}
                </Stack>
              </Stack>
            </form>
          </>
        ) : (
          <>
            <Typography variant="h2" align="center" gutterBottom>
              Thanks for registration!
            </Typography>
            <Typography variant="h2" align="center">
              We are now reviewing your request.
            </Typography>
            <Typography variant="h2" align="center">
              You will hear from us in just a few days.
            </Typography>
          </>
        )}
      </Stack>
    </Container>
  );
}

export default NPORegistrationPage;
