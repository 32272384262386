import React, { useState, useEffect } from 'react';
import { Stack, Paper, Box, Button, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskList from './TaskList';
import NewTaskForm from './NewTaskForm';
import { appFetch } from "../../../utils/fetch";
import useNewTaskForm from '../../../hooks/useTaskForm';

export const statusColors = {
  'In Progress': { background: '#fff5d4', color: '#f4cd52' },
  'Done': { background: '#e2f6e0', color: '#79cc73' },
  'Delayed': { background: '#ffe7e6', color: '#ff473c' },
};

const ProjectTasks = ({ activeStatus, project, setAllTasks, allTasks,teamMembers }) => {
  const [tasks, setTasks] = useState([]);
  const [doneTasks, setDoneTasks] = useState([]); // New state for done tasks

  const {
    newTask,
    isEditing,
    titleError,
    handleAddTask,
    handleEditTask,
    handleConfirmTask,
    handleInputChange,
    handleStatusChange,
    handleClose,
  } = useNewTaskForm(project, setAllTasks, activeStatus );


  useEffect(() => {
    if(!project) return
    const fetchTasks = async () => {
      try {
        const response = await appFetch(`/api/projectTask/${project._id}/tasks`, {
          method: "GET",
        });
        if (response.ok) {
          const data = await response.json();
          setAllTasks(data.data);
        } else {
          console.error('Failed to fetch tasks');
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };
    fetchTasks();
  }, [project]);

  useEffect(() => {
    setTasks(allTasks.filter(task => task.type === activeStatus && task.status !== 'Done'));
    setDoneTasks(allTasks.filter(task => task.status === 'Done')); // Filter for 'Done' tasks
  }, [activeStatus, allTasks]);

  const handleToggleTask = (_id) => {
    setTasks(prevTasks =>
      prevTasks.map(task =>
        task._id === _id ? { ...task, checked: !task.checked } : task
      )
    );
  };

  const handleDeleteCheckedTasks = async () => {
    const tasksToDelete = tasks.filter(task => task.checked);
    const deletedTaskIds = [];

    try {
      for (const task of tasksToDelete) {
        const url = `/api/projectTask/${project._id}/tasks/${task._id}`;
        const response = await appFetch(url, {
          method: "DELETE",
        });
        if (response.ok) {
          deletedTaskIds.push(task._id);
        } else {
          throw new Error(`Failed to delete task with ID: ${task._id}`);
        }
      }

      setAllTasks(prevAllTasks => prevAllTasks.filter(task => !deletedTaskIds.includes(task._id)));
    } catch (error) {
      console.error('Error deleting tasks:', error);
    }
  };

  return (
    <Paper sx={{ boxShadow: 'none', padding: 0 }}>
      <Stack>
        <Stack direction="row" alignItems="center">
          <Box sx={{ width: 50, padding: '8px' }} />
          <Box sx={{ width: 200, padding: '8px' }}>
            <Typography variant="h6" align="left">Task Title</Typography>
          </Box>
          <Box sx={{ width: 250, padding: '8px' }}>
            <Typography variant="h6" align="left">Assignment</Typography>
          </Box>
          <Box sx={{ width: 100, padding: '8px' }}>
            <Typography variant="h6" align="left">Type</Typography>
          </Box>
          <Box sx={{ width: 180, padding: '8px' }}>
            <Typography variant="h6" align="left">Status</Typography>
          </Box>
          <Box sx={{ width: 200, padding: '8px' }}>
            <Typography variant="h6" align="left">Date</Typography>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box justifySelf={"end"} sx={{ width: 50, padding: '8px' }}>
            <IconButton aria-label="delete" onClick={handleDeleteCheckedTasks}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Stack>

        <TaskList tasks={tasks} handleToggleTask={handleToggleTask} handleEditTask={handleEditTask} statusColors={statusColors} />

        {/* Additional list for 'Done' tasks */}
        {doneTasks.length > 0 && (
          <>
            <Typography variant="h6" sx={{ marginTop: 2 }}>Done Tasks</Typography>
            <TaskList tasks={doneTasks.filter(item => item.type === activeStatus)} handleToggleTask={handleToggleTask} handleEditTask={handleEditTask} statusColors={statusColors} />
          </>
        )}

        {newTask && (
          <NewTaskForm
            newTask={newTask}
            isEditing={isEditing}
            titleError={titleError}
            teamMembers={teamMembers}
            handleInputChange={handleInputChange}
            handleStatusChange={handleStatusChange}
            handleConfirmTask={handleConfirmTask}
            statusColors={statusColors}
            handleClose={handleClose}
          />
        )}

        {!newTask && (
          <Box sx={{ padding: '16px' }}>
            <Button variant="contained" color ="primary" onClick={()=>handleAddTask({type:activeStatus})}>
            Add Task
            </Button>
            </Box>
            )}
            </Stack>
            </Paper>
            );
            };

export default ProjectTasks;
