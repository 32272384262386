import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Box } from "@mui/system";
import { themeColors } from "../../../utils/constant";
import { Skeleton, useMediaQuery } from "@mui/material";
import ProjectCard from "../../../components/ProjectCard";
import jsStyles from "../../../styles/jsStyles";

function ProjectsCarousel({ containerSx = {}, loading, projects, mutate }) {
  const isXs = useMediaQuery(`@media screen and (max-width: 780px)`);
  const isMd = useMediaQuery(`@media screen and (max-width: 1110px)`);
  const totalSlides = loading ? 3 : projects.length;
  const visibleSlides = Math.min(totalSlides, isXs ? 1 : isMd ? 2 : 3);

  const ProjectCardSkeleton = () => (
    <Box display={"flex"} justifyContent="center">
      <Skeleton
        width={300}
        height={278}
        variant="rectangular"
        sx={{ borderRadius: jsStyles.cardBorderRadius }}
      />
    </Box>
  );
  return (
    <Box sx={{ mr: isXs ? "0" : "auto", mx: isXs ? "auto" : "0" }}>
      <Box
        sx={{
          mx: { xs: 2, sm: 5 },
          width: [isXs ? "75vw" : 325, 662, 990][visibleSlides - 1],
          position: "relative",
          ".btn": {
            bgcolor: themeColors.indigo100,
            border: "none",
            width: "2.1rem",
            height: "2.1rem",
            borderRadius: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            ":hover": {
              bgcolor: themeColors.indigo300,
            },
          },
          ".back": { left: "-2.3rem" },
          ".next": { right: "-2.3rem" },
          ...containerSx,
        }}
      >
        <CarouselProvider
          naturalSlideWidth={document.documentElement.offsetWidth}
          naturalSlideHeight={315}
          totalSlides={totalSlides}
          visibleSlides={visibleSlides}
          isIntrinsicHeight
          infinite
          className="provider"
        >
          <Slider>
            {!loading ? (
              projects?.map((project, index) => (
                <Slide key={project._id} index={index}>
                  <Box display={"flex"} justifyContent="center" p="1px">
                    <ProjectCard
                      marketplaceMode
                      key={project._id}
                      mutate={mutate}
                      {...project}
                    />
                  </Box>
                </Slide>
              ))
            ) : (
              <>
                <Slide index={0}>
                  <ProjectCardSkeleton />
                </Slide>
                <Slide index={1}>
                  <ProjectCardSkeleton />
                </Slide>
                <Slide index={2}>
                  <ProjectCardSkeleton />
                </Slide>
              </>
            )}
          </Slider>
          {visibleSlides < totalSlides && (
            <>
              <ButtonBack className="btn back">
                <ChevronLeftIcon />
              </ButtonBack>
              <ButtonNext className="btn next">
                <ChevronRightIcon />
              </ButtonNext>
            </>
          )}
        </CarouselProvider>
      </Box>
    </Box>
  );
}

export default ProjectsCarousel;
