import { Box, Dialog, Divider, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import MainButton from "../../../components/MainButton";

function JoinRequestDialog({ open, onClose }) {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { px: { xs: 2, sm: 5 }, py: { xs: 4, sm: 6.5 }, m: 2 },
      }}
    >
      <Typography variant="h3" mb={3}>
        Great choice of a Vogo project!{" "}
      </Typography>
      <Typography variant="text1">
        We are going over the request
      </Typography>
      <Typography variant="text1" fontWeight={700}>
          a follow-up email will arrive soon.
      </Typography>
      <Divider sx={{ my: 3 }} />
      <Box mb={3}>
        <Typography variant="text1">
          You can choose up to 3 different projects.
          <br />
          An acceptance for one project will cancel the other projects' requests
          automatically.
        </Typography>
      </Box>
      <Typography mb={4}>
        Want to have a better chance?{" "}
        <Link to="/my-profile">Improve your profile here.</Link>
      </Typography>
      <Box display="flex" justifyContent="center" columnGap={{ xs: 1, sm: 3 }}>
        <MainButton
          labelProps={{ variant: "body1", color: "white" }}
          label="Back to project"
          onClick={onClose}
        />
        <MainButton
          labelProps={{ variant: "body1", color: "white" }}
          label="More projects"
          onClick={() => navigate("/projects")}
        />
      </Box>
    </Dialog>
  );
}

export default JoinRequestDialog;
