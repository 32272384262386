import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AppInput from "../../../../components/AppInput";
import GradientButton from "../../../../components/GradientButton";
import { useUser } from "../../../../context/user";
import { appFetch } from "../../../../utils/fetch";
import { validateByRules } from "../../../../utils/validators";

function Step2() {
  const { user, setUser } = useUser();
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [linkedinUrl, setLinkedinUrl] = useState(user?.linkedinURL || "");
  const [phone, setPhone] = useState(user?.phone || "");

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);

  const validateForm = () => {
    const hasErrors =
      validateByRules("name", firstName) ||
      validateByRules("name", lastName) ||
      validateByRules("linkedinUrl", linkedinUrl) ||
      validateByRules("phone", phone);
    return hasErrors;
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const invalid = validateForm();
    if (invalid) {
      setShowInputErrors(true);
      return;
    }

    setServerError("");

    try {
      setLoading(true);
      const body = {
        firstName,
        lastName,
        phone,
      };
      if (linkedinUrl) body.url = { linkedin: linkedinUrl };

      const res = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify(body),
      });
      const data = await res.json();

      if (!res.ok) throw new Error();

      setUser(data.user);
    } catch (err) {
      setServerError("Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="First name"
            name="firstName"
            value={firstName}
            setValue={setFirstName}
            validationRules={"required name"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="Last name"
            name="lastName"
            value={lastName}
            setValue={setLastName}
            validationRules={"required name"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="Phone"
            name="phone"
            value={phone}
            setValue={setPhone}
            validationRules={"required phone"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="Linkedin URL (optional)"
            name="linkedinURL"
            value={linkedinUrl}
            setValue={setLinkedinUrl}
            validationRules={"linkedinUrl"}
            showError={showInputErrors}
          />
        </Grid>
      </Grid>
      <GradientButton
        sx={{ mt: 8 }}
        label="Next"
        type="submit"
        loading={loading}
      />
      {serverError ? (
        <Typography color="error" textAlign="center" height="1.5em">
          {serverError}
        </Typography>
      ) : null}
    </form>
  );
}

export default Step2;
