import React from "react";
import { Navigate, useLocation } from "react-router";

import WelcomePage from "../../pages/welcome-page/WelcomePage";
import { useUser } from "../../context/user";
import LoadingScreen from "../LoadingScreen";

function UserRoute({ authUserStatus = "approved", allowedRoles, children }) {
  const { user } = useUser();
  let location = useLocation();

  const roleMatch = !allowedRoles || allowedRoles.includes(user?.role);

  // if authUserStatus contains 'null' & user status is undefined - pass
  if (
    (Array.isArray(authUserStatus)
      ? authUserStatus.includes(null)
      : authUserStatus === null) &&
    !user?.status
  )
    return children;
  // if authUserStatus contains user status - pass
  if (
    roleMatch &&
    (Array.isArray(authUserStatus)
      ? authUserStatus.includes(user?.status)
      : user?.status === authUserStatus)
  )
    return children;

  // still trying to login
  if (user === undefined) {
    return <LoadingScreen />;
  }
  // login failed
  if (user === null) {
    if (location.pathname === "/") return <WelcomePage />;
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (user.status === "unconfigured") {
    return <Navigate to="/signup" replace />;
  }
  if (user.status === "not-approved") {
    return <Navigate to="/waiting-for-confirm" replace />;
  }
  if (user.status === "approved") {
    return <Navigate to="/" replace />;
  }
}

export const withUserAuth = (children, authProps = {}) => (
  <UserRoute {...authProps}>{children}</UserRoute>
);

export default UserRoute;
