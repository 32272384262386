import { Typography, Container, Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import GradientDivider from "../components/GradientDivider";
import GradientButton from "../components/GradientButton";
import NumberBullet from "../components/NumberBullet";

const JoinUsPage = () => {
  const navigate = useNavigate();

  const goToNPORegistraionPage = () => navigate("/signup/npo");

  return (
    <Container maxWidth="md">
      {/* <Box display="flex" flexDirection="column" gap={15}></Box> */}
      <Typography
        variant="h3"
        fontSize={"1.5rem"}
        fontWeight={400}
        mb={7}
        mt={9}
      >
        Our community of tech professionals is dedicated to helping NPOs like
        yours harness the power of technology to achieve your goals.
      </Typography>
      <GradientDivider sx={{ maxWidth: 340, mb: 1.8, ml: "auto" }} />
      <Typography variant="h3" fontSize={"1.5rem"} mb={6}>
        Here's how it works?
      </Typography>
      <StepListItem
        number={1}
        title={"Fill out the sign-up form:"}
        description="Please provide us with a brief overview of your organization, so that we can get to know you better. This should take no longer than a few minutes."
      />
      <StepListItem
        number={2}
        title={"Create your own profile:"}
        description="Create a compelling profile that will attract tech professionals and inspire them to volunteer for your organization's projects."
      />
      <StepListItem
        number={3}
        title={"Decide on the project scope:"}
        description="You have the option to select from our templates  or create a new one that aligns with your needs. The project scope can include a new website, a custom software solution, a mobile application, or technical consulting services."
      />
      <StepListItem
        number={4}
        title={"Publish your project:"}
        description="Publish your project: Once the project is published, it will be visible on vogo platform for the community to view. Tech professionals will then have the opportunity to request to join the project."
      />
      <StepListItem
        number={5}
        title={"Approve your team members:"}
        description="Approve your team members: Volunteers that want to be part of your project will ask to contact you and will share their profiles. This way,  you can meet and get to know your future team members and approve when there’s a match."
      />
      <Typography fontWeight={600} mt={6} mb={2}>
        To be part of this community there is a membership fee of 500$ per year.
      </Typography>
      <Typography mb={10}>
        As a member of the community, you will have the opportunity to publish
        new technology projects, collaborate with tech experts and consult with
        other community members on various technical challenges.
      </Typography>

      <Stack alignItems={"center"} mb={14} gap={3}>
        <GradientButton label={"JOIN US"} onClick={goToNPORegistraionPage} />
      </Stack>
    </Container>
  );
};

const StepListItem = ({ number, title, description }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", sm: "row" }}
      ml={3}
      gap={{ xs: 0.5, sm: 1.5 }}
      mb={{ xs: 3, sm: 2 }}
    >
      <NumberBullet
        size={{ xs: "1.7rem", sm: "1.8rem" }}
        labelProps={{ variant: "body" }}
        number={number}
      />
      <Box mt={0.3}>
        <Typography component={"span"} fontWeight={600}>
          {title}{" "}
        </Typography>
        <Typography component={"span"}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default JoinUsPage;
