import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Container, Stack, Typography } from "@mui/material";

import { validateByRules } from "../../utils/validators";

import GradientButton from "../../components/GradientButton";
import GradientTypography from "../../components/GradientTypography";
import jsStyles from "../../styles/jsStyles";
import AppInput from "../../components/AppInput";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async () => {
    const invalid = validateForm();
    if (invalid) {
      setShowInputErrors(true);
      return;
    }

    setLoading(true);
    setServerError("");
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (err) {
      const errorCode = err.code;
      const errorMessage = err.message;
      console.error(err);
      if (errorCode === "auth/user-not-found") {
        setServerError("User not found.");
      } else {
        setServerError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const hasErrors = validateByRules("email", email);
    return hasErrors;
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      {!success ? (
        <>
          <GradientTypography mb={5} mt={4}>
            Change password
          </GradientTypography>
          <Stack
            component="form"
            maxWidth={500}
            sx={{ ...jsStyles.cardBorder }}
            mx="auto"
            mb={10}
            py={5}
            px={{ xs: 2, sm: 4 }}
            alignItems="center"
          >
            <Typography variant="h6" fontWeight={400} sx={{ mb: 4 }}>
              To regain access to your account, please enter your email address
              below and we will send you a link to reset your password
            </Typography>
            <AppInput
              // label="Email"
              placeholder="Insert your mail address"
              value={email}
              name="email"
              setValue={setEmail}
              validationRules={"email"}
              showError={showInputErrors}
              sx={{ input: { textAlign: "center" } }}
            />
            <Typography color="error" height="1em">
              {serverError}
            </Typography>
            <GradientButton
              label="Reset Password"
              onClick={onSubmit}
              loading={loading}
            />
          </Stack>
        </>
      ) : (
        <Stack>
          <Typography
            variant="h5"
            fontWeight="400"
            mt={28}
            maxWidth={600}
            mx="auto"
          >
            A link to reset your password has been sent to the email associated
            with your account.
          </Typography>
        </Stack>
      )}
    </Container>
  );
}

export default ForgotPasswordPage;
