import { Box } from "@mui/material";
import React from "react";
import { themeColors } from "../utils/constant";
import GradientButton from "./GradientButton";

function AppTabs({ tabs, activeTab, setTab, ...props }) {
  return (
    <Box display="flex" gap={2.5} {...props}>
      {tabs.map((tabLabel) => (
        <GradientButton
          key={tabLabel}
          useSingleColor
          singleColor={
            activeTab === tabLabel
              ? themeColors.indigo700
              : themeColors.indigo500 + '99'
          }
          inverted
          label={tabLabel}
          onClick={() => setTab(tabLabel)}
        />
      ))}
    </Box>
  );
}

export default AppTabs;
