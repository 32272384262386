import { getAuth } from "firebase/auth";
const baseUrl = "https://api.vogoexpert.com";
// const baseUrl = "http://localhost:5000";
export async function appFetch(url, options = {}) {
  const token = await getAuth().currentUser.getIdToken();
  // process.env.REACT_APP_API_URL
  return fetch(`${baseUrl}${url}`, {
    headers: { "Content-Type": "application/json", Authorization: token },
    ...options,
  });
}
 
export function swrFetcher(...args) {
  return appFetch(...args)
    .then((res) => res.json())
    .then((json) => json.data);
}
export function swrFetcherNotAuth(...args) {
  const [url, options] = args;
  return fetch(`${baseUrl}${url}`, {
    headers: { "Content-Type": "application/json" },
    ...options,
  })
    .then((res) => res.json())
    .then((json) => json.data);
}
export function swrFetcherNotAuth2(...args) {
  const [url, options] = args;
  return fetch(`${baseUrl}${url}`, {
    headers: { "Content-Type": "application/json" },
    ...options,
  })
    .then((res) => res.json());
}