import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import WebsiteIcon from "../../../images/icons/website.png";

// helper component
const EditableField = ({
  value,
  onChangeItem,
  removeItem,
  href,
  isEditMode,
  isLinksList,
  multiple,
  multiline,
  sx = {},
  textFieldProps = {},
}) => {
  return !isEditMode ? (
    <Box
      component={isLinksList ? "a" : ""}
      href={href}
      className={isLinksList ? "link" : ""}
      sx={{
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: isLinksList ? "nowrap" : "pre-line",
      }}
    >
      {value}
    </Box>
  ) : (
    <>
      <TextField
        onChange={onChangeItem}
        value={value}
        sx={{ wordBreak: isLinksList ? "break-all" : "", ...sx }}
        className="editable-text-field"
        required
        multiline={multiline}
        fullWidth
        {...textFieldProps}
      />
      {multiple && (
        <IconButton size="small" color="error" onClick={removeItem}>
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );
};

function EditableItem({
  label,
  setLabel,
  value,
  setValue,
  multiple,
  multiline,
  onSaveValue,
  isEditMode,
  editButtonComponent,
  isLinksList,
  showLinkType,
  fullyEditable = false,
}) {
  const LINK_TYPES = [
    { regex: /docs.google.com/i, label: "Instructions" },
    { regex: /figma.com/i, label: "Design" },
    { regex: /github.com/i, label: "Github" },
    { regex: /slack.com/i, label: "Slack" },
    { regex: /asana.com/i, label: "Asana" },
  ];

  const onSubmitHandler = (ev) => {
    ev.preventDefault();
    onSaveValue(value);
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Box
        display="flex"
        position="relative"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={"start"}
        gap={{ xs: 1, sm: 0 }}
        py={2}
        pr={6}
      >
        {fullyEditable && isEditMode ? (
          <EditableField
            isEditMode={isEditMode}
            value={label}
            onChangeItem={(ev) => setLabel(ev.target.value)}
            sx={{
              fontWeight: 700,
              width: { xs: "auto", sm: 180 },
              flexShrink: 0,
              pr: 2,
            }}
            textFieldProps={{
              placeholder: "Title",
            }}
          />
        ) : (
          <Typography
            fontWeight={700}
            width={{ xs: "auto", sm: 180 }}
            flexShrink={0}
          >
            {label}
          </Typography>
        )}
        {multiple ? (
          <ul
            style={{
              margin: 0,
              padding: 0,
              listStyle: "none",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            {value.map((item, index) => {
              const absoluteUrl = item.startsWith("http")
                ? item
                : "http://" + item;
              const linkType = LINK_TYPES.find(({ regex }) => regex.test(item));
              const onChangeItem = (ev) => {
                ev.preventDefault();
                const newItems = [...value];
                newItems[index] = ev.target.value;
                setValue(newItems);
              };
              const removeItem = () => {
                const newItems = [...value];
                newItems.splice(index, 1);
                setValue(newItems);
              };

              return (
                <li
                  key={index}
                  style={{
                    marginBottom: ".2em",
                    padding: ".15em",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  {showLinkType && linkType ? (
                    <Typography component={"span"} whiteSpace="nowrap" mr={1}>
                      {linkType.label + " -"}
                    </Typography>
                  ) : null}

                  {isLinksList && (
                    <img
                      src={WebsiteIcon}
                      alt="icon"
                      style={{
                        width: "1em",
                        height: "1em",
                        marginRight: ".3em",
                      }}
                    />
                  )}
                  <EditableField
                    value={value[index]}
                    onChangeItem={onChangeItem}
                    removeItem={removeItem}
                    href={isLinksList ? absoluteUrl : ""}
                    isEditMode={isEditMode}
                    isLinksList={isLinksList}
                    multiple
                  />
                </li>
              );
            })}
            {isEditMode && (
              <li>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => setValue([...value, ""])}
                >
                  {isLinksList ? "Add link" : "Add item"}
                </Button>
              </li>
            )}
          </ul>
        ) : (
          <EditableField
            isEditMode={isEditMode}
            value={value}
            multiline={multiline}
            onChangeItem={(ev) => setValue(ev.target.value)}
            textFieldProps={{
              placeholder: "Description",
              rows: 2,
            }}
          />
        )}
        {editButtonComponent}
      </Box>
    </form>
  );
}

export default EditableItem;
