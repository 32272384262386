export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getTextByValue = (list, value) =>
  list.find((item) => item.value === value)?.text;

export const createOnChangeHandler = (setState) => (ev) =>
  setState(ev.target.value);

export const getFullName = (user) =>
  (user?.fullName || user?.firstName) &&
  (`${user.firstName} ${user.lastName}` || user.fullName);

export const decodeHtml = (html) => {
  if (!html) return "";
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  const value = txt.value;
  txt.remove();
  return value;
};
export const formatHref = (href) => {
  return /^http[s]?:\/\//.test(href) ? href : `https://${href}`;
};
