import { useState } from 'react';
import { appFetch } from "../utils/fetch";


const useNewTaskForm = (project,  setAllTasks, activeStatus) => {
  const [newTask, setNewTask] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const handleAddTask = ({date = null,type = null}) => {
    setIsEditing(false);
    setTitleError(false);
    let currentDate;
    let enumType;
    if(!date){
     currentDate = new Date().toISOString().split('T')[0];
  }
  if(type){
    enumType = type;
 }else if(activeStatus){
  enumType = activeStatus
 }else{
  enumType=""
 }
    setNewTask({ _id: null, title: '', description: '', assignment: [], status: 'In Progress', date: date ? date :currentDate, checked: false, type: enumType });
  };

  const handleEditTask = (task) => {
    setIsEditing(true);
    setTitleError(false);
    setNewTask({ ...task });
  };

  const handleConfirmTask = async () => {
    if (!newTask.title.trim()) {
      setTitleError(true);
      return;
    }

    const url = isEditing
      ? `/api/projectTask/${project._id}/tasks/${newTask._id}`
      : `/api/projectTask/create-task/${project._id}`;
    const method = isEditing ? 'PUT' : 'POST';
    const payload = {
      title: newTask.title,
      assignment: newTask.assignment,
      description: newTask.description,
      status: newTask.status,
      date: newTask.date,
      type: newTask.type,
    };

    try {
      const uploadRes = await appFetch(url, {
        method,
        body: JSON.stringify(payload),
      });

      if (uploadRes.ok) {
        const updatedTask = await uploadRes.json();
        if (isEditing) {
          setAllTasks(prevAllTasks => prevAllTasks.map(task => task._id === newTask._id ? updatedTask.data : task));
        } else {
          setAllTasks(prevAllTasks => [...prevAllTasks, updatedTask.data]);
        }
        setNewTask(null);
        setIsEditing(false);
      } else {
        console.error('Failed to create or update task');
      }
    } catch (error) {
      console.error('Error posting or updating task:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setNewTask(prevState => ({
      ...prevState,
      status: value,
    }));
  };

  const handleClose = () => {
    setNewTask(null);
  };

  return {
    newTask,
    isEditing,
    titleError,
    handleAddTask,
    handleEditTask,
    handleConfirmTask,
    handleInputChange,
    handleStatusChange,
    handleClose,
  };
};

export default useNewTaskForm;
