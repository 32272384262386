import {
  Checkbox,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import MultipleCreatableInput from "../../../../components/admin/MultipleCreatableInput";
import AppSelect from "../../../../components/AppSelect";
import GradientButton from "../../../../components/GradientButton";
import { useUser } from "../../../../context/user";
import {
  HOW_DID_YOU_HEAR_OPTIONS,
  PROFESSION_ROLES,
} from "../../../../data/lists";
import { appFetch } from "../../../../utils/fetch";

function Step3() {
  const { user, setUser } = useUser();
  const [professionRole, setProfessionRole] = useState(
    user?.professionRole || ""
  );
  const [howDidYouHear, setHowDidYouHear] = useState(user?.howDidYouHear || "");
  const [skills, setSkills] = useState(user?.professionPrograms || []);
  const [agreementChecked, setAgreementChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);

  const validateForm = () => {
    const hasErrors = !howDidYouHear || !professionRole || !skills?.length;
    return hasErrors;
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const invalid = validateForm();
    if (invalid) {
      setShowInputErrors(true);
      return;
    }
    setServerError("");

    try {
      setLoading(true);
      const res = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify({
          role: professionRole,
          howDidYouHear,
          professionPrograms: skills?.map((i) => i._id),
        }),
      });
      const data = await res.json();

      if (!res.ok) throw new Error();

      setUser(data.user);
    } catch (err) {
      setServerError("Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack component={"form"} width={{ xs: 1, sm: 450 }} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppSelect
            label="What is your main profession?"
            name="profession"
            value={professionRole}
            setValue={setProfessionRole}
            required
          >
            {PROFESSION_ROLES.map(({ value, text2 }) => (
              <MenuItem value={value} key={value}>
                {text2}
              </MenuItem>
            ))}
          </AppSelect>
        </Grid>
        <Grid item xs={12}>
          <AppSelect
            label="How did you hear about vogo?"
            name="how-did-you-hear"
            value={howDidYouHear}
            setValue={setHowDidYouHear}
            required
          >
            {HOW_DID_YOU_HEAR_OPTIONS.map((value) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </AppSelect>
        </Grid>
        <Grid item xs={12}>
          <MultipleCreatableInput
            items={skills}
            setItems={setSkills}
            apiRoute="/api/mutable-list/programm-language"
            disabledCreation
            label="Please list your skills"
            needAuth={false}
            error={showInputErrors && !skills?.length}
            helperText={showInputErrors && !skills?.length && "Required"}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} alignItems="center">
            <Checkbox
              checked={agreementChecked}
              required
              onChange={() => setAgreementChecked((prev) => !prev)}
            />
            <Typography variant="body2" align="left">
              I acknowledge that I have read and consent to the{" "}
              <a
                target="_blank"
                href={"/trems-of-service.pdf"}
                rel="noreferrer"
              >
                terms of service
              </a>{" "}
              and{" "}
              <a target="_blank" href={"/privacy-policy.pdf"} rel="noreferrer">
                privacy policy
              </a>
              .
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <GradientButton
        sx={{ mt: 8, alignSelf: "center" }}
        label="Next"
        type="submit"
        loading={loading}
        disabled={!agreementChecked}
      />
      {serverError ? (
        <Typography color="error" textAlign="center" height="1.5em">
          {serverError}
        </Typography>
      ) : null}
    </Stack>
  );
}

export default Step3;
