import React, { useState } from "react";
import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validateByRules } from "../../../../utils/validators";
import { useUser } from "../../../../context/user";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { appFetch } from "../../../../utils/fetch";
import GoogleLoginButton from "../../../../components/auth/google-login-button/GoogleLoginButton";
import AppInput from "../../../../components/AppInput";
import GradientButton from "../../../../components/GradientButton";

const googleProvider = new GoogleAuthProvider();

function Step1() {
  const { setUser } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);

  const signupHandler = async (ev, isGoogleSignin) => {
    ev.preventDefault();

    if (!isGoogleSignin) {
      const invalid = validateForm();
      if (invalid) {
        setShowInputErrors(true);
        return;
      }
    }

    const auth = getAuth();
    !isGoogleSignin && setLoading(true);
    setServerError("");

    try {
      //   let token;
      if (isGoogleSignin) {
        const result = await signInWithPopup(auth, googleProvider);
        GoogleAuthProvider.credentialFromResult(result);
        // Signed in
        // token = credential.idToken;
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
        // token = await credential.user.getIdToken();
      }
      const googleDisplayName = await getAuth().currentUser.displayName;
      setLoading(true);
      const res = await appFetch("/api/auth/signup?user_type=npo", {
        method: "POST",
        body: JSON.stringify({
          fullName: isGoogleSignin ? googleDisplayName : "",
        }),
      });
      const data = await res.json();

      if (res.status === 400)
        throw new Error(`${data.error.msg}: ${data.error.param}`);

      setUser(data.user);
    } catch (err) {
      const errorCode = err.code;
      const errorMessage = err.message;
      console.error(err);
      if (errorCode === "auth/email-already-in-use") {
        setServerError("Email already in use.");
      } else if (
        errorCode === "auth/popup-closed-by-user" ||
        errorCode === "auth/cancelled-popup-request"
      ) {
        // do nothing!
      } else {
        setServerError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const hasErrors =
      validateByRules("email", email) || validateByRules("password", password);
    return hasErrors;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack
      component={"form"}
      spacing={2}
      alignItems="center"
      width={{ xs: 1, sm: 450 }}
    >
      <AppInput
        label="Email"
        name="email"
        value={email}
        setValue={setEmail}
        validationRules={"email required"}
        showError={showInputErrors}
      />
      <AppInput
        label="Password"
        value={password}
        name="password"
        setValue={setPassword}
        validationRules={"password required"}
        showError={showInputErrors}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography color="error" height="1.5em">
        {serverError}
      </Typography>
      <GradientButton
        label="Next"
        onClick={(ev) => signupHandler(ev, false)}
        type="submit"
        loading={loading}
        sx={{ mb: 1, minWidth: "13.2rem" }}
      />
      <GoogleLoginButton
        loading={loading}
        label="Sign up with google"
        onClick={(ev) => signupHandler(ev, true)}
        type="submit"
      />
    </Stack>
  );
}

export default Step1;
