import React from "react";
import NposDataGrid from "../../components/admin/tables/NposDataGrid";

function NposPage_OLD() {
  return (
    <div>
      <NposDataGrid />
    </div>
  );
}

export default NposPage_OLD;
