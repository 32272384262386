import React from 'react';
import { Stack } from '@mui/material';
import TaskItem from './TaskItem';

const TaskList = ({ tasks, handleToggleTask, handleEditTask, statusColors }) => (
  <Stack>
    {tasks.map((task, index) => (
      <TaskItem 
        key={task._id}
        task={task}
        index={index}
        handleToggleTask={handleToggleTask}
        handleEditTask={handleEditTask}
        statusColors={statusColors}
      />
    ))}
  </Stack>
);

export default TaskList;
