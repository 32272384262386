import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import LogoSrc from "../../../images/logo.svg";
import GradientButton from "../../GradientButton";

const PublicHeader = () => {
  const navigate = useNavigate();
  const goToLoginPage = () => navigate("/login");

  const LINKS = [
    { label: "Case studies", href: "/#Case-studies-anchor" },
    {
      label: "For tech professionals",
      href: "/#for-tech-juniors-proffesionals-anchor",
    },
    { label: "For NPOs", href: "/#For-NPOs-anchor" },
    { label: "FAQs", href: "/#FAQs-anchor" },
  ];

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const HeaderLink = ({ label, href }) => {
    return (
      <HashLink
        smooth
        scroll={scrollWithOffset}
        to={href}
        style={{ textDecoration: "none" }}
      >
        <Typography
          sx={{
            textDecoration: "none",
            ":hover": {
              textDecoration: "underline",
            },
          }}
        >
          {label}
        </Typography>
      </HashLink>
    );
  };

  return (
    <Container maxWidth="xl">
      <header style={{ height: "4rem" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: 1 }}
        >
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={LogoSrc}
              style={{
                height: "60%",
              }}
              alt="vogo-logo"
            />
          </Link>
          <Box display="flex" alignItems="center" gap={5.5}>
            <Box
              display={{ xs: "none", md: "flex" }}
              alignItems="center"
              gap={4}
            >
              {LINKS.map((props) => (
                <HeaderLink key={props.label} {...props} />
              ))}
            </Box>
            <GradientButton
              label="LOGIN"
              onClick={goToLoginPage}
              inverted
              sx={{ width: 110, minWidth: "auto" }}
            />
          </Box>
        </Box>
      </header>
    </Container>
  );
};

export default PublicHeader;
