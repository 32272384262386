import React from "react";
import useSWR from "swr";
import UsersDataGrid from "../../components/admin/tables/UsersDataGrid";
import { swrFetcher } from "../../utils/fetch";
import { Button } from "@mui/material";

function UsersPage() {
  const { data: users, error, mutate } = useSWR("/api/user?role=1", swrFetcher);
  return (
    <div>
      <UsersDataGrid {...{ users, error, mutate }} />
      <Button
        variant="text"
        disabled={!users}
        href={`mailto:?bcc=${users?.map((i) => i.email).join(",")}`}
        sx={{ mt: 1 }}
      >
        Send bulk email
      </Button>
    </div>
  );
}

export default UsersPage;
