import { Box } from "@mui/system";
import jsStyles from "../styles/jsStyles";

function GradientDivider({ sx = {}, ...props }) {
  return (
    <Box
      height={8}
      sx={{ ...jsStyles.orangeGradient, borderRadius: 10, width:1, ...sx }}
      {...props}
    />
  );
}

export default GradientDivider;
