import { Stack, Typography } from "@mui/material";
import React from "react";

function WaitingForConfirmPage() {
  return (
    <Stack sx={{ textAlign: "center" }} alignItems='center' minHeight={400} mt='20vh'>
      <Typography variant="h3" mb={1}>
        Thank you for signing up!
      </Typography>
      <Typography variant="text1" maxWidth={550}>
        Our team is reviewing your request and will get back to you within a few
        days via your mailbox.
      </Typography>
    </Stack>
  );
}

export default WaitingForConfirmPage;
