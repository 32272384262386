// import React, { useEffect } from "react";
// import { Outlet } from "react-router-dom";
// import Header from "./header/components/Header";
// import LoadingScreen from "./LoadingScreen";
// import { WeavyClient, WeavyProvider } from "@weavy/uikit-react";
// import { useChat } from "../hooks/useChat";
// import { useUser } from "../context/user";
// import { appFetch } from "../utils/fetch";


// const getToken = async (refresh) => {
//   var res = await appFetch(`/api/auth/get-weavy-token?refresh=${refresh}`);
//   if (res.ok) return (await res.json()).access_token;
//   throw new Error("Failed to retrieve token");
// };


// function LayoutForUsers() {
//   const { weavyClient, setWeavyClient } = useChat();
//   const { user } = useUser();

//   useEffect(() => {
//     if (user?.status === "approved" && !weavyClient) {
//       let client = new WeavyClient({
//         url: process.env.REACT_APP_WEAVY_URL,
//         tokenFactory: getToken,
//       });
//       setWeavyClient(client);
//     }
//   }, [user]);

//   return (
//     <>
//       <Header />
//       {user ? (
//         <WeavyProvider
//           client={weavyClient}
//           options={{ enableCloudFiles: true, enableScrollbarDetection: true }}
//         >
//           <main className="LayoutForUsers__main">
//             <Outlet />
//           </main>
//         </WeavyProvider>
//       ) : (
//         <Outlet />
//       )}
//       <LoadingScreen sx={{ zIndex: -10 }} />
//     </>
//   );
// }

// export default LayoutForUsers;
