import useProject from "../hooks/useProject";
import ProjectsPage from "./ProjectsPage";

const HomeProjectsTab = () => {
  const { data, mutate } = useProject();

  return <ProjectsPage data={data} mutate={mutate} />;
};

export default HomeProjectsTab;
