import { Navigate, useLocation } from "react-router";
import { useUser } from "../../context/user";

function NonUserRoute({ children }) {
  const { user } = useUser();
  let location = useLocation();

  const fromUrl =
    location.state?.from?.pathname ||
    ["/admin", "/projects", "/create-project"][user?.role];

  if (user?.status === "approved") {
    return <Navigate to={fromUrl} replace />;
  } else {
    return children;
  }
}

export default NonUserRoute;
