import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { getAuth } from "firebase/auth";
import { Box, TextField, Typography, Paper, Avatar, Stack, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send'; // Importing the Send Icon
import { appFetch } from "../utils/fetch";

const SOCKET_SERVER_URL = "https://api.vogoexpert.com";

// const SOCKET_SERVER_URL = "http://localhost:5000";

const Chat = ({ roomId }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const scrollPositionRef = useRef(null);

  const userImage = "/path/to/user/image.png"; // Set the path for the user image
  const currentUser = {
    name: "Amitai",
    image: "/path/to/current/user/image.png", // Replace with the actual image path of the current user
  };

  // Fetch old messages from the server
  const loadOldMessages = async (initial = false) => {
    if (!hasMore && !initial) return;

    const currentSkip = initial ? 0 : skip;

    try {
      // const response = await fetch(`/api/chat/${roomId}/messages?skip=${currentSkip}&limit=20`);
      const response = await appFetch(`/api/chat/${roomId}/messages?skip=${currentSkip}&limit=20`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        const newMessages = data.data;

        if (initial) {
          setMessages(newMessages.reverse()); // Load first messages and reverse
        } else {
          setMessages((prevMessages) => [...newMessages.reverse(), ...prevMessages]);
        }

        if (newMessages.length < 20) {
          setHasMore(false); // No more messages to load
        }

        if (newMessages.length > 0) {
          setSkip((prevSkip) => prevSkip + 20); // Update skip if we fetched messages
        }
      } else {
        setError('Failed to load messages');
      }
    } catch (error) {
      setError('Error loading messages: ' + error.message);
    }
  };

  useEffect(() => {
    const initializeSocket = async () => {
      try {
        const token = await getAuth().currentUser.getIdToken();

        if (!socketRef.current) {
          const socket = io(SOCKET_SERVER_URL, {
            extraHeaders: {
              Authorization: `Bearer ${token}`,
            }
          });

          socketRef.current = socket;

          socket.on('connect_error', (err) => {
            setError("Connection failed: " + err.message);
          });

          socket.on('receiveMessage', (data) => {
            setMessages((prevMessages) => [...prevMessages, data]);
          });

          socket.emit('joinRoom', roomId); // Join the room
        }
      } catch (err) {
        setError('Failed to get token or connect to chat: ' + err.message);
      }
    };

    initializeSocket();

    // Load initial 20 messages
    loadOldMessages(true);

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [roomId]);

  // Send message to the server
  const sendMessage = () => {
    if (message.trim() && socketRef.current) {
      const timestamp = new Date(); // Add the timestamp here
      socketRef.current.emit('sendMessage', { room: roomId, message, timestamp });
      setMessage(""); // Clear the message input after sending
    }
  };

  // Scroll to bottom for new messages only
  useEffect(() => {
    if (scrollPositionRef.current === null && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Handle scroll to load more messages
  const handleScroll = (e) => {
    if (e.target.scrollTop === 0 && hasMore) {
      scrollPositionRef.current = e.target.scrollHeight; // Save scroll position before loading more
      loadOldMessages(); // Load more messages when scrolled to the top
    }
  };

  // Restore scroll position after loading old messages
  useEffect(() => {
    if (scrollPositionRef.current !== null && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - scrollPositionRef.current;
      scrollPositionRef.current = null; // Reset scroll position
    }

  }, [messages]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
  };

  return (
    <Paper elevation={3} sx={{ height: '80vh', display: 'flex', flexDirection: 'column', padding: 2 }}>
      <Box
        ref={messagesContainerRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: 2,
          '&::-webkit-scrollbar': {
            width: '8px', // Make the scrollbar thinner
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c1c1c1', // Scrollbar color
            borderRadius: '4px', // Rounded edges
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555', // Darker color when hovering
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1', // Track color
          },
        }}
        onScroll={handleScroll}
      >
        {messages.map((msg, index) => (
          <Stack key={index} direction="row" spacing={2} alignItems="flex-start" sx={{ marginBottom: 3 }}>
            {/* Avatar with larger size */}
            <Stack sx={{ width: 65, height: 65 }}>
              <Avatar sx={{ width: "100%", height: "100%" }}  src={ userImage} alt={msg.sender.firstName}  />
              <Typography variant="subtitle2" component="div" fontWeight="bold" sx={{ textAlign: 'center' }}>
                {msg.sender.firstName}
              </Typography>
            </Stack>

            <Box>
              <Box
                sx={{
                  backgroundColor: '#f0f0f0',
                  padding: '26px',
                  borderRadius: '55px',
                  minHeight: '67px',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '300px',
                  minWidth: '130px',
                  position: "relative"
                }}
              >
                <Typography variant="body1" component="span" sx={{ color: '#333' }}>
                  {msg.message}
                </Typography>
                <Box sx={{ position: "absolute", bottom: 2, right: 0, paddingRight:2 }}>
                  <Typography alignSelf={"end"} variant="caption" component="span" sx={{ marginRight: '10px', color: '#666', }}>
                    {formatTime(msg.timestamp)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input area with user's avatar */}
      <Box sx={{ display: 'flex', padding: 1, alignItems: 'center' }}>{}
        <Avatar alt={currentUser.name} src={currentUser.image} sx={{ width: 80, height: 80 }} /> {/* Larger avatar in the input */}
        <TextField
          fullWidth
          inputProps={{
            style: {
              height: "70px",
            },
          }}
          variant="outlined"
          size="small"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message"
          onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)}
          sx={{
            marginLeft: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '50px',
              height: '70px', // Set the height to match the Avatar
            },
          }}
        />
        <IconButton
          color="primary"
          sx={{ marginLeft: 1, height: '70px', width: '70px' }} // Ensure button matches input height
          onClick={sendMessage}
        >
          <SendIcon /> {/* Send icon from Material UI */}
        </IconButton>
      </Box>

      {error && (
        <Typography color="error" sx={{ textAlign: 'center', marginTop: 1 }}>
          {error}
        </Typography>
      )}
    </Paper>
  );
};

export default Chat;
