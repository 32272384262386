import { Box, Typography } from "@mui/material";
import React from "react";
import jsStyles from "../styles/jsStyles";
import { themeColors } from "../utils/constant";

function NumberBullet({
  number,
  inactive = false,
  sx = {},
  labelProps = {},
  size = { xs: "2.3rem", sm: "3.2rem" },
  ...props
}) {
  
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        ...(!inactive
          ? jsStyles.orangeGradient
          : { backgroundColor: themeColors.indigo300 }),
        borderRadius: 100,
      }}
      sx={{
        width: size,
        height: size,
        flexShrink: 0,
        ...sx,
      }}
      {...props}
    >
      <Typography variant="h3" color="white" {...labelProps}>
        {number}.
      </Typography>
    </Box>
  );
}

export default NumberBullet;
