import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import React from "react";

function MainButton({
  label,
  onClick,
  isLoadingButton,
  labelProps = {},
  sx = {},
  ...props
}) {
  const Component = isLoadingButton ? LoadingButton : Button;
  return (
    <Component
      variant="contained"
      onClick={onClick}
      sx={{
        height: "55px",
        width: "185px",
        borderRadius: "60px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        whiteSpace: "pre",
        py: "3px",
        px: "34px",
        ...sx,
      }}
      {...props}
    >
      <Typography
        fontWeight={700}
        variant="text1"
        lineHeight={1.2}
        {...labelProps}
      >
        {label}
      </Typography>
    </Component>
  );
}

export default MainButton;
