import React, { createContext, useContext, useState } from 'react';

const initialState = {
  waitingRequests: [],
  projectsInProcess: [],
  finishedProjects: [],
};

const ProjectsStatusContext = createContext({
  projectsStatus: initialState,
  updateProjectsStatus: null,
});

export const ProjectsStatusContextProvider = ({ children }) => {
  const [projectsStatus, setProjectsStatus] = useState(initialState);

  const updateProjectsStatus = (data) => {
    setProjectsStatus((prevStatus) => ({
      ...prevStatus,
      ...data,
    }));
  };

  return (
    <ProjectsStatusContext.Provider
      value={{ projectsStatus, updateProjectsStatus }}
    >
      {children}
    </ProjectsStatusContext.Provider>
  );
};

export function useProjectsStatus() {
  return useContext(ProjectsStatusContext);
}
