import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { themeMeasurements } from "../../utils/constant";

function NPOBenefitArticle({ title, description, iconSrc }) {
  const theme = useTheme();

  const isRowMode = useMediaQuery(theme.breakpoints.only("sm"));

  return (
    <Box
      bgcolor="white"
      borderRadius={themeMeasurements.borderRadiusBase}
      px={3}
      py={4}
      width={1}
    >
      <Box
        display="flex"
        flexDirection={isRowMode ? "row" : "column"}
        columnGap={4}
        maxWidth={isRowMode ? "auto" : 234}
        alignItems={{ xs: "start" }}
        sx={{
          ".image": {
            width: 55,
            height: 55,
            objectFit:'contain'
          },
        }}
      >
        <img
          className="image"
          src={iconSrc}
          alt={title}
          style={{
            marginBottom: ".5rem",
            width: "",
            flexShrink: 0,
            objectFit: "contain",
          }}
        />
        <Box>
          <Typography variant="h4" mb={2}>
            {title}
          </Typography>
          <Typography maxWidth={isRowMode ? 234 : "auto"} textAlign="left">
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default NPOBenefitArticle;
