import { Box, LinearProgress } from "@mui/material";
import React from "react";

function LoadingScreen({ sx = {} }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: 1.2,
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "#fff",
        zIndex: 1000,
        ".MuiLinearProgress-bar": { borderRadius: 10 },
        ...sx,
      }}
    >
      Loading
      <LinearProgress sx={{ width: 100, borderRadius: 10, height: 6 }} />
    </Box>
  );
}

export default LoadingScreen;
