import useProject from "../hooks/useProject";
import ProjectsPage from "./ProjectsPage";
import { useUser } from "../../../context/user";

const MyProjectsTab = () => {
    const { user } = useUser();
    const { data } = useProject(user?._id);

    return (
        <ProjectsPage data={data} isMyProjectsPage />
    );
};

export default MyProjectsTab;