import React, { useState } from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { appFetch } from "../../../utils/fetch";
import { USER_STATUSES } from "../../../data/lists";
import { Link } from "react-router-dom";
import { getFullName } from "../../../utils/tools";
import jsStyles from "../../../styles/jsStyles";

import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";

const UsersDataGrid = ({ users, error, mutate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [selectedUser, setSelectedUser] = useState(null);
  const [openShowDialog, setOpenShowDialog] = useState(false);

  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      renderCell: ({ value, row }) => (
        <Link to={`/profile/${row.uid}`}>
          {value || `${row.firstName} ${row.lastName}`}
        </Link>
      ),
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueGetter: ({ value }) =>
        USER_STATUSES.find((i) => i.value === value)?.text,
      valueSetter: ({ value, row }) => {
        const val = USER_STATUSES.find((i) => i.text === value)?.value;
        console.log(val);
        return { ...row, status: val };
      },
      valueOptions: USER_STATUSES.map(({ text }) => text),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Creation Date",
      valueGetter: ({ value }) => new Date(value).toLocaleDateString("he"),
      flex: 1,
      sortComparator: (a, b) => {
        const parseDate = (str) => {
          const [date, month, year] = str.split(".");
          return new Date(year, month - 1, date);
        };
        return parseDate(a) - parseDate(b);
      },
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      valueGetter: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const dateStr = date.toLocaleDateString("he");
        const isToday = dateStr === new Date().toLocaleDateString("he");
        if (!isToday) return dateStr;
        return date.toLocaleTimeString("he");
      },
      flex: 1,
      sortComparator: (a, b) => {
        const parseDate = (str) => {
          const [date, month, year] = str.split(".");
          return new Date(year, month - 1, date);
        };
        return parseDate(a) - parseDate(b);
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 50 * 3,
      cellClassName: "actions",
      getActions: ({ row }) => [
        <Tooltip title="Show" key={`show-btn-${row.id}`}>
          <IconButton
            aria-label="show"
            onClick={() => {
              setOpenShowDialog(true);
              setSelectedUser(row);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>,
        <Tooltip title="Email" key={`email-btn-${row.id}`}>
          <IconButton
            aria-label="email"
            href={`mailto:${row.email}`}
            target="_blank"
            disabled={!row.email}
          >
            <EmailIcon />
          </IconButton>
        </Tooltip>,
        <Tooltip title="Delete" key={`delete-btn-${row.id}`}>
          <IconButton
            aria-label="delete"
            onClick={() => onClickDeleteItem(row)}
            key={`delete-btn-${row._id}`}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>,
      ],
    },
  ];

  async function onClickDeleteItem(item) {
    await confirm();
    try {
      const res = await appFetch("/api/user/" + item.uid, {
        method: "DELETE",
      });
      if (!res.ok) {
        throw new Error();
      }
      mutate((prevUsers) => prevUsers.filter((c) => c.uid !== item.uid));
      enqueueSnackbar("User Deleted.");
    } catch (err) {
      console.log(err.message || "An error has occurred");
      enqueueSnackbar("An error has occurred", { variant: "error" });
    }
  }

  async function onCellEditCommit({ id, field, value: cellValue }) {
    try {
      let url;
      let value = cellValue;
      if (field === "status") {
        value = USER_STATUSES.find((i) => i.text === value)?.value;
        url = "/api/user/change-status/" + id;
        if (value === -1) throw new Error("Role not found");
      }

      const res = await appFetch(url, {
        method: "PUT",
        body: JSON.stringify({ [field]: value }),
      });
      if (!res.ok) throw new Error();

      enqueueSnackbar("Changes saved");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("An error has occurred", { variant: "error" });
    }
  }

  const DisplayTextField = ({ fieldKey, valueGetter, ...props }) => {
    const value = valueGetter
      ? valueGetter(selectedUser)
      : selectedUser?.[fieldKey];
    return (
      <TextField
        sx={jsStyles.textField}
        fullWidth
        value={value}
        disabled={!value}
        {...props}
        InputProps={{
          readOnly: true,
        }}
      />
    );
  };

  return (
    <Box sx={{ height: "80vh" }}>
      <DataGrid
        rows={users || []}
        columns={columns}
        onCellEditCommit={onCellEditCommit}
        getRowId={({ uid }) => uid}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sort
        loading={!users && !error}
      />
      <Dialog
        open={Boolean(openShowDialog && selectedUser)}
        onClose={() => {
          setOpenShowDialog(false);
          setSelectedUser(null);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {getFullName(selectedUser)}
        </DialogTitle>
        <DialogContent>
          {/* {JSON.stringify(Object.entries(selectedNpo || {}))} */}
          {selectedUser ? (
            <Stack
              spacing={8}
              sx={{ ...jsStyles.cardBorder, py: 10, px: 10, width: 1 }}
            >
              <Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="How did you hear about vogo?"
                      fieldKey="howDidYouHear"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="City"
                      valueGetter={(i) => i.city?.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DisplayTextField
                      label="Phone"
                      fieldKey="phone"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          ) : null}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UsersDataGrid;
