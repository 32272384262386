import React, { useState, useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import jsStyles from "../../../styles/jsStyles";

const TimelineStatus = ({ activeStatus, onStatusClick, allTasks }) => {
  const points = [1, 15, 30, 45, 60, 75];
  const labels = ['Gathering Team', 'Defining & Designing', 'Setting Up Environment', 'Development', 'Testing', 'Delivery'];

  const calculateCompletionPercentage = (tasks) => {
    if (!tasks.length) return 0;

    const completedTasks = tasks.filter(task => task.status === 'Done').length;
    const percentage = (completedTasks / tasks.length) * 100;

    return Math.floor(percentage); // This will remove all digits after the decimal point
  };

  const [projectProgressStatus, setProjectProgressStatus] = useState(calculateCompletionPercentage(0));

  useEffect(() => {
    setProjectProgressStatus(calculateCompletionPercentage(allTasks));
  }, [allTasks]);

  return (
    <>
      <Stack
        p={0.8}
        sx={{
          borderRadius: "20px",
          position: 'relative',
          width: '100%',
          height: '45px',
          backgroundColor: '#e5e8f3',
        }}
      >
        <Box
          sx={{
            borderRadius: "20px",
            width: `${projectProgressStatus}%`,
            height: '100%',
            backgroundColor: 'red',
            ...jsStyles.orangeGradient,
            transition: 'width 0.5s ease-in-out', // Adding transition for smooth animation
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              transform: "translateY(15%)"
            }}
          >
            {`${calculateCompletionPercentage(allTasks)}%`}
          </Typography>
        </Box>
      </Stack>
      <Stack
        p={0.4}
        mt={3}
        sx={{
          borderRadius: "20px",
          position: 'relative',
          width: '100%',
          height: '25px',
          backgroundColor: '#e5e8f3',
        }}
      >
        <Stack flexDirection={"row"} height={"100%"} width={"100%"} gap={0.5}>
          {points.map((item, index) => {
            let rangePercentage = 0;
            if (index === points.length - 1) {
              rangePercentage = 100 - item;
            } else {
              rangePercentage = points[index + 1] - item;
            }
            let tasksStatusProgress = 0;
            if (allTasks.length > 0) {
              const tasksStatus = allTasks.filter(task => task.type === labels[index]);
              tasksStatusProgress = calculateCompletionPercentage(tasksStatus);
            }
            const color = tasksStatusProgress === 100 ? { backgroundColor: "#57be4e" } : { ...jsStyles.orangeGradient };

            return (
              <Box
                key={index}
                sx={{
                  borderRadius: "20px",
                  width: `${rangePercentage + (rangePercentage * 0.01)}%`,
                  height: '100%',
                  transition: 'width 0.5s ease-in-out', // Adding transition for smooth animation
                }}
              >
                <Box
                  sx={{
                    borderRadius: "20px",
                    width: `${tasksStatusProgress}%`,
                    height: "100%",
                    ...color,
                    transition: 'width 0.5s ease-in-out', // Adding transition for smooth animation
                  }}
                >
                  {tasksStatusProgress > 10 &&
                    <Typography
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {`${tasksStatusProgress}%`}
                    </Typography>}
                </Box>
              </Box>
            );
          })}
        </Stack>
        {points.map((position, index) => (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              left: `${position + (position * 0.01)}%`,
              top: '70%',
              width: '6px',
              height: '6px',
              backgroundColor: '#354998',
              borderRadius: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
            onClick={() => onStatusClick(labels[index])} // Handle click
          >
            <Box
              sx={{
                position: 'absolute',
                top: '7px',
                left: '50%',
                width: '1px',
                height: '62px',
                backgroundColor: '#7785ba',
                transform: 'translateX(-50%)',
              }}
            />
            <Typography
              sx={{
                position: 'relative',
                top: '52px',
                transform: 'translate(100%, -50%)',
                color: '#7785ba',
                fontWeight: activeStatus === labels[index] ? 'bold' : 'normal',
                fontSize: activeStatus === labels[index] ? '1.2rem' : '1rem',
                textDecoration: activeStatus === labels[index] ? 'underline' : 'none',
              }}
            >
              {labels[index]}
            </Typography>
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default TimelineStatus;
