import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AppInput from "../../../../components/AppInput";
import GradientButton from "../../../../components/GradientButton";
import PlacesAutocomplete from "../../../../components/PlacesAutocomplete";
import { useUser } from "../../../../context/user";
import { appFetch } from "../../../../utils/fetch";
import { validateByRules } from "../../../../utils/validators";
import { swrFetcherNotAuth, swrFetcherNotAuth2} from "../../../../utils/fetch";
import {useEffect} from "react";
function Step2() {
  const { user, setUser } = useUser();
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [linkedinUrl, setLinkedinUrl] = useState(user?.linkedinURL || "");
  const [city, setCity] = useState(user?.city || null);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [isApproveEmail, setIsApproveEmail] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if(user.email){
        try {
          const response = await swrFetcherNotAuth2(`/api/auth/check-already-approve-email/${user.email}`, {
            method: "GET",
          });
     
      
           if (response.emailVerified) {
             setIsApproveEmail(true);
           }
        } catch (error) {
          console.error('Error checking email verification:', error);
          // Optionally, handle the error (e.g., show a notification)
        }
      }
      
    }, 5000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const validateForm = () => {
    const hasErrors =
      validateByRules("required name", firstName) ||
      validateByRules("required name", lastName) ||
      validateByRules("required linkedinUrl", linkedinUrl);
    return hasErrors;
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const invalid = validateForm();
    if (invalid) {
      setShowInputErrors(true);
      return;
    }

    setServerError("");

    try {
      setLoading(true);
      const res = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify({
          firstName,
          lastName,
          url: { linkedin: linkedinUrl },
          city: { name: city?.description, placeId: city?.place_id },
        }),
      });
      const data = await res.json();

      if (!res.ok) throw new Error(data.errorMessage);

      setUser(data.user);
    } catch (err) {
      setServerError(err.message || "Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
const resendVerificationEmail = async()=>{
  if(user.email){
    try {

      const res = await swrFetcherNotAuth("/api/auth/check-email", {
        method: "POST",
        body: JSON.stringify({email:user.email}),
      });
  
      const data = await res.json();
  
      if (!res.ok) throw new Error(data.errorMessage || "Server error");
      if (data.isValidated) {
      } else {
      }
    } catch (err) {
      console.log(err)
    } finally {
  
    }
  }
  
}
  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="First name"
            name="firstName"
            value={firstName}
            setValue={setFirstName}
            validationRules={"required name"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="Last name"
            name="lastName"
            value={lastName}
            setValue={setLastName}
            validationRules={"required name"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="Linkedin URL"
            name="linkedinURL"
            value={linkedinUrl}
            setValue={setLinkedinUrl}
            validationRules={"required linkedinUrl"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PlacesAutocomplete
            place={city}
            setPlace={setCity}
            showError={showInputErrors}
          />
        </Grid>
      </Grid>
      {!isApproveEmail && (
        <Typography sx={{ mt: 2 }} style={{ color: '#4caf50' }}>
          Check your email and confirm it{' '}
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={resendVerificationEmail}
          >
           resend email
          </span>
        </Typography>
      )}
      <GradientButton
      disabled={!isApproveEmail}
        sx={{ mt: 8 }}
        label="Next"
        type="submit"
        loading={loading}
      />
      {serverError ? (
        <Typography color="error" textAlign="center" height="1.5em">
          {serverError}
        </Typography>
      ) : null}
    </form>
  );
}

export default Step2;
