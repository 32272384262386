import React from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { validateByRules } from "../../../../utils/validators";

function LinkInputsGroup({ title, state, setState }) {
  const createOnChangeLinkHandler = (linkIndex) => {
    return (ev) => {
      const value = ev.target.value;
      setState((prevLinks) =>
        prevLinks.map((link, index) => (index !== linkIndex ? link : value))
      );
    };
  };

  const deleteLink = (linkIndex) => {
    setState((prevLinks) =>
      prevLinks.filter((item, index) => linkIndex !== index)
    );
  };

  return (
    <Stack alignItems="flex-start">
      <Typography fontWeight={700}>{title}</Typography>
      <Button
        onClick={() => setState(state.concat(""))}
        variant="contained"
        sx={{ mb: 1 }}
        startIcon={<AddIcon />}
      >
        Add Link
      </Button>
      <Stack spacing={1}>
        {state.map((link, index) => {
          const errorMessage = validateByRules("url", link);
          return (
            <FormControl
              error={Boolean(errorMessage)}
              variant="outlined"
              key={index}
              required
              fullWidth
            >
              <OutlinedInput
                fullWidth
                value={link}
                onChange={createOnChangeLinkHandler(index)}
                inputProps={{ min: 1 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => deleteLink(index)} edge="end">
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
          );
        })}
      </Stack>
    </Stack>
  );
}

export default LinkInputsGroup;
