import { ScrollRestoration } from "react-router-dom";
import { useUser } from "../../../context/user";
import PrivateHeader from "./PrivateHeader";
import PublicHeader from "./PublicHeader";

const Header = () => {
  const { user } = useUser();

  return (
    <>
      <ScrollRestoration />
      {user ? <PrivateHeader /> : <PublicHeader />}
    </>
  );
};

export default Header;
