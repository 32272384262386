import useSWR from "swr";
import { swrFetcher } from "../../../utils/fetch";
import { PROFESSION_ROLES } from "../../../data/lists";
import { useMemo } from "react";
import { getTextByValue } from "../../../utils/tools";
import { getFullName } from "../../../utils/tools";

const useUserProfile = (uidOrUser) => {
  // if its uid - fetch user. if its user object - just use it.
  const itsUid = typeof uidOrUser === "string";

  const { data: fetchedUser } = useSWR(
    itsUid ? `/api/user/${uidOrUser}` : null,
    swrFetcher
  );

  const user = itsUid ? fetchedUser : uidOrUser;
  const { data: projects } = useSWR(
    user ? `/api/project/user/${user._id}` : null,
    swrFetcher
  );

  const role = useMemo(
    () => user && getTextByValue(PROFESSION_ROLES, user.professionRole),
    [user]
  );

  const memberSince = useMemo(() => {
    if (!user) return;
    return new Date(user.createdAt).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    });
  }, [user]);

  const finishedProjectsCount = projects?.filter(
    ({ status }) => status === "done"
  ).length;

  if (user) user.fullName = getFullName(user);

  return {
    user,
    role,
    memberSince,
    finishedProjectsCount,
    projects,
  };
};

export default useUserProfile;
