import { createTheme, experimental_sx as sx } from "@mui/material/styles";
import jsStyles from "../styles/jsStyles";
import { themeColors, themeTypography } from "../utils/constant";

const cssBaselineRoot = () => {
  const colors = {};
  Object.keys(themeColors).forEach((key) => {
    colors[`--${key}`] = themeColors[key];
  });
  return colors;
};

const theme = createTheme({
  palette: {
    primary: {
      main: themeColors.main,
      contrastText: "#fff",
    },
    secondary: {
      main: themeColors.orange,
    },
    mainBackground: themeColors.mainBackground,
    formButton: {
      main: themeColors.buttonColor,
    },
    filterButoon: {
      main: themeColors.secondaryPageBackground,
    },
    themeWhite: { main: "#fff" },
  },
  typography: {
    fontFamily: "Outfit, sans-serif",
    allVariants: {
      color: themeColors.main,
    },
    ...themeTypography,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ":root": {
          ...cssBaselineRoot(),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          ...jsStyles.cardBorderRadius,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiContainer: {
      styleOverrides: { root: sx({ px: { xs: 1, sm: 2, lg: 3 } }) },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
          ":focus-within .MuiOutlinedInput-notchedOutline,:hover .MuiOutlinedInput-notchedOutline":
            { borderColor: themeColors.orange },
        },
        notchedOutline: {},
      },
    },
  },
});

export default theme;
