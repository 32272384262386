import { Alert, Snackbar } from "@mui/material";

const GenericSnackbar = ({ open, onClose, message, type }) => {
  const defaultMessage =
    type === "error"
      ? "An error has occurred"
      : type === "success"
      ? "The operation is complete"
      : "";
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <Alert onClose={onClose} severity={type} sx={{ width: "100%" }}>
        {message || defaultMessage}
      </Alert>
    </Snackbar>
  );
};

export default GenericSnackbar;
