import { Typography } from "@mui/material";
import jsStyles from "../styles/jsStyles";

function GradientTypography({ sx = {}, variant = "h1", children, ...props }) {
  return (
    <Typography
      variant={variant}
      sx={{
        ...jsStyles.orangeGradientText,
        display: "inline-block",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default GradientTypography;
