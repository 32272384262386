import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import NumberBullet from "../../../components/NumberBullet";
import { TECH_PRO_SIGNUP_STEPS } from "../../../data/lists";

function SignupStepper({ activeStepIndex = 0 }) {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "row", sm: "row" }}
      alignItems="center"
      justifyContent="center"
      gap={{ xs: 1, sm: 3 }}
    >
      {TECH_PRO_SIGNUP_STEPS.map((label, index) => {
        const isActive = activeStepIndex === index;
        return (
          <Box
            display="flex"
            alignItems="center"
            gap={{ xs: 1, sm: 2 }}
            key={"NumberBullet" + index}
          >
            <NumberBullet
              number={index + 1}
              inactive={!isActive}
            />
            <Typography
              fontWeight={isActive ? 700 : 400}
              sx={{ display: { xs: isActive ? "" : "none", sm: "inline" } }}
            >
              {label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default SignupStepper;
