import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import {
  AppBar,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import PicturePicker from "../../../PicturePicker";
import MultipleCreatableInput from "../../MultipleCreatableInput";
import { appFetch } from "../../../../utils/fetch";
import {
  PROFESSION_ROLES,
  SENIORITY_TYPES,
  PROJECT_STATUSES,
  APP_TYPES,
  DIFFICULTY_OPTIONS,
  TIME_UNITS_OPTIONS,
} from "../../../../data/lists";
import { validateByRules } from "../../../../utils/validators";
import LinkInputsGroup from "./LinkInputsGroup";
// import { createOnChangeHandler } from "../../../utils/tools";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// TO ADD INPUT:
// 1. add a field in INITIAL_FORM_STATE
// 2. add an input element in render method
// 3. add a validation logic in getValidationError method
// TADDA!!

// PROPS:
// open,
// onClose,
// editedItem,
// mutate,
// setSuccessSnackbarMessage,

const INITIAL_FORM_STATE = {
  status: "",
  name: "",
  organizationName: "",
  tags: [],
  appType: "",
  // estimatedDurationCount: "",
  // estimatedDurationUnit: "",
  // difficultyLevel: "",
  description: "",
  theValue: "",
  theNeed: "",
  // requiredAvailabilityHoursCount: "",
  // requiredAvailabilityPer: "",
  programmingLanguages: [],
  infoLinks: [],
  teamLinks: [],
  meetingLocation: "",
  meetingTiming: "",
  contactPerson: "",
  team: [],
  logoFile: [],
  imageFile: [],

  // status: "done",
  // name: "project name",
  // organizationName: "org name",
  // tags: [],
  // appType: "website",
  // estimatedDurationCount: "3",
  // estimatedDurationUnit: "day",
  // difficultyLevel: "",
  // description: "asdfadsf",
  // theValue: "asdfasdfasdf",
  // theNeed: "asfasdfadsf",
  // requiredAvailabilityHoursCount: "4",
  // requiredAvailabilityPer: "week",
  // programmingLanguages: [],
  // infoLinks: ["ef.co"],
  // teamLinks: [],
  // meetingLocation: "",
  // meetingTiming: "",
  // contactPerson: "",
  // team: [],
  // logoFile: [],
  // imageFile: [],
};

const INITIAL_STATE = {
  form: INITIAL_FORM_STATE,

  inputErrors: Object.keys(INITIAL_FORM_STATE).reduce(
    (prev, current) => ({ ...prev, [current]: "" }),
    {}
  ),
  error: "",
  processText: "",
  loading: false,
};
class AddProjectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
  }

  isEditMode = () => Boolean(this.props?.editedItem);

  componentDidUpdate(prevProps) {
    if (this.isEditMode() && this.props.open !== prevProps.open) {
      // const {
      //   estimatedDuration: {
      //     count: estimatedDurationCount,
      //     unit: estimatedDurationUnit,
      //   },
      //   requiredAvailability: {
      //     hoursCount: requiredAvailabilityHoursCount,
      //     per: requiredAvailabilityPer,
      //   },
      // } = this.props.editedItem;
      this.setState({
        form: {
          ...this.state.form,
          ...this.props.editedItem,
          // estimatedDurationCount,
          // estimatedDurationUnit,
          // requiredAvailabilityHoursCount,
          // requiredAvailabilityPer,
        },
      });
    }
  }

  clearForm() {
    this.setState(INITIAL_STATE);
  }

  onSubmit = async (ev) => {
    ev.preventDefault();

    const { form } = this.state;
    const { logoFile, imageFile } = form;
    const { editedItem, mutate, setSuccessSnackbarMessage } = this.props;

    const validationError = this.getValidationError();
    if (validationError) {
      this.setState({ error: validationError });
      return;
    }

    try {
      this.setState({ loading: true });
      this.setState({ error: "" });

      // 1. UPLOAD IMAGES
      let imagesPayload = { logo: form.logo, image: form.image };
      if (logoFile[0] || imageFile[0]) {
        this.setState({ processText: "Uploading images..." });
        const payload = {};
        if (logoFile[0])
          payload.logo = {
            data: logoFile[0].data_url,
            type: logoFile[0].file.type,
          };
        if (imageFile[0])
          payload.image = {
            data: imageFile[0].data_url,
            type: imageFile[0].file.type,
          };

        const url = !this.isEditMode()
          ? "/api/file/project-images"
          : "/api/file/project-images/" + editedItem._id;
        const uploadRes = await appFetch(url, {
          method: "POST",
          body: JSON.stringify(payload),
        });
        if (!uploadRes.ok) throw new Error();
        const uploadResData = await uploadRes.json();

        imagesPayload = uploadResData;
      }

      // 2. CREATE/UPDATE PROJECT
      this.setState({
        processText: !this.isEditMode()
          ? "Creating project..."
          : "Saving changes...",
      });
      const payload = {
        ...form,
        ...imagesPayload,
        // estimatedDuration: {
        //   count: form.estimatedDurationCount,
        //   unit: form.estimatedDurationUnit,
        //   dayCount:
        //     { day: 1, week: 7, month: 30 }[form.estimatedDurationUnit] *
        //     form.estimatedDurationCount,
        // },
        // requiredAvailability: {
        //   hoursCount: form.requiredAvailabilityHoursCount,
        //   per: form.requiredAvailabilityPer,
        //   hoursPerDay:
        //     form.requiredAvailabilityHoursCount /
        //     { day: 1, week: 7, month: 30 }[form.requiredAvailabilityPer],
        // },
      };

      const url = !this.isEditMode()
        ? "/api/project"
        : "/api/project/" + editedItem._id;
      const createRes = await appFetch(url, {
        method: !editedItem ? "POST" : "PUT",
        body: JSON.stringify(payload),
      });
      if (!createRes.ok) throw new Error();
      await createRes.json();
      const successMessage = !this.isEditMode()
        ? "Project created"
        : "Changes saved";
      setSuccessSnackbarMessage(successMessage);
      mutate();
      this.onCloseDialog();
    } catch (error) {
      console.error(error);
      this.setState({ error: "An error has occurred" });
    } finally {
      this.setState({ processText: "" });
      this.setState({ loading: false });
    }
  };

  getValidationError = () => {
    const { form } = this.state;
    const errors = {};
    const requiredFileds = [
      "status",
      "name",
      "organizationName",
      "tags",
      // "estimatedDurationCount",
      // "estimatedDurationUnit",
      // "difficultyLevel",
      "description",
      "theValue",
      "theNeed",
      // "requiredAvailabilityHoursCount",
      // "requiredAvailabilityPer",
      "programmingLanguages",
      "meetingLocation",
      "meetingTiming",
      "contactPerson",
    ];
    for (const field of requiredFileds) {
      if (
        Array.isArray(INITIAL_FORM_STATE[field])
          ? !form[field].length
          : form[field] === ""
      ) {
        errors[field] = "Required";
      }
    }
    this.setState({ inputErrors: errors });
    return Object.keys(errors).length
      ? "Please check the errors"
      : form.infoLinks.some((url) => validateByRules("url", url))
      ? "Please check the info links"
      : !form.team.length
      ? "Please add team members"
      : form.team.some(({ requirements: { role, seniority } }) => !role)
      ? "Please check the team members"
      : !this.isEditMode() && !form.logoFile[0]
      ? "Please pick logo"
      : !this.isEditMode() && !form.imageFile[0]
      ? "Please pick image"
      : null;
  };

  onCloseDialog = () => {
    this.props.onClose();
    this.clearForm();
  };

  createOnChangeHandler = (key) => {
    const setStateForm = this.setStateForm.bind(this);
    return function (ev) {
      setStateForm(key, ev.target.value);
    };
  };

  setStateForm = (key, value) => {
    if (typeof value === "function") {
      this.setState((prevState) => ({
        form: { ...this.state.form, [key]: value(prevState.form[key]) },
      }));
    } else {
      this.setState({
        form: { ...this.state.form, [key]: value },
      });
    }
  };

  createSetStateFormProp = (key) => {
    const setStateForm = this.setStateForm.bind(this);
    return (value) => setStateForm(key, value);
  };

  CustomSelectComponent = ({
    label,
    formKey,
    items,
    formControlProps = {},
    ...props
  }) => {
    return (
      <FormControl fullWidth required {...formControlProps}>
        <InputLabel>{label}</InputLabel>
        <Select
          fullWidth
          label={label}
          value={this.state.form[formKey]}
          onChange={this.createOnChangeHandler(formKey)}
          error={Boolean(this.state.inputErrors[formKey])}
          {...props}
        >
          {items.map(({ value, text }, index) => {
            return (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  CustomTextFieldComponent = ({ label, formKey, ...props }) => {
    return (
      <TextField
        fullWidth
        value={this.state.form[formKey]}
        onChange={this.createOnChangeHandler(formKey)}
        label={label}
        required
        error={Boolean(this.state.inputErrors[formKey])}
        helperText={this.state.inputErrors[formKey]}
        {...props}
      />
    );
  };

  render() {
    const {
      form,

      inputErrors,
      error,
      processText,
      loading,
    } = this.state;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.onCloseDialog}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.onCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {this.isEditMode() ? "Edit" : "Add"} project
            </Typography>
          </Toolbar>
        </AppBar>
        <form onSubmit={this.onSubmit}>
          <Box p={3} display="flex" flexDirection="column" alignItems="center">
            <Grid container spacing={2} mb={3} maxWidth={750}>
              <Grid item xs={6}>
                <this.CustomSelectComponent
                  formKey="status"
                  label="Status"
                  items={PROJECT_STATUSES}
                />
              </Grid>
              <Grid item xs={6}>
                <this.CustomSelectComponent
                  formKey="appType"
                  label="App type"
                  items={APP_TYPES}
                />
              </Grid>
              <Grid item xs={6}>
                <this.CustomTextFieldComponent
                  label="Project name"
                  formKey="name"
                />
              </Grid>
              <Grid item xs={6}>
                <this.CustomTextFieldComponent
                  label="Organization name"
                  formKey="organizationName"
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <MultipleCreatableInput
                  items={form.tags}
                  setItems={(tags) => {
                    this.setStateForm("tags", tags);
                  }}
                  apiRoute="/api/mutable-list/project-tag"
                  label="Tags"
                  placeholder="Education, medical..."
                  error={Boolean(inputErrors.tags)}
                  helperText={inputErrors.tags}
                />
              </Grid>
              {/* <Grid item xs={12} marginBottom={2}>
                <Typography fontWeight={700}>Estimated Duration</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <this.CustomTextFieldComponent
                      formKey="estimatedDurationCount"
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <this.CustomSelectComponent
                      formKey="estimatedDurationUnit"
                      label="Unit"
                      items={TIME_UNITS_OPTIONS.map((i) => ({
                        ...i,
                        text: i.text + "s",
                      }))}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              {/* <Grid item xs={12}>
                <this.CustomSelectComponent
                  formKey="difficultyLevel"
                  label="Difficulty level"
                  items={DIFFICULTY_OPTIONS}
                />
              </Grid> */}
              <Grid item xs={12}>
                <this.CustomTextFieldComponent
                  label="Description"
                  formKey="description"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <this.CustomTextFieldComponent
                  label="The value"
                  formKey="theValue"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <this.CustomTextFieldComponent
                  label="The need"
                  formKey="theNeed"
                  multiline
                  rows={3}
                />
              </Grid>
              {/* <Grid item xs={12} marginBottom={2}>
                <Typography fontWeight={700}>Required availability</Typography>
                <Box flexDirection="row">
                  <this.CustomTextFieldComponent
                    formKey="requiredAvailabilityHoursCount"
                    type="number"
                    inputProps={{ min: 1 }}
                    sx={{ width: 100 }}
                  />
                  <Typography component="span" sx={{ mx: 2 }}>
                    hours a
                  </Typography>
                  <this.CustomSelectComponent
                    formControlProps={{ fullWidth: false }}
                    sx={{ width: 150 }}
                    formKey="requiredAvailabilityPer"
                    label="Unit"
                    items={TIME_UNITS_OPTIONS}
                  />
                </Box>
              </Grid> */}
              <Grid item xs={12} marginBottom={2}>
                <MultipleCreatableInput
                  items={form.programmingLanguages}
                  setItems={(programmingLanguages) => {
                    this.setStateForm(
                      "programmingLanguages",
                      programmingLanguages
                    );
                  }}
                  apiRoute="/api/mutable-list/programm-language"
                  label="Programming Languages"
                  placeholder="Python, React..."
                  error={Boolean(inputErrors.programmingLanguages)}
                  helperText={inputErrors.programmingLanguages}
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <LinkInputsGroup
                  title="Info Links (public)"
                  state={form.infoLinks}
                  setState={this.createSetStateFormProp("infoLinks")}
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <LinkInputsGroup
                  title="Team Links (private)"
                  state={form.teamLinks}
                  setState={this.createSetStateFormProp("teamLinks")}
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Typography fontWeight={700}>Required team</Typography>
                <Button
                  onClick={() =>
                    this.setStateForm(
                      "team",
                      form.team.concat({
                        requirements: { role: "", seniority: "" },
                      })
                    )
                  }
                  variant="contained"
                  sx={{ mb: 2 }}
                  startIcon={<AddIcon />}
                >
                  Add member
                </Button>
                <Stack spacing={1.2}>
                  {form.team.map(
                    (
                      { requirements: { role, seniority }, chosenUser },
                      index
                    ) => {
                      const _setStateForm = this.setStateForm.bind(this);
                      function onChangeRequirement(key) {
                        return (ev) => {
                          _setStateForm("team", (prevTeam) => {
                            const clonedTeam = [...prevTeam];
                            prevTeam[index].requirements[key] = ev.target.value;
                            return clonedTeam;
                          });
                        };
                      }
                      function deleteItem(index) {
                        _setStateForm(
                          "team",
                          form.team.filter((item, i) => index !== i)
                        );
                      }
                      return (
                        <Box display="flex" gap={1} key={index}>
                          <IconButton
                            sx={{ flexShrink: 0 }}
                            onClick={() => deleteItem(index)}
                            edge="end"
                          >
                            <DeleteIcon />
                          </IconButton>
                          <FormControl fullWidth>
                            <InputLabel>Role</InputLabel>
                            <Select
                              fullWidth
                              label="Role"
                              value={role}
                              onChange={onChangeRequirement("role")}
                              required
                            >
                              {PROFESSION_ROLES.map(
                                ({ value, text }, index) => (
                                  <MenuItem key={value} value={value}>
                                    {text}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel>Seniority</InputLabel>
                            <Select
                              fullWidth
                              label="Seniority"
                              value={seniority}
                              onChange={onChangeRequirement("seniority")}
                            >
                              {SENIORITY_TYPES.map(({ value, text }, index) => (
                                <MenuItem key={value} value={value}>
                                  {text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      );
                    }
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <this.CustomTextFieldComponent
                  label="Meeting location"
                  formKey="meetingLocation"
                />
              </Grid>
              <Grid item xs={12}>
                <this.CustomTextFieldComponent
                  label="Meeting timing"
                  formKey="meetingTiming"
                />
              </Grid>
              <Grid item xs={12}>
                <this.CustomTextFieldComponent
                  label="Contact person"
                  formKey="contactPerson"
                />
              </Grid>
              <Grid item xs={12}>
                <PicturePicker
                  maxNumberOfFiles={1}
                  label="LOGO (250x250)"
                  images={form.logoFile}
                  setImages={(val) => this.setStateForm("logoFile", val)}
                />
              </Grid>
              <Grid item xs={12}>
                <PicturePicker
                  maxNumberOfFiles={1}
                  label="IMAGE (1000x310)"
                  images={form.imageFile}
                  setImages={(val) => this.setStateForm("imageFile", val)}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {error ? (
                <Typography color="error" fontWeight="700" mt={1}>
                  {error}
                </Typography>
              ) : null}
              {processText ? (
                <Typography color="info.main" fontWeight="700" mt={1}>
                  {processText}
                </Typography>
              ) : null}
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                loading={loading}
                startIcon={<SaveIcon />}
                size="large"
              >
                {!this.isEditMode() ? "CREATE" : "SAVE"}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Dialog>
    );
  }
}

export default AddProjectDialog;
