import { themeColors } from "../utils/constant";

const orangeGradient = {
  backgroundImage:
    "linear-gradient(106.7deg, var(--orangeGradientStart) 7.81%, var(--orangeGradientEnd) 100%)",
};
const orangeGradientInverted = {
  backgroundImage:
    "linear-gradient(106.7deg, var(--orangeGradientEnd) 7.81%, var(--orangeGradientStart) 100%)",
};

const cardBorderRadius = { borderRadius: ".9rem" };

const jsStyles = {
  orangeGradient,
  orangeGradientInverted,
  orangeGradientText: {
    ...orangeGradient,
    backgroundClip: "text",
    color: "transparent",
  },
  cardBorderRadius,
  cardBorder: {
    borderRadius: cardBorderRadius,
    border: "1px solid",
    borderColor: themeColors.indigo500,
  },
  shadowSm: {
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
  },
  textField: {
    bgcolor: themeColors.indigo100,
    ".MuiOutlinedInput-notchedOutline": { border: "none" },
    borderRadius: 1.5,
    color: `${themeColors.main} !important`,
  },
  select: {
    ".MuiSelect-select": { bgcolor: themeColors.indigo100 },
    ".MuiOutlinedInput-notchedOutline": { border: "none" },
    textAlign: "left",
    borderRadius: 1.5,
  },
  inset0: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default jsStyles;










// import { themeColors } from "../utils/constant";

// const orangeGradient = {
//   backgroundImage:
//     "linear-gradient(106.7deg, var(--orangeGradientStart) 7.81%, var(--orangeGradientEnd) 100%)",
// };

// const sharedInputStyles = {
//   backgroundColor: themeColors.indigo100,
//   color: `${themeColors.main} !important`,
//   borderRadius: 1.5,
//   ".MuiOutlinedInput-notchedOutline": { border: "none" }, // Removes the border
// };

// const jsStyles = {
//   orangeGradient,
//   orangeGradientInverted: {
//     backgroundImage:
//       "linear-gradient(106.7deg, var(--orangeGradientEnd) 7.81%, var(--orangeGradientStart) 100%)",
//   },
//   orangeGradientText: {
//     ...orangeGradient,
//     backgroundClip: "text",
//     color: "transparent",
//   },
//   cardBorderRadius: { borderRadius: ".9rem" },
//   cardBorder: {
//     borderRadius: { borderRadius: ".9rem" },
//     border: "1px solid",
//     borderColor: themeColors.indigo500,
//   },
//   shadowSm: {
//     boxShadow:
//       "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
//   },
//   textField: {
//     ...sharedInputStyles,  // Reusing shared input styles for TextField
//   },
//   select: {
//     ".MuiSelect-select": sharedInputStyles,  // Reusing shared input styles for Select
//     textAlign: "left",
//   },
//   autocomplete: {
//     "& .MuiOutlinedInput-root": sharedInputStyles,  // Reusing shared input styles for Autocomplete
//     "& .MuiAutocomplete-popupIndicator": {
//       color: themeColors.main, // Color for the dropdown indicator
//     },
//     "& .MuiAutocomplete-clearIndicator": {
//       color: themeColors.main, // Color for the clear button
//     },
//     "& .MuiAutocomplete-option": {
//       backgroundColor: themeColors.indigo50, // Background for dropdown options
//       "&[aria-selected='true']": {
//         backgroundColor: themeColors.indigo200, // Background for selected option
//       },
//       "&:hover": {
//         backgroundColor: themeColors.indigo100, // Background on hover
//       },
//     },
//   },
//   inset0: {
//     position: "absolute",
//     top: 0,
//     bottom: 0,
//     right: 0,
//     left: 0,
//   },
// };

// export default jsStyles;
