import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Stack,
  Container,
  Divider,
  Skeleton,
} from "@mui/material";

import useSWR from "swr";

import { swrFetcher } from "../../../utils/fetch";
import { getTextByValue } from "../../../utils/tools";
import MainActionButton from "./MainActionButton";
import JoinRequestDialog from "./JoinRequestDialog";
import TooMuchRequestsDialog from "./TooMuchRequestsDialog";
import TooMuchProjectsDialog from "./TooMuchProjectsDialog";
import ProjectDescriptionItem from "../../../components/ProjectDescriptionItem/ProjectDescriptionItem";
import jsStyles from "../../../styles/jsStyles";
import { themeColors } from "../../../utils/constant";
import { APP_TYPES, PROJECT_STATUSES } from "../../../data/lists";
import ProjectTags from "../../../components/ProjectTags";
import { increaseProjectViews } from "../../../utils/project";
import { useUser } from "../../../context/user";
import { PROJECT_TEMPLATES } from "../../../data/project-templates";
import vogoImage from "../../../images/logo192.png";

const ProjectInfo = () => {
  const { id } = useParams();
  const { user } = useUser();
  const { data } = useSWR("/api/project/" + id, swrFetcher);
  let project = data;

  increaseProjectViews(id, user._id);

  const [requestToJoinDialogOpen, setRequestToJoinDialogOpen] = useState(false);
  const [tooMuchRequestsDialogOpen, setTooMuchRequestsDialogOpen] =
    useState(false);
  const [tooMuchProjectsDialogOpen, setTooMuchProjectsDialogOpen] =
    useState(false);
  const onCloseRequestToJoinDialog = () => setRequestToJoinDialogOpen(false);
  const onCloseTooMuchRequestsDialog = () =>
    setTooMuchRequestsDialogOpen(false);
  const onCloseTooMuchProjectsDialog = () =>
    setTooMuchProjectsDialogOpen(false);

  const openJoinRequestDialog = () => setRequestToJoinDialogOpen(true);
  const openTooMuchRequestsDialog = () => setTooMuchRequestsDialogOpen(true);
  const openTooMuchProjectsDialog = () => setTooMuchProjectsDialogOpen(true);

  const customImage = project?.image?.url;
  const defaultImage = PROJECT_TEMPLATES.find(
    (i) => i.value === project?.appType
  )?.bigImageSrc;
  const defaultLogo = vogoImage;

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Stack
        mb={4}
        sx={{
          ...jsStyles.cardBorderRadius,
          overflow: "hidden",
          bgcolor: themeColors.indigo100,
          ".image": {
            height: 300,
            objectFit: "cover",
            ...(!customImage
              ? {
                  objectFit: "contain",
                  bgcolor: "white",
                  py: 1,
                  m: 0.8,
                  mb: 0,
                  borderTopLeftRadius: ".5rem",
                  borderTopRightRadius: ".5rem",
                }
              : {}),
          },
        }}
      >
        {project ? (
          <img
            className="image"
            src={customImage || defaultImage}
            alt="background images"
          />
        ) : (
          <Skeleton variant="rectangular" className="image" />
        )}
        <Box
          p={2}
          display="flex"
          gap={3}
          sx={{
            ".logo": {
              ...jsStyles.cardBorderRadius,
              width: 110,
              height: 110,
              overflow: "hidden",
              objectFit: "contain",
              bgcolor: "white",
            },
          }}
        >
          {project ? (
            <img
              className="logo"
              src={project.logo?.url || project.npo?.npoLogo?.url || defaultLogo}
              alt={`${project.name}-logo`}
            />
          ) : (
            <Skeleton variant="rectangular" className="logo" />
          )}
          <Stack>
            <Typography variant="h4">
              {project?.name || <Skeleton width={80} />}
            </Typography>
            <Typography variant="h1">
              {getTextByValue(APP_TYPES, project?.appType) || (
                <Skeleton width={150} />
              )}
            </Typography>
            {project ? (
              <ProjectTags tags={project?.tags} mt="auto" />
            ) : (
              <Skeleton width={120} />
            )}
          </Stack>
        </Box>
      </Stack>
      <ProjectDescriptionItem
        title="Description"
        description={project?.description}
      />
      <Divider />
      <ProjectDescriptionItem title="The need" description={project?.theNeed} />
      <Divider />
      <ProjectDescriptionItem
        title="The value"
        description={project?.theValue}
      />
      <Divider />
      <ProjectDescriptionItem
        title="More information"
        links={project?.infoLinks}
      />
      <Divider />
      <ProjectDescriptionItem title="The team:" team={project?.team} />
      <Box display="flex" justifyContent="center" mt={3} mb={8}>
        {project &&
        project.status !== PROJECT_STATUSES.at(-1).value &&
        (user.role === 1 || user.role === 0) ? (
          <MainActionButton
            project={project}
            openJoinRequestDialog={openJoinRequestDialog}
            openTooMuchRequestsDialog={openTooMuchRequestsDialog}
            openTooMuchProjectsDialog={openTooMuchProjectsDialog}
          />
        ) : null}
      </Box>
      <JoinRequestDialog
        open={requestToJoinDialogOpen}
        onClose={onCloseRequestToJoinDialog}
      />
      <TooMuchRequestsDialog
        open={tooMuchRequestsDialogOpen}
        onClose={onCloseTooMuchRequestsDialog}
      />
      <TooMuchProjectsDialog
        open={tooMuchProjectsDialogOpen}
        onClose={onCloseTooMuchProjectsDialog}
      />
    </Container>
  );
};

export default ProjectInfo;
