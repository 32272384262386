import { appFetch } from "./fetch";

export const increaseProjectViews = async (projectId, userId) => {
  // project-views schema : [
  //   { projectId: userId, }
  // ]
  const projectsViews = JSON.parse(
    localStorage.getItem("project-views") || "{}"
  );
  const alreadyViewed = projectsViews?.[projectId] === userId;
  if (alreadyViewed) return;

  try {
    projectsViews[projectId] = userId;
    await appFetch(`/api/project/${projectId}/view`, { method: "PUT" });
    localStorage.setItem("project-views", JSON.stringify(projectsViews));
  } catch (error) {
    console.error(error);
  }
};
