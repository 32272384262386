import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-DTL1Y9PDDY");
  ReactGA.send("pageview");

  TagManager.initialize({
    gtmId: "GTM-W73JF2S",
  });

  hotjar.initialize(3334553, 6);
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
