export const themeColors = {
  main: "#252654",
  orange: "#FE9256",
  orangeGradientStart: "#EC6142",
  orangeGradientEnd: "#F3C840",
  indigo100: "#f3f3f9",
  indigo300: "rgba(53, 73, 152, 0.25)",
  indigo500: "#7D8BC5",
  indigo700: "#252654",
  secondaryPageBackground: "#fff",
  mainBackground: "#ECF3FE",
  formBorder: "#FFF6E0",
  buttonColor: "#B5C7E8",
  borderColor: "#DADADA",
};

export const themeTypography = {
  h1: {
    fontWeight: "700",
    fontSize: "3rem",
    lineHeight: 1.2,
    "@media (max-width:1200px)": {
      fontSize: "2.6rem",
    },
    "@media (max-width:600px)": {
      fontSize: "2.1rem",
    },
  },
  h2: {
    fontWeight: "700",
    fontSize: "2.3rem",
    lineHeight: 1.2,
    "@media (max-width:1200px)": {
      fontSize: "2.1rem",
    },
    "@media (max-width:600px)": {
      fontSize: "1.9rem",
    },
  },
  h3: {
    fontWeight: "700",
    fontSize: "1.4rem",
    lineHeight: 1.2,
    "@media (max-width:600px)": {
      fontSize: "1.22rem",
    },
  },
  h4: {
    fontWeight: "600",
    fontSize: "1.2rem",
    lineHeight: 1.4,
    "@media (max-width:600px)": {
      fontSize: "1.1rem",
    },
  },
  text: {
    fontWeight: "400",
    fontSize: "23px",
  },
  text1: {
    fontWeight: "400",
    fontSize: "19px",
  },
  text2: {
    fontWeight: "400",
    fontSize: "12px",
  },
};

export const themeMeasurements = {
  borderRadiusBase: "1.25rem",
};
