import { Stack, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/icons/Facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../images/icons/LinkedIn.svg";
import { ReactComponent as EmailIcon } from "../../images/icons/Email.svg";
import { themeColors, themeMeasurements } from "../../utils/constant";

const Footer = () => {
  const IconLink = ({ href, children }) => (
    <a href={href} target="_blank" rel="noreferrer">
      <Box
        sx={{
          //   path: { fill: "white" },
          width: 40,
          height: 40,
          borderRadius: 40,
          bgcolor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </a>
  );

  return (
    <Box mt='auto'>
      <Container maxWidth="xl">
        <Box
          borderRadius={themeMeasurements.borderRadiusBase}
          bgcolor={themeColors.indigo500}
          py={4}
          mb={{ xs: 1, sm: 2, lg: 3 }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row",
                },
                justifyContent: "space-between",
                alignItems: {
                  xs: "center",
                  sm: "start",
                },
              }}
            >
              <Stack alignItems={{ xs: "center", sm: "start" }}>
                <Box
                  sx={{
                    path: { fill: "white" },
                    width: 127,
                    mt: { xs: 1.5, sm: 0 },
                  }}
                >
                  <Logo width="100%" />
                </Box>
                <Typography color="white">
                  © VOGO, {new Date().getFullYear()}. All rights reserved.
                </Typography>
              </Stack>
              <Box
                display="flex"
                alignItems="center"
                gap={1.5}
                mt={{ xs: 0, sm: 1.5 }}
              >
                <Typography fontWeight={700} color="white" mr={2}>
                  Contact us
                </Typography>
                <IconLink href="https://www.facebook.com/groups/junex.2022">
                  <FacebookIcon />
                </IconLink>
                <IconLink href="https://www.linkedin.com/groups/12582272/">
                  <LinkedInIcon />
                </IconLink>
                <IconLink href="mailto:vogo-impact@gmail.com">
                  <EmailIcon />
                </IconLink>
              </Box>
            </Box>
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
