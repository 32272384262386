// icons
import { ReactComponent as CloudWebIcon } from "../images/project-templates/cloud-web.svg";
import { ReactComponent as DataAnalysisIcon } from "../images/project-templates/data-analysis.svg";
import { ReactComponent as LaptopDevIcon } from "../images/project-templates/laptop-dev.svg";
import { ReactComponent as MobileDevIcon } from "../images/project-templates/mobile-dev.svg";
import { ReactComponent as PcDevIcon } from "../images/project-templates/pc-dev.svg";
import { ReactComponent as WebIcon } from "../images/project-templates/web.svg";

// images
import dataAnalysisSrc from "../images/project-templates/data-analysis.png";
import itSrc from "../images/project-templates/IT.png";
import softwareSrc from "../images/project-templates/software.png";
import mobileSrc from "../images/project-templates/mobileapp.png";
import pcDevSrc from "../images/project-templates/web-support.png";
import webSrc from "../images/project-templates/website.png";

export const PROJECT_TEMPLATES = [
  {
    icon: LaptopDevIcon,
    bigImageSrc: softwareSrc,
    title: "Custom made software",
    value: "software",
    subtitle:
      "Partnering with a dedicated product team to design and build a custom web app, a process that may take a year to complete.",
    skillsRegex:
      /(Mobile app development|UX design|UI design|Back-end development|product management|project management)/i,
    team: [
      { requirements: { role: "productManager" } },
      { requirements: { role: "UXUI" } },
      { requirements: { role: "developer" } },
      { requirements: { role: "developer" } },
    ],
  },
  {
    icon: MobileDevIcon,
    bigImageSrc: mobileSrc,
    title: "Tailormade mobile app",
    value: "mobile-app",
    subtitle:
      "Partnering with a dedicated product team to design and build a custom mobile app, a process that may take several months to complete.",
    skillsRegex:
      /(web development|UX design|UI design|Back-end development|product management|project management)/i,
    team: [
      { requirements: { role: "productManager" } },
      { requirements: { role: "UXUI" } },
      { requirements: { role: "developer" } },
      { requirements: { role: "developer" } },
    ],
  },
  {
    icon: DataAnalysisIcon,
    bigImageSrc: dataAnalysisSrc,
    title: "Data analysis",
    value: "data-analysis",
    subtitle:
      "Need help making better decisions and measuring the impact of your work? collaborate with a data analyst expert to provide insights and analysis.",
    skillsRegex: /(Data analysis)/i,
    team: [{ requirements: { role: "data-analysis" } }],
  },
  {
    icon: WebIcon,
    bigImageSrc: webSrc,
    title: "Website development",
    value: "website",
    subtitle: "Have a website idea but need a developer to make it happen.",
    skillsRegex: /(Web development|UI design)/i,
    team: [
      { requirements: { role: "UXUI" } },
      { requirements: { role: "developer" } },
    ],
  },
  {
    icon: PcDevIcon,
    bigImageSrc: pcDevSrc,
    title: "Web development support",
    value: "web-support",
    subtitle:
      "Find a tech professional to support you on an ongoing basis for up to 4 weeks,",
    skillsRegex: /web development/i,
    team: [{ requirements: { role: "developer" } }],
  },
  {
    icon: CloudWebIcon,
    bigImageSrc: itSrc,
    title: "IT Infrastructure",
    value: "IT",
    subtitle:
      "Want to partner with an IT professional who can support your organization's technology needs from start to finish.",
    skillsRegex: /(IT infrastructure)/i,
    team: [{ requirements: { role: "IT" } }],
  },
];
