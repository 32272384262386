import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Divider, Stack, Typography } from "@mui/material";

import { themeColors } from "../../../utils/constant";
import GradientTypography from "../../../components/GradientTypography";
import jsStyles from "../../../styles/jsStyles";
import SignupStepper from "./SignupStepper";
import StepCard from "./StepCard";
import { useUser } from "../../../context/user";
import { clacSignupStepIndex } from "../../../utils/auth";

function TechProSignupPage() {
  const { user } = useUser();
  const initialStepIndex = clacSignupStepIndex(user);

  const [stepIndex, setStepIndex] = useState(initialStepIndex);

  useEffect(() => {
    const newStepIndex = clacSignupStepIndex(user);
    if (stepIndex !== newStepIndex) setStepIndex(newStepIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container maxWidth="md" sx={{ textAlign: "center" }}>
      <Helmet>
      </Helmet>
      <GradientTypography mb={2} mt={4}>
        Sign up
      </GradientTypography>
      <Typography variant="h2" mb={5}>
        We want to get to know you better
      </Typography>

      <Stack
        maxWidth={750}
        sx={{ ...jsStyles.cardBorder }}
        mx="auto"
        mb={10}
        py={3.5}
        px={{ xs: 2, sm: 5 }}
      >
        <SignupStepper activeStepIndex={stepIndex} />
        <Divider sx={{ bgcolor: themeColors.indigo500, my: 3 }} />
        <StepCard index={stepIndex} />
      </Stack>
    </Container>
  );
}

export default TechProSignupPage;
