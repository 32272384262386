import { IconButton, Stack } from "@mui/material";
import React from "react";
import AppEditIcon from "./AppEditIcon";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

function StartEditButton({
  isEditMode,
  onClickEdit,
  onCancelEdit,
  cancelEditButton,
  loading,
  deletable = false,
  onClickDelete,
  disabled = false,
}) {
  const startEditMode = (ev) => {
    ev.preventDefault();
    onClickEdit();
  };

  return !isEditMode ? (
    <AppEditIcon
      onClick={startEditMode}
      //   disabled={Boolean(editType)}
      type="button"
    />
  ) : !cancelEditButton ? (
    <Stack gap={1}>
      <IconButton
        size="small"
        disabled={loading || disabled}
        type="submit"
        color="secondary"
      >
        <SaveIcon />
      </IconButton>
      {deletable && (
        <IconButton
          size="small"
          disabled={loading}
          color="error"
          onClick={onClickDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  ) : (
    <IconButton size="small" type="button" onClick={onCancelEdit}>
      <CloseIcon />
    </IconButton>
  );
}

export default StartEditButton;
