import React from "react";
import { Dialog, DialogTitle } from "@mui/material";
import TeamMemberListItem from "./TeamMemberListItem";

function TeamDialog({ onClose, open, team, joinRequests, projectId }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Manage Team</DialogTitle>
      {team
        ? team.map((teamMember) => {
            const matchedJoinRequests = joinRequests.filter(
              ({ professionRole }) =>
                professionRole === teamMember.requirements.role
            );
            return (
              <TeamMemberListItem
                teamMember={teamMember}
                joinRequests={matchedJoinRequests}
                key={teamMember._id}
                projectId={projectId}
              />
            );
          })
        : null}
    </Dialog>
  );
}

export default TeamDialog;
