import { useState } from "react";
import { useProjectsStatus } from "../../../context/projectsStatus";
import { useUser } from "../../../context/user";
import { appFetch } from "../../../utils/fetch";
import CONFIG from "../../../data/config.json";
import { useNavigate } from "react-router";
import { Stack, Typography } from "@mui/material";
import GradientButton from "../../../components/GradientButton";

const MainActionButton = ({
  project,
  openJoinRequestDialog,
  openTooMuchRequestsDialog,
  openTooMuchProjectsDialog,
}) => {
  const { user } = useUser();
  const { projectsStatus, updateProjectsStatus } = useProjectsStatus();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  async function requestToJoin() {
    try {
      setLoading(true);
      //   setError("");
      const res = await appFetch(
        "/api/project/request-to-join/" + project._id,
        {
          method: "POST",
        }
      );
      if (!res.ok) throw new Error();
      updateProjectsStatus({
        waitingRequests: [...projectsStatus.waitingRequests, project],
      });
      openJoinRequestDialog();
    } catch (err) {
      console.log(err);
      //   setError("");
    } finally {
      setLoading(false);
    }
  }

  async function cancelRequest() {
    try {
      setLoading(true);
      //   setError("");
      const res = await appFetch(
        `/api/project/cancel-join-request/${project._id}/${user._id}`,
        {
          method: "POST",
        }
      );
      if (!res.ok) throw new Error();
      updateProjectsStatus({
        waitingRequests: projectsStatus.waitingRequests.filter(
          ({ _id }) => _id !== project._id
        ),
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const isUserRoleRequired = project.team.some(
    ({ requirements: { role }, chosenUser }) =>
      role === user.professionRole && !chosenUser
  );
  // console.log(projectsStatus)

  const reachedMaxRequests =
    projectsStatus.waitingRequests.length >= CONFIG.MAX_USER_JOIN_REQUESTS;

  const reachedMaxActiveProjects =
    projectsStatus.projectsInProcess.length >= CONFIG.MAX_USER_ACTIVE_PROJECTS;
  const userInTeam = project.team.some(
    ({ chosenUser }) => (chosenUser?._id || chosenUser) === user._id
  );
  const userInWaitingList = projectsStatus.waitingRequests.some(
    ({ _id }) => _id === project._id
  );

  const onClick = userInWaitingList
    ? cancelRequest
    : userInTeam
    ? () => navigate(`status`)
    : reachedMaxRequests
    ? openTooMuchRequestsDialog
    : reachedMaxActiveProjects
    ? openTooMuchProjectsDialog
    : requestToJoin;

  const disabled = !userInTeam && !userInWaitingList && !isUserRoleRequired;

  const disabledLabel =
    !userInTeam &&
    !userInWaitingList &&
    !isUserRoleRequired &&
    `Unfortunately, we do not have any relevant open positions available in this project.`;

  const label = userInWaitingList
    ? "CANCEL REQUEST"
    : userInTeam
    ? "GO TO PROJECT ➔"
    : "REQUEST TO JOIN";

  return (
    <Stack alignItems={"center"}>
      {!disabledLabel ? (
        <GradientButton
          onClick={onClick}
          loading={loading}
          disabled={disabled}
          label={label}
        />
      ) : (
        <Typography
          mt={1}
          color="GrayText"
          align="center"
          sx={{ "::first-letter": { textTransform: "uppercase" } }}
        >
          {disabledLabel}
        </Typography>
      )}
    </Stack>
  );
};

export default MainActionButton;
