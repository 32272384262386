import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./header/components/Header";

function Layout({ usersLayout = false }) {
  return (
    <>
      <Header />
      <Outlet />
      {!usersLayout && <Footer />}
    </>
  );
}

export default Layout;
