import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  Container,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validateByRules,  } from "../../utils/validators";

import GoogleLoginButton from "../../components/auth/google-login-button/GoogleLoginButton";
import AppInput from "../../components/AppInput";
import MainButton from "../../components/MainButton";
import GradientTypography from "../../components/GradientTypography";
import jsStyles from "../../styles/jsStyles";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);

  const loginHandler = async (ev, isGoogleSignin) => {
    ev.preventDefault();

    if (!isGoogleSignin) {
      const invalid = validateForm();
      if (invalid) {
        setShowInputErrors(true);
        return;
      }
    }
    const auth = getAuth();
    !isGoogleSignin && setLoading(true);
    setServerError("");
    try {
      if (isGoogleSignin) {
        const result = await signInWithPopup(auth, new GoogleAuthProvider());
        GoogleAuthProvider.credentialFromResult(result);
        // Signed in
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      setLoading(true);
    } catch (err) {
      const errorCode = err.code;
      const errorMessage = err.message;
      console.error(err);
      if (errorCode === "auth/user-not-found") {
        setServerError("User not found.");
      } else if (errorCode === "auth/wrong-password") {
        setServerError("Wrong password.");
      } else if (
        errorCode === "auth/popup-closed-by-user" ||
        errorCode === "auth/cancelled-popup-request"
      ) {
        // do nothing!
      } else {
        setServerError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const hasErrors =
      validateByRules("email", email) || validateByRules("password", password);
    return hasErrors;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <GradientTypography mb={2} mt={4}>
        Login
      </GradientTypography>
      <Typography variant="h2" mb={5}>
        Log in to your account
      </Typography>
      <Stack
        component="form"
        maxWidth={500}
        sx={{ ...jsStyles.cardBorder }}
        mx="auto"
        mb={10}
        py={5}
        px={{ xs: 2, sm: 8 }}
        alignItems="center"
      >
        <AppInput
          label="Email"
          value={email}
          name="email"
          setValue={setEmail}
          validationRules={"email"}
          showError={showInputErrors}
          sx={{ mb: 2 }}
        />
        <AppInput
          label="Password"
          name="password"
          value={password}
          setValue={setPassword}
          validationRules={"password"}
          showError={showInputErrors}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link to="/forgot-password" style={{ alignSelf: "flex-start" }}>
          <Typography
            variant="body2"
            sx={{
              color: "black",
              mt: 0.8,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Forgot Password
          </Typography>
        </Link>
        <Typography color="error" height="1.5em">
          {serverError}
        </Typography>
        <MainButton
          label="Login"
          onClick={(ev) => loginHandler(ev, false)}
          type="submit"
          isLoadingButton
          loading={loading}
          sx={{ mb: 1, minWidth: "13.2rem" }}
        />
        <GoogleLoginButton
          loading={loading}
          onClick={(ev) => loginHandler(ev, true)}
          type="submit"
        />
        <Typography variant="text2" fontWeight="700">
          Dont have an account? <Link to="/signup">Sign up</Link>
        </Typography>
      </Stack>
    </Container>
  );
}

export default LoginPage;
