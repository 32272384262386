import React from "react";
import ImageUploading from "react-images-uploading";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";

const PicturePicker = ({ label, maxNumberOfFiles = 1, images, setImages }) => {
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumberOfFiles}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
        // write your building UI
        <div className="upload__image-wrapper">
          <Button
            variant={!isDragging ? "outlined" : "contained"}
            onClick={onImageUpload}
            disabled={images.length === maxNumberOfFiles}
            {...dragProps}
            sx={{ width: 1, height: 140 }}
          >
            {!isDragging ? (
              <Stack>
                <Typography>{label}</Typography>
                <Typography variant="caption">Click or Drop here</Typography>
              </Stack>
            ) : (
              <UploadFileIcon />
            )}
          </Button>
          <Grid
            display="flex"
            alignItems="start"
            marginTop={1}
            marginBottom={2}
            gap={1}
          >
            {imageList.map((image, index) => (
              <Box
                className="image-item"
                sx={{ position: "relative" }}
                key={image.file.name + image.file.lastModified}
              >
                <img src={image["data_url"]} alt="" width="100" />
                <Tooltip
                  title="Remove"
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                >
                  <IconButton
                    onClick={() => onImageRemove(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Grid>
        </div>
      )}
    </ImageUploading>
  );
};

export default PicturePicker;
