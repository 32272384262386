import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import EditIcon from "../images/icons/edit.png";
import { ReactComponent as EditIconOutlined } from "../images/icons/edit.svg";

function AppEditIcon({
  size = "1.3em",
  outlined = true,
  isButton = true,
  sx = {},
  ...props
}) {
  const Container = isButton ? IconButton : Box;
  return (
    <Container
      size="small"
      sx={{
        "img,svg": {
          width: size,
          height: size,
          ...sx,
        },
      }}
      {...props}
    >
      {outlined ? (
        <EditIconOutlined />
      ) : (
        <img src={EditIcon} alt="edit-profile" />
      )}
    </Container>
  );
}

export default AppEditIcon;
