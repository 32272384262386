import { createContext, useContext, useMemo, useState } from "react";

const ProjectsFiltersContext = createContext({
  search: null,
  setSearch: null,
  filters: null,
  setFilters: null,
});

const useFilteredProjects = (data) => {
  const { search, setSearch, filters, setFilters } = useContext(
    ProjectsFiltersContext
  );

  const handleUpdateFilters = ({ value, name }) => {
    setFilters((state) => ({ ...state, [name]: value }));
  };

  const handleUpdateSearch = (event) => setSearch(event.target.value);

  const projects = useMemo(() => {
    const { project, difficulty, duration, lookingFor } = filters;
    let filtered = data || [];
    if (search) {
      filtered = filtered.filter(({ name }) =>
        name.toLowerCase().includes(search.toLocaleLowerCase())
      );
    }
    if (project) {
      filtered = filtered.filter(({ status }) => status !== "done");
    }
    if (typeof difficulty === "number") {
      filtered = filtered.filter(
        ({ difficultyLevel }) => difficultyLevel === difficulty
      );
    }
    if (typeof duration === "number") {
      filtered = filtered.filter(({ estimatedDuration }) => {
        const { dayCount } = estimatedDuration;
        if (duration === 0) {
          return dayCount < 30;
        } else if (duration === 1) {
          return 30 <= dayCount && dayCount <= 60;
        } else {
          return dayCount > 60;
        }
      });
    }
    if (lookingFor) {
      filtered = filtered.filter(({ team }) =>
        team.find(
          ({ requirements, chosenUser }) =>
            !chosenUser && requirements.role === lookingFor
        )
      );
    }
    return filtered;
  }, [data, search, filters]);

  return {
    filters,
    handleUpdateFilters,
    handleUpdateSearch,
    search,
    projects,
  };
};
export const ProjectsFiltersContextProvider = ({ children }) => {
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    project: "",
    difficulty: "",
    duration: "",
    lookingFor: "",
  });

  return (
    <ProjectsFiltersContext.Provider
      value={{
        search,
        setSearch,
        filters,
        setFilters,
      }}
    >
      {children}
    </ProjectsFiltersContext.Provider>
  );
};

export default useFilteredProjects;
