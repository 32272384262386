import React from "react";
import { Avatar, Skeleton } from "@mui/material";
import { AVATAR_BG_COLORS } from "../data/lists";
import { getFullName } from "../utils/tools";
import jsStyles from "../styles/jsStyles";

function AppAvatar({
  user,
  uri,
  isNpoLogo = false,
  variant = "circular",
  isTask = false,
  size = { xs: 35, sm: isTask ? 35 : 45},
  sx = {},
  ...props
}) {
  const { profileImage } = user || {};

  function getBackgroundColor(stringInput) {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return AVATAR_BG_COLORS[stringUniqueHash % AVATAR_BG_COLORS.length];
  }

  const avatarSx = {
    width: size,
    height: size,
    border: `${Math.max(size / 35, 1)}px solid white`,
    bgcolor: "white",
    borderRadius: variant === "rect" ? jsStyles.cardBorderRadius : 100,
    ...sx,
  };
  const imgProps = { sx: { objectFit: "contain !important" } };

  const fullName = getFullName(user);
  const getFirstLetter = (str) =>
    str?.split("").find((char) => ![8234].includes(char.charCodeAt(0)));
  const altText =
    getFirstLetter(isNpoLogo ? user?.npoName : fullName)?.toUpperCase() || "";

  const src = uri ? uri : isNpoLogo ? user?.npoLogo?.url : profileImage?.url;

  return src ? (
    <Avatar
      sx={avatarSx}
      imgProps={imgProps}
      alt={fullName}
      src={src}
      {...props}
    />
  ) : user ? (
    <Avatar
      sx={{ ...avatarSx, bgcolor: getBackgroundColor(user._id) }}
      imgProps={imgProps}
      alt={fullName}
      {...props}
    >
      {altText}
    </Avatar>
  ) : (
    <Skeleton
      variant="circular"
      width={size?.xs || size}
      height={size?.xs || size}
      sx={{ flexShrink: 0 }}
    />
  );
}

export default AppAvatar;
