import React from "react";
import { Navigate } from "react-router";

import { useUser } from "../../context/user";
import LoadingScreen from "../LoadingScreen";

function AdminRoute({ children }) {
  const { user } = useUser();

  // still trying to login
  if (user === undefined) {
    return <LoadingScreen />;
  }
  // login failed
  if (user === null) {
    return <Navigate to="/" />;
  }
  // if not admin, go to user dashboard
  if (user.role !== 0) {
    return <Navigate to="/projects" replace />;
  }
  return children;
}

export default AdminRoute;
