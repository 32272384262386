import * as React from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import {statusColors} from "./ProjectTasks"
import useNewTaskForm from '../../../hooks/useTaskForm';
import NewTaskForm from './NewTaskForm';
import { Stack } from '@mui/material';

const initialValue = dayjs();

function ServerDay(props) {
  
  const { highlightedDays = [], day, outsideCurrentMonth, allTasks,project, setAllTasks, isEditing,
    titleError,
    handleAddTask,
    handleEditTask,
    handleConfirmTask,
    handleInputChange,
    handleStatusChange,
    handleClose,...other } = props;
 
  // Find the task for the current day

  const taskForDay = allTasks.find(task =>
    day.isSame(dayjs(task.date), 'day')
  );

  // If the current day has a task, mark it as selected
  const isSelected =
    !outsideCurrentMonth && highlightedDays.includes(day.format('YYYY-MM-DD'));

  // Determine the icon based on the task's status or type
  const getTaskIcon = (task) => {
    if (!task) return undefined;
    const color = statusColors[task.status] // If no task, return undefined
const formattedDate = new Date(task.date).toISOString().split('T')[0];
    return <AssignmentIcon onClick={()=>{ isSelected ? handleEditTask(task):handleAddTask({date:formattedDate})}} sx={{color}}  />;
  };

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? getTaskIcon(taskForDay) : undefined}
    >
      <PickersDay
        onClick={() =>  {
          if(isSelected){
            handleEditTask(taskForDay)
          }else{
            const date = new Date(day.toString());
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
            const dayOfMonth = String(date.getDate()).padStart(2, '0'); // Pad with '0' if needed

            const formattedDate = `${year}-${month}-${dayOfMonth}`;
            handleAddTask({ date: formattedDate});

          }
        }}
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function DateCalendarServerRequest({ project, setAllTasks, allTasks,teamMembers  }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const {
    newTask,
    isEditing,
    titleError,
    handleAddTask,
    handleEditTask,
    handleConfirmTask,
    handleInputChange,
    handleStatusChange,
    handleClose,
  } = useNewTaskForm(project, setAllTasks, "");
  React.useEffect(() => {
    // Extract the dates from allTasks and format them to 'YYYY-MM-DD'
    const taskDates = allTasks.map(task => dayjs(task.date).format('YYYY-MM-DD'));
    setHighlightedDays(taskDates);
  }, [allTasks]);

  const handleMonthChange = (date) => {
    // Optional: handle any logic when the month is changed, e.g., updating tasks for the current month
  };
  console.log("---------------")
console.log(process.env.REACT_APP_API_URL)
console.log("---------------")

  return (
    <Stack flexDirection={"row"} justifyContent={"space-between"}>
      <Stack></Stack>
    <Stack sx={{width:500,}}><LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        sx={{        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Nice subtle shadow
          maxHeight:"1000px", width: '400px', height: '410px',    '& .MuiPickersDay-root': {
      width: 50,  // Increase the width of each day
      height: 50,
       // Increase the height of each day
      fontSize: '1rem', // Increase the font size of the day numbers
    },
    '& .MuiDayCalendar-header':{
      justifyContent:"space-evenly"
    },
    '& .MuiDayCalendar-header span':{
      width:"24px"
    },
    '& .MuiYearCalendar-root':{
      width:"unset",
      maxHeight:"400px"
    },
    
    '& .MuiPickersSlideTransition-root':{
      minHeight:"500px"
    },
    '& .MuiTypography-root': {
      fontSize: '1.5rem', // Increase the font size of month and year text
    }, }} // Adjust the values as needed

        defaultValue={initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: (props) => <ServerDay {...props} setAllTasks={setAllTasks} allTasks={allTasks} project={project} 
          newTask={newTask}
          isEditing={isEditing}
          titleError= {titleError}
          handleAddTask= {handleAddTask}
          handleEditTask= {handleEditTask}
          handleConfirmTask={handleConfirmTask}
          handleInputChange=  {handleInputChange}
          handleStatusChange= {handleStatusChange}
          handleClose= {handleClose} />,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
       {newTask && (
          <NewTaskForm
            newTask={newTask}
            isEditing={isEditing}
            titleError={titleError}
            teamMembers={teamMembers}
            handleInputChange={handleInputChange}
            handleStatusChange={handleStatusChange}
            handleConfirmTask={handleConfirmTask}
            statusColors={statusColors}
            handleClose={handleClose}
          />
        )}</Stack>
    </Stack>
    
  );
}
