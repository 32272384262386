import React, { useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  styled,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import TeamMember from '../../../components/TeamMember';
import GradientButton from "../../../components/GradientButton";
import backImg from "../../../images/back.png";

const CustomPaper = styled(Paper)(({ theme }) => ({
  width: 320,
}));

const NewTaskForm = ({
  newTask,
  isEditing,
  titleError,
  teamMembers,
  handleInputChange,
  handleStatusChange,
  handleConfirmTask,
  statusColors,
  handleClose, // Pass a handleClose prop to close the dialog
}) => {
  const [typeError, setTypeError] = useState(false);

  const onConfirmTask = () => {
    if (!newTask.type) {
      setTypeError(true); // Set error if type is empty
      return;
    }
    handleConfirmTask();
  };

  return (
    <Dialog
      open={Boolean(newTask)}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          padding: 50,
          borderRadius: '16px', // Rounded corners
        },
      }}
    >
      <img 
        src={backImg}
        alt="Back" 
        style={{ position: 'absolute', left: 50, top: 50, cursor: 'pointer' }} 
        onClick={handleClose} 
      />
      <DialogTitle sx={{ textAlign: 'center', fontSize: '1.5rem', color: '#233783' }}>
        {isEditing ? 'Edit Task' : 'Add a New Task'}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} sx={{ padding: 2 }}>
          <TextField
            name="title"
            label="Task Title"
            placeholder="Write here..."
            variant="outlined"
            value={newTask.title}
            onChange={handleInputChange}
            size="medium"
            error={titleError}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: titleError ? 'red' : '#FF7033',
                },
              },
            }}
          />
          <TextField
            name="description"
            label="Task Description"
            placeholder="Describe your task"
            variant="outlined"
            value={newTask.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
          />
          <TextField
            name="date"
            label="Task Date"
            type="date"
            variant="outlined"
            value={newTask.date ? new Date(newTask.date).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          <Autocomplete
            multiple
            options={teamMembers.filter((item) => item.uid)}
            getOptionLabel={(option) => option.firstName}
            value={newTask.assignment}
            onChange={(event, newValue) => {
              handleInputChange({ target: { name: 'assignment', value: newValue } });
            }}
            isOptionEqualToValue={(option, value) => option.uid === value.uid}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assignment"
                variant="outlined"
                placeholder="Select Members"
                fullWidth
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <TeamMember
                  isTask={true}
                  user={(() => {
                    const { uid, ...user } = option;
                    return user;
                  })()}
                  subtitle={option.role}
                />
              </li>
            )}
            PaperComponent={(props) => <CustomPaper {...props} />}
          />
          {/* Task type field */}
          <Select
            name="type"
            value={newTask.type}
            onChange={(event) => {
              handleInputChange(event);
              if (typeError) {
                setTypeError(false); // Reset error if value is selected
              }
            }}
            variant="outlined"
            fullWidth
            displayEmpty
            error={typeError}
            sx={{
              borderRadius: '8px',
              '& .MuiSelect-outlined': {
                padding: '10px',
              },
              '& fieldset': {
                borderColor: typeError ? 'red' : '',
              },
            }}
          >
            <MenuItem value="" disabled>
              Choose Type
            </MenuItem>
            <MenuItem value="Gathering Team">Gathering Team</MenuItem>
            <MenuItem value="Defining & Designing">Defining & Designing</MenuItem>
            <MenuItem value="Setting Up Environment">Setting Up Environment</MenuItem>
            <MenuItem value="Development">Development</MenuItem>
            <MenuItem value="Testing">Testing</MenuItem>
            <MenuItem value="Delivery">Delivery</MenuItem>
          </Select>
          <Select
            name="status"
            value={newTask.status}
            onChange={handleStatusChange}
            variant="outlined"
            fullWidth
            displayEmpty
            sx={{
              borderRadius: '8px',
              '& .MuiSelect-outlined': {
                padding: '10px',
              },
            }}
          >
            <MenuItem value="" disabled>
              Choose Status
            </MenuItem>
            <MenuItem value="In Progress" style={{ color: statusColors['In Progress'].color }}>
              In Progress
            </MenuItem>
            <MenuItem value="Done" style={{ color: statusColors['Done'].color }}>
              Done
            </MenuItem>
            <MenuItem value="Delayed" style={{ color: statusColors['Delayed'].color }}>
              Delayed
            </MenuItem>
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
        <GradientButton
          label={isEditing ? 'Update' : 'ADD'}
          onClick={onConfirmTask} // Call the custom confirm function
          sx={{ mt: { xs: 3, md: 5 } }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default NewTaskForm;
