import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import React from "react";

import GoogleIcon from "../../../images/icons/google.png";

function GoogleLoginButton({ onClick,loading, label = "Log in with google" }) {
  return (
    <LoadingButton
      loading={loading}
      // color="secondary"
      sx={{
        color: "black",
        borderRadius: ".5rem",
        width: "13.2rem",
        bgcolor: "mainBackground",
        textTransform: "none",
        filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1))",
        display: "flex",
        justifyContent: "center",
        columnGap: 1,
        py: 1,
        my: 1,
      }}
      onClick={onClick}
    >
      <img src={GoogleIcon} alt='google-icon' />
      <Typography variant="text2" fontWeight="700">
        {label}
      </Typography>
    </LoadingButton>
  );
}

export default GoogleLoginButton;
