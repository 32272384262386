import { Box, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { MEETING_DETAILS_ITEMS } from "../../data/lists";
import WebsiteIcon from "../../images/icons/website.png";
import { formatHref } from "../../utils/tools";
import Team from "../Team";

const ProjectDescriptionItem = ({
  title,
  description,
  links,
  team,
  meetingDetails,
}) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: !team ? "row" : "column" }}
      gap={{ xs: 1, sm: team ? 2.5 : 0 }}
      py={2}
    >
      <Typography fontWeight={700} width={180} flexShrink={0}>
        {title}
      </Typography>
      {links ? (
        <Stack spacing={1}>
          {links.map((link) => (
            <Box
              key={link}
              component={"a"}
              href={formatHref(link)}
              display="flex"
              gap={1}
              alignItems="center"
              className="link"
            >
              <img
                src={WebsiteIcon}
                alt="icon"
                style={{ width: "1em", height: "1em" }}
              />
              <Typography>{link}</Typography>
            </Box>
          ))}
        </Stack>
      ) : meetingDetails ? (
        <Stack spacing={1}>
          {Object.entries(meetingDetails).map(([key, text]) => {
            console.log(meetingDetails);
            const label = MEETING_DETAILS_ITEMS.find(
              (i) => i.key === key
            )?.label;
            return (
              <Box display={"flex"} alignItems="center" key={key} gap={.7}>
                <Typography>{label}</Typography>
                <Typography>-</Typography>
                <Typography>{text}</Typography>
              </Box>
            );
          })}
        </Stack>
      ) : team ? (
        <Team team={team} />
      ) : (
        <Typography>{description || <Skeleton width={200} />}</Typography>
      )}
    </Box>
  );
};

export default ProjectDescriptionItem;
