import React from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
  Menu,
  MenuItem,
  Button,
  Link,
} from "@mui/material";

import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import BrushIcon from "@mui/icons-material/Brush";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { PROFESSION_ROLES, SENIORITY_TYPES } from "../../../../data/lists";
import { appFetch } from "../../../../utils/fetch";
import { useSWRConfig } from "swr";
import { getFullName } from "../../../../utils/tools";

function TeamMemberListItem({ teamMember, joinRequests, projectId }) {
  const { _id, requirements, chosenUser } = teamMember;

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [loading, setLoading] = React.useState(null);

  const { mutate } = useSWRConfig();

  const openMenu = Boolean(anchorEl);
  function openRequestsMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleCloseMenu() {
    setAnchorEl(null);
  }
  async function acceptRequest(userId) {
    const res = await appFetch(
      `/api/project/accept-join-request/${projectId}/${userId}/${_id}`,
      { method: "POST" }
    );
    if (!res.ok) throw new Error();
    // const { data } = await res.json();
    mutate("/api/project/full");
  }
  async function rejectRequest(userId) {
    const res = await appFetch(
      `/api/project/cancel-join-request/${projectId}/${userId}`,
      { method: "POST" }
    );
    if (!res.ok) throw new Error();
    // const { data } = await res.json();
    mutate("/api/project/full");
  }
  async function cancelJoining(userId) {
    const res = await appFetch(
      `/api/project/cancel-joining/${projectId}/${userId}`,
      { method: "POST" }
    );
    if (!res.ok) throw new Error();
    // const { data } = await res.json();
    mutate("/api/project/full");
    handleCloseMenu();
  }

  const ICONS_DIC = {
    developer: DeveloperModeIcon,
    UXUI: BrushIcon,
    productManager: WorkspacesIcon,
  };

  const Icon = ICONS_DIC[requirements.role] || QuestionMarkIcon;

  return (
    <List sx={{ pt: 0 }}>
      <ListItem>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={
            PROFESSION_ROLES.find(({ value }) => value === requirements.role)
              ?.text
          }
          secondary={
            SENIORITY_TYPES.find(
              ({ value }) => value === requirements.seniority
            )?.text
          }
        />
        {joinRequests.length && !chosenUser ? (
          <>
            <Badge badgeContent={joinRequests.length} color="primary">
              <Tooltip title="Pick from waiting list">
                <IconButton
                  sx={{ marginLeft: 4 }}
                  onClick={openRequestsMenu}
                  disabled={!joinRequests.length}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
            </Badge>

            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {joinRequests.map((user) => (
                <MenuItem
                  key={user._id}
                  disableRipple
                  sx={{ display: "flex", gap: 2 }}
                >
                  <Link href={"/profile/" + user.uid} target="_blank">
                    <OpenInNewIcon />
                    {getFullName(user)}
                  </Link>
                  <Button
                    onClick={() => acceptRequest(user._id)}
                    startIcon={<PersonAddIcon fontSize="small" />}
                    variant="contained"
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => rejectRequest(user._id)}
                    startIcon={<PersonRemoveIcon fontSize="small" />}
                  >
                    Reject
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : chosenUser ? (
          <>
            <ListItemText sx={{ ml: 4 }}>
              <Link href={"/profile/" + chosenUser.uid} target="_blank" className="link">
                {getFullName(chosenUser)}
              </Link>
            </ListItemText>
            <Tooltip title="remove">
              <IconButton onClick={() => cancelJoining(chosenUser._id)}>
                <CloseIcon color="warning" />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </ListItem>
    </List>
  );
}

export default TeamMemberListItem;
