export const USER_ROLES = ["Admin", "Tech pro", "Npo"];

export const TECH_PRO_SIGNUP_STEPS = [
  "Account setup",
  "Contact details",
  "Education & Expertise",
];

export const NPO_SIGNUP_STEPS = [
  "Account setup",
  "Contact details",
  "NPO details",
];

export const PROJECT_STATUSES = [
  { value: "gathering-team", text: "Recruiting" },
  { value: "defining", text: "Planning" },
  { value: "design", text: "Design" },
  { value: "development", text: "Development" },
  { value: "testing", text: "Testing" },
  { value: "delivery", text: "Deployment" },
  { value: "done", text: "Done" },
];

export const PROFESSION_ROLES = [
  {
    value: "productManager",
    text: "Product manager",
    text2: "Product management",
    shortText: "PM",
  },
  {
    value: "UXUI",
    text: "UX/UI designer",
    text2: "UX/UI design",
    shortText: "UX/UI",
  },
  {
    value: "developer",
    text: "Developer",
    text2: "Development",
    shortText: "Dev",
  },
  {
    value: "QA",
    text: "Quality assurance engineer",
    text2: "Quality assurance",
    shortText: "QA",
  },
  {
    value: "data-analysis",
    text: "Data analysis engineer",
    text2: "Data analysis",
    shortText: "Data analysis",
  },
  {
    value: "IT",
    text: "IT expert",
    text2: "Information technology",
    shortText: "IT expert",
  },
];

export const EDUCATION_OPTIONS = [
  {
    value: "productManager",
    text: "Product manager",
  },
  { value: "developer", text: "Developer" },
  { value: "UXUI", text: "UX/UI" },
];

export const HOW_DID_YOU_HEAR_OPTIONS = [
  "Friends",
  "Linkedin",
  "Facebook",
  "Course",
  "Other",
];

export const SENIORITY_TYPES = [
  { value: "0-2", text: "0-2 Years" },
  { value: "2-5", text: "2-5 Years" },
  { value: "5+", text: "5+ Years" },
];

export const APP_TYPES = [
  { value: "software", text: "Custom made software" },
  { value: "mobile-app", text: "Tailormade mobile app" },
  { value: "data-analysis", text: "Data analysis" },
  { value: "website", text: "Website development" },
  { value: "web-support", text: "Web development support" },
  { value: "IT", text: "IT Infrastructure" },
];

// export const PROJECTS_OPTIONS = [{ text: "Available", value: 1 }];

export const TIME_UNITS_OPTIONS = [
  { text: "Day", value: "day" },
  { text: "Week", value: "week" },
  { text: "Month", value: "month" },
];

export const DIFFICULTY_OPTIONS = [
  { text: "Easy", value: 0 },
  { text: "Medium", value: 1 },
  { text: "Hard", value: 2 },
];

export const DURATION_OPTIONS = [
  { text: "less then a month", value: 0 },
  { text: "1-2 months", value: 1 },
  { text: "More than 2 months", value: 2 },
];

export const MEETING_DETAILS_ITEMS = [
  { label: "Location", key: "meetingLocation" },
  { label: "Meetings", key: "meetingTiming" },
  { label: "Contact", key: "contactPerson" },
];

export const ONE_DAY = 86400000;

export const AVATAR_BG_COLORS = [
  "#F18AA1",
  "#EEBB57",
  "#BBA2D0",
  "#B2A9A2",
  "#37AFA3",
  "#373B48",
];

export const USER_STATUSES = [
  { value: "unconfigured", text: "Unconfigured" },
  { value: "not-approved", text: "Waiting for confirm" },
  { value: "approved", text: "Active" },
];

export const PROJECT_CONFIRM_STATUSES = [
  { value: "not-approved", text: "Waiting for confirm" },
  { value: "approved", text: "Active" },
];
