/* eslint-disable no-useless-escape */
export const validationRules = {
  email: [
    (str) => !str && "Please type email.",
    (str) =>
      !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        str
      ) && "Invalid Email.",
  ],
  phone: [(str) => /[^\d\s-\(\)\+]/.test(str) && "Invalid Phone."],
  name: [
    (str) => str.length > 20 && "Name is too long.",
    (str) => str.length < 2 && "Name is too short.",
  ],
  title: [
    (str) => str.length > 50 && "Title is too long.",
    (str) => (!str ? false : str.length < 2 && "Title is too short."),
  ],
  description: [
    (str) => str.length > 500 && "Description is too long.",
    (str) => (!str ? false : str.length < 2 && "Description is too short."),
  ],
  password: [
    (str) => !str && "Please type password.",
    (str) => str.length > 70 && "Password is too long.",
    (str) => str.length < 6 && "Password must be at least 6 characters long.",
  ],
  url: [(str) => str && !validURL(str) && "Invalid url."],
  linkedinUrl: [
    (str) =>
      str &&
      !/^(https:\/\/)?(www.)?linkedin.com\/.+$/i.test(str) &&
      "Invalid linkedin url.",
  ],
  required: [(str) => !str && "Required"],
  generateRules: (rulesData) => {
    const { min, max, required } = rulesData;
    const rules = [];
    if (required) rules.push(validationRules.required);
    if (min) rules.push((str) => str.length < min && "Field is too short.");
    if (max) rules.push((str) => str.length < max && "Field is too long.");
    return rules;
  },
};

export function validateByRules(ruleTypes, str) {
  const rules = ruleTypes
    .split(" ")
    .flatMap((ruleType) => validationRules[ruleType]);
  for (const rule of rules) {
    const validationErrorMessage = rule(str);
    if (validationErrorMessage) return validationErrorMessage;
  }
}

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
