import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { PROFESSION_ROLES } from "../../../data/lists";
import GradientButton from "../../../components/GradientButton";

const AddTeamPositionDialog = ({ open, onClose, onSave }) => {
  const INITIAL_STATE = {
    role: "",
    seniority: "",
  };
  const [teamPosition, setTeamPosition] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function onChangeRequirement(key) {
    return ({ target: { value } }) => {
      setTeamPosition({ ...teamPosition, [key]: value });
    };
  }

  async function submitHandler() {
    try {
      setLoading(true);
      setError("");
      await onSave({ teamPosition });
      onClose();
      setTeamPosition(INITIAL_STATE);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: 700, textAlign: "center" }}>
        Recruit new teammate
      </DialogTitle>
      <DialogContent sx={{ width: { xs: "80vw", sm: 450 } }}>
        <Stack m={"auto"} gap={1} maxWidth={250} pt={1}>
          <FormControl>
            <InputLabel>Role</InputLabel>
            <Select
              fullWidth
              label="rol"
              value={teamPosition?.role}
              onChange={onChangeRequirement("role")}
              required
            >
              {PROFESSION_ROLES.map(({ value, text }, index) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <GradientButton label="Add" loading={loading} onClick={submitHandler} />
      </DialogActions>
      {Boolean(error) && (
        <Typography color="error" fontWeight="700" mt={1}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
};

export default AddTeamPositionDialog;
